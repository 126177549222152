import React from 'react';
import { withTheme, withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import TableFilter from 'components/table_components/table_filter';

const styles = theme => ({
    additionalFormWrapper: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: 12,
        '& label': {
            marginBottom: 0
        }
    },
    innerContent: {
        backgroundColor: theme.palette.blue[50],
        padding: '8px 12px 0',
        borderRadius: 4
    }
});

const ContentQuestionsTableFilter = observer(({
    filter, filterState, stateOptions, statusOptions, handleChangeFilterStatus, handleChangeFilterState
}) => (
    <div>
        <TableFilter
            isFilterActive={filter.length > 0 || filterState.length > 0}
            isCheckbox={false}
            compoundFilterData={[
                {
                    innerHeader: 'Set Status',
                    options: statusOptions,
                    handleChange: value => handleChangeFilterStatus(value)
                },
                {
                    innerHeader: 'Set State',
                    options: stateOptions,
                    handleChange: value => handleChangeFilterState(value)
                }
            ]}
        />
    </div>
));

export default withStyles(styles)(withTheme(ContentQuestionsTableFilter));
