/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { appCtx } from 'components/appStore';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import LargeCheckIcon from 'img/large_check.svg';
import AlertCircleIcon from 'img/alert_circle.svg';
import QuestionIcon from 'img/question.svg';
import TimerIcon from 'img/timer2.svg';
import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import { generateAssessment, generateAssessmentSkills, generateAssessmentSkillSet, getGeneratedCompletedQuestions,
    uploadGenerationFile, reUploadGenerationFile, compoundGeneratedQuestionTypes, saveGeneratedAssessment, getGeneratedCount
} from 'requests/ScriptRequests';
import Zendesk from 'helper/zendeskFunctions';
import OnboardingEvents from 'events/OnboardingEvents';
import AssessmentBuilderEvents from 'events/AssessmentBuilderEvents';
import Sidebar from './components/Sidebar';

import stylesJs from './styles';

const renderQuestionType = type => getQuestionTypeInfo(type, renderIconAndLabel);

const renderIconAndLabel = (Icon, label, labelShort = label) => (
    <div className="u-dsp--f u-ai--center">
        { Icon && (
            <Icon style={{
                width: '24px',
                height: '24px',
                marginRight: '15px'
            }}
            />
        )}
        <span>{labelShort}</span>
    </div>
);

const MIN_JOB_DESCRIPTION_LENGTH = 2;
const ERROR_BIGGER_FILE = 'The file should not be empty and its size should be 10MB or less';
const LOADER_SAVE_STATE = {
    save: 0,
    invite: 1
};

const TTAiGeneratorDialog = ({ open, flashMessage, classes, history, location }) => {
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Analyzing');
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadingSave, setLoadingSave] = useState(null);
    const [errorFile, setErrorFile] = useState(null);
    const [generatedUuid, setGeneratedUuid] = useState(null);
    const [generatedAssessment, setGeneratedAssessment] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [jobDescription, setJobDescription] = useState('');
    const [firedTextEvent, setFiredtextEvent] = useState(false);
    const [canGenerate, setCanGenerate] = useState(null);

    const { closeTTAiGeneratorDialog, ttBuilderSettings: { firstOpen }, company, updateCompany } = useContext(appCtx);

    useEffect(() => {
        if (!open) {
            onRestart();
            Zendesk.show();
            return;
        }
        getLimits();
        Zendesk.hide();
        if (open && firstOpen) {
            OnboardingEvents.GENERATE_LOADED();
        }
    }, [open]);

    const getLimits = () => {
        getGeneratedCount().then(({ data, success }) => {
            if (success) {
                const availableCount = data.count;
                setCanGenerate(availableCount === -1 || availableCount > 0);
            }
        });
    };

    const onSubmit = async () => {
        setLoading(true);
        setCurrentStep(0);
        let genUuid = generatedUuid;
        try {
            AssessmentBuilderEvents.GENERATE_NEXT_CLICKED({ description: genUuid ? 'file' : 'text' });
            if (!uploadedFile) {
                const { data: { uuid } } = await generateAssessment({
                    jobDescription
                });
                genUuid = uuid;
            }
            await generateAssessmentSkills(genUuid);
            setLoadingText('Matching Skills');
            await generateAssessmentSkillSet(genUuid);
            setLoadingText('Generating Assessment');
            await compoundGeneratedQuestionTypes(genUuid);
            const { data } = await getGeneratedCompletedQuestions(genUuid);
            setGeneratedUuid(genUuid);
            setGeneratedAssessment(data);
            setCurrentStep(2);
        } catch (e) {
            onRestart();
            flashMessage("Oops! Something’s not quite right on our end. Our team is on it, and we'll be back as soon as possible.", 'error');
        } finally {
            setLoadingText('Analysing');
            setLoading(false);
        }
    };

    const handleClose = () => {
        let step = '';
        if (currentStep === 1) step = 'start';
        if (currentStep === 2) step = 'preview';
        if (currentStep === 3) step = 'info page';
        AssessmentBuilderEvents.GENERATE_CLOSED({ step });
        closeTTAiGeneratorDialog();
    };

    const handleJobDescription = (e) => {
        setJobDescription(e.target.value);
        if (!firedTextEvent) {
            AssessmentBuilderEvents.GENERATE_DESCRIPTION_ADDED({ description: 'text' });
            setFiredtextEvent(true);
        }
    };

    const uploadFile = (file, request) => {
        request().then(({ data, success }) => {
            if (success) {
                setErrorFile(null);
                setUploadedFile(file);
                setGeneratedUuid(data.uuid);
                AssessmentBuilderEvents.GENERATE_DESCRIPTION_ADDED({ description: 'file' });
            }
        }).catch((err) => {
            setErrorFile(err?.response?.data?.msg);
        }).finally(() => setLoadingFile(false));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        if (file) {
            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                setUploadedFile(null);
                setErrorFile(`${file.name} is larger than 10MB, please upload a smaller file`);
                flashMessage(ERROR_BIGGER_FILE, 'error');
                return;
            }
            formData.append('file', file);
            setLoadingFile(true);
            if (generatedUuid && uploadedFile) {
                uploadFile(file, () => reUploadGenerationFile(formData, generatedUuid));
            } else {
                uploadFile(file, () => uploadGenerationFile(formData));
            }

            e.target.value = '';
        }
    };

    const onRestart = () => {
        setUploadedFile(null);
        setGeneratedUuid(null);
        setJobDescription('');
        setCurrentStep(1);
        setFiredtextEvent(false);
        setTimeout(() => setErrorFile(null), 200);
    };

    const handleRestartClicked = () => {
        onRestart();
        AssessmentBuilderEvents.GENERATE_RESTART_CLICKED();
    };

    const onSave = (withRedirect = false) => {
        setLoadingSave(withRedirect ? LOADER_SAVE_STATE.invite : LOADER_SAVE_STATE.save);
        saveGeneratedAssessment(generatedUuid).then(({ success, data }) => {
            if (success) {
                const { slug, active } = data;
                const { companySettings } = company;
                const { activeAuditionsPerCompanyUsed } = companySettings;
                updateCompany({
                    ...company,
                    companySettings: {
                        ...companySettings,
                        activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed + 1
                    }
                });
                if (withRedirect) {
                    if (active) {
                        history.push(`/script/invite/${slug}/invitation`);
                    } else {
                        history.push(`/script/select/${slug}`);
                    }
                } else {
                    const onMainPage = location.pathname.includes('my-assessments');
                    if (onMainPage) {
                        history.push('/my-assessments', { needRefetch: true });
                    }
                }
                closeTTAiGeneratorDialog();

                const fireEvent = withRedirect
                    ? AssessmentBuilderEvents.GENERATE_INVITE_CLICKED
                    : AssessmentBuilderEvents.GENERATE_SAVE_CLICKED;
                fireEvent({
                    skills: generatedAssessment.skillsExplanation.map(el => el.skill),
                    numberSkills: generatedAssessment.skillsExplanation.length,
                    questionNumber: generatedAssessment.completedQuestions.length,
                    title: generatedAssessment.auditionName,
                    onboarding: Boolean(firstOpen)
                });
            }
        }).finally(() => setLoadingSave(null));
    };

    const handleSampleClick = () => {
        AssessmentBuilderEvents.GENERATE_SAMPLE_CLICKED();
        window.open('/docs/sample_job_description.pdf', '_blank');
    };

    const handleReviewAndInvite = () => {
        AssessmentBuilderEvents.GENERATE_PREVIEW_INVITE_CLICKED({ questionNumber: generatedAssessment.completedQuestions.length });
        setCurrentStep(3);
    };

    const handleResetClick = () => {
        AssessmentBuilderEvents.GENERATE_RESET_CLICKED();
        getLimits();
        onRestart();
    };

    const renderUploadArea = () => {
        if (errorFile) {
            return (
                <>
                    <img src={AlertCircleIcon} className="u-mrg--bx2" alt="" />
                    <div className="helperText error">{errorFile}</div>
                    <div>
                        <label htmlFor="file-upload" className={classes.baseButton}>Re-upload</label>
                    </div>
                </>
            );
        }
        if (uploadedFile) {
            return (
                <>
                    <img src={LargeCheckIcon} className="u-mrg--bx2" alt="" />
                    <div className="helperText">{uploadedFile.name} uploaded</div>
                    <div>
                        <label htmlFor="file-upload" className={classes.baseButton}>Re-upload</label>
                    </div>
                </>
            );
        }
        return (
            <>
                <label htmlFor="file-upload" className="u-dsp--f u-jc--center u-mrg--tx4">
                    <i className="c-img-dropzone__icon icon-upload-cloud" />
                </label>
                <div className="helperText">Please make sure the file is under 10MB in .pdf format</div>
            </>
        );
    };

    const validJobDescriptionLength = jobDescription.length >= MIN_JOB_DESCRIPTION_LENGTH;
    const disabledButton = !canGenerate || (!uploadedFile && !validJobDescriptionLength);
    const disableUpload = !canGenerate || validJobDescriptionLength || loadingFile;

    const renderActionComponent = () => {
        if (loading) {
            return null;
        }

        if (currentStep === 3) {
            return (
                <Button
                    color="primary"
                    variant="text"
                    className={classes.startAgainButton}
                    onClick={handleResetClick}
                >
                    Start again, create a new assessment for another role
                </Button>
            );
        }

        return (
            <>
                {
                    (Boolean(uploadedFile) || validJobDescriptionLength) && (
                        <Button
                            color="primary"
                            variant="text"
                            className={classes.nextButton}
                            onClick={handleRestartClicked}
                        >
                            Restart
                        </Button>
                    )
                }
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.nextButton}
                    onClick={onSubmit}
                    disabled={disabledButton}
                >
                    Next
                </Button>
            </>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            fullScreen
            actionComponent={renderActionComponent()}
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress
                        size={60}
                    />
                    <div className="text">{loadingText}</div>
                </div>
            )}
            <div className={clsx(classes.wrapper, 'u-dsp--f')}>
                {
                    currentStep === 1 && (
                        <>
                            <Sidebar firstOpen={firstOpen} canGenerate={canGenerate} />
                            <div className={classes.content}>
                                <div className={classes.title}>Upload</div>
                                <div className={clsx(classes.uploadArea, disableUpload && 'disabled')}>
                                    {
                                        loadingFile && (
                                            <div className={classes.fileLoadingOverlay}>
                                                <CircularProgress
                                                    size={30}
                                                />
                                            </div>
                                        )
                                    }
                                    { renderUploadArea() }
                                    <input
                                        className={classes.invisible}
                                        type="file"
                                        accept=".pdf,application/pdf"
                                        disabled={disableUpload}
                                        onChange={handleFileUpload}
                                        hidden
                                        id="file-upload"
                                    />
                                </div>
                                <Button
                                    color="primary"
                                    variant="text"
                                    onClick={handleSampleClick}
                                >
                                    View Sample JD Format
                                </Button>
                                <div className={classes.divider}>OR</div>
                                <div className={classes.title}>Paste Text</div>
                                <TextInput
                                    disabled={Boolean(uploadedFile) || loadingFile || !canGenerate}
                                    className={classes.textInput}
                                    multiline
                                    rows={10}
                                    hasCounter
                                    maxLength={25000}
                                    label="Job Description"
                                    placeholder="Place text here"
                                    onChange={handleJobDescription}
                                    value={jobDescription}
                                    counterClassName={classes.counterClass}
                                    helperTextClassName={classes.helperInputClass}
                                />
                            </div>
                        </>
                    )
                }
                {
                    currentStep === 2 && (
                        <PreviewScript
                            onClose={handleClose}
                            assessment={generatedAssessment}
                            open={currentStep === 2}
                            onSubmit={() => setCurrentStep(3)}
                            showActionTooltips={false}
                            actionsText={{
                                next: 'Next',
                                submit: 'Review and Invite'
                            }}
                            headerActions={(
                                <Button variant="contained" color="primary" className={classes.buttonReview} onClick={handleReviewAndInvite}>
                                    Review and Invite
                                </Button>
                            )}
                        />
                    )
                }
                {
                    currentStep === 3 && (
                        <div className={classes.readyWrapper}>
                            <div className="title">Your assessment is ready to go!</div>
                            <div className="assessmentName">{generatedAssessment.auditionName}</div>
                            <div className="table">
                                <div className="tableHeader">
                                    <div>
                                        <img src={QuestionIcon} alt="" />
                                        Number of Questions: <span>{generatedAssessment.completedQuestions.length}</span>
                                    </div>
                                    <div>
                                        <img src={TimerIcon} alt="" />
                                        {generatedAssessment.timeToComplete} minutes
                                    </div>
                                </div>
                                <div className="tableBody">
                                    <div className="skills">
                                        <div>Skills Covered:</div>
                                        <ol>
                                            {
                                                generatedAssessment.skillsExplanation.map((el, idx) => (
                                                    <li key={idx} className="u-mrg--tx3">{el.skill}: <span>{el.explanation}</span></li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                    <div className="questionTypes">
                                        <div>Question Types:</div>
                                        {
                                            generatedAssessment.questionTypes.map((el, idx) => (
                                                <div key={idx} className="u-mrg--tx3">
                                                    {renderQuestionType(el)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <Button
                                color="primary"
                                variant="contained"
                                className={clsx(classes.footerButton, classes.inviteButton)}
                                onClick={() => onSave(true)}
                                disabled={loadingSave}
                            >
                                {
                                    loadingSave === LOADER_SAVE_STATE.invite ? (
                                        <CircularProgress
                                            size={20}
                                        />
                                    ) : <span>Invite Candidates</span>
                                }

                            </Button>
                            <div className="footerHelpText">
                                Find it anytime in your saved assessments
                            </div>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => onSave()}
                                disabled={loadingSave}
                                className={classes.footerButton}
                            >
                                {
                                    loadingSave === LOADER_SAVE_STATE.save ? (
                                        <CircularProgress
                                            size={20}
                                        />
                                    ) : <span>Save for Later</span>
                                }
                            </Button>
                        </div>
                    )
                }
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTAiGeneratorDialog));
