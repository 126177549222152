import React, { useEffect, useState, useRef, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { Divider, Typography, Switch, Button, IconButton, CircularProgress } from '@mui/material';
import { checkHasCompanyFeature, checkCompanyCanBeUpgraded } from 'helper/commonFunctions';
import {
    loadChatbotSettings, changeChatbotSettings, changeChatbotWidgetSettings,
    addChatbotWidgetLink, removeChatbotWidgetLink, changeChatbotWidgetLink
} from 'requests/ChatbotRequests';
import CloseIcon from '@mui/icons-material/Close';
import Zendesk from 'helper/zendeskFunctions';
import TextInput from 'libraries/TextInput';

import { appCtx } from 'components/appStore';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import PlusIcon from 'components/icons/PlusIcon';
import Tooltip from 'libraries/Tooltip';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';

const styles = theme => ({
    talkToUsLink: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginLeft: '5px'
    },
    closeIcon: {
        marginTop: '19px',
        marginLeft: '7px'
    }
});

const ChatbotPage = observer(({ classes, setSaving, audition, company, history }) => {
    const [screeningChatbotAllowed, setChatbotAllowed] = useState(false);
    const [widgetEnabled, setWidgetEnabled] = useState(false);
    const [assessmentPageLinks, setAssessmentPageLinks] = useState([]);
    const [loadingRemoveLink, setLoadingRemoveLink] = useState(null);
    const timerId = useRef(null);
    const { flashMessage } = useContext(appCtx);


    const {
        slug, screeningChatbot, companyChatbotWidgetEnabled,
        countValidScreeningQuestions, uuid: ttUUID, name: ttName
    } = audition;
    const hasChatbotFeature = checkHasCompanyFeature(company, 'AUDITION_CONVERSATIONAL_SCREENING');
    const hasWidgetFeatureForCompany = company?.companySettings?.chatbotWidget;
    const isWidgetEnabledForCompany = companyChatbotWidgetEnabled;
    const widgetAllowed = (isWidgetEnabledForCompany && hasWidgetFeatureForCompany) && Boolean(countValidScreeningQuestions);

    useEffect(() => {
        setChatbotAllowed(screeningChatbot);
    }, [screeningChatbot]);


    useEffect(() => {
        if (widgetAllowed && screeningChatbot) {
            getChatbotWidgetSettings(slug);
        }
    }, [slug, widgetAllowed]);


    const openZendesk = () => {
        Zendesk.open(true);
    };

    const getChatbotWidgetSettings = async (scriptSlug) => {
        if (!hasChatbotFeature) return;
        const { data: { enabled, links } } = await loadChatbotSettings(scriptSlug);
        setWidgetEnabled(enabled);
        setAssessmentPageLinks(links);
    };

    const handleChangeWidget = async (e) => {
        const { checked } = e.target;
        setSaving(true);
        setWidgetEnabled(checked);
        await changeChatbotWidgetSettings(slug, checked);
        AssessmentSettingsEvents.ASSESSMENT_WIDGET_ENABLED({ enabled: checked, ttUUID, ttName });
        setSaving(false);
    };

    const handleChangeScreeningChatbotAllowed = async (e) => {
        const { checked } = e.target;
        setSaving(true);
        setChatbotAllowed(checked);
        await changeChatbotSettings(slug, checked);
        if (checked) getChatbotWidgetSettings(slug);
        setSaving(false);
    };

    const addEmptyLink = () => {
        const newPageLinks = [...assessmentPageLinks];
        newPageLinks.push({ url: '', id: '', error: '' });
        setAssessmentPageLinks(newPageLinks);
    };

    const handleLink = index => (e) => {
        const { value } = e.target;
        const newPageLinks = [...assessmentPageLinks];
        newPageLinks[index].url = value;
        newPageLinks[index].error = '';
        setAssessmentPageLinks(newPageLinks);

        clearTimeout(timerId.current);
        const currentLink = assessmentPageLinks[index];
        timerId.current = setTimeout(() => {
            const { id } = currentLink;
            if (id) {
                changeLink(id, value, index);
            } else {
                addLink(value, index);
            }
        }, 300);
    };

    const addLink = async (newLinkValue, index) => {
        try {
            const { data } = await addChatbotWidgetLink(slug, newLinkValue);
            const newPageLinks = [...assessmentPageLinks];
            newPageLinks[index].id = data.id;
            setAssessmentPageLinks(newPageLinks);
        } catch (e) {
            if (e?.response?.data?.msg) {
                const newPageLinks = [...assessmentPageLinks];
                newPageLinks[index].error = e?.response?.data?.msg;
                setAssessmentPageLinks(newPageLinks);
            } else {
                flashMessage('Something went wrong.');
            }
        }
    };


    const changeLink = async (id, value, index) => {
        try {
            await changeChatbotWidgetLink(id, value);
        } catch (e) {
            if (e?.response?.data?.msg) {
                const newPageLinks = [...assessmentPageLinks];
                newPageLinks[index].error = e?.response?.data?.msg;
                setAssessmentPageLinks(newPageLinks);
            } else {
                flashMessage('Something went wrong.');
            }
        }
    };

    const removeLink = (index, linkId) => async () => {
        try {
            if (linkId) {
                setLoadingRemoveLink(linkId);
                await removeChatbotWidgetLink(linkId);
            }
            const newPageLinks = [...assessmentPageLinks];
            newPageLinks.splice(index, 1);
            setAssessmentPageLinks(newPageLinks);
            setLoadingRemoveLink(null);
        } catch (e) {
            setLoadingRemoveLink(null);
        }
    };

    const returnTooltipNoScreening = children => (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        maxWidth: '230px',
                        textAlign: 'center'
                    }
                }
            }}
            label={!countValidScreeningQuestions ? 'To enable the chatbot experience, you\'ll first need to add screening to the assessment' : ''}
        >
            <span>{children}</span>
        </Tooltip>
    );

    return (
        <div>
            <div className="u-mrg--bx5">
                <div className="u-dsp--distribute u-fw--wrap">
                    <div>
                        <Typography variant="h5">Screening Chatbot</Typography>
                    </div>
                    {returnTooltipNoScreening(
                        <Switch
                            checked={screeningChatbotAllowed}
                            disabled={(!hasChatbotFeature || !countValidScreeningQuestions) && !screeningChatbotAllowed}
                            onChange={handleChangeScreeningChatbotAllowed}
                            value="reminder"
                        />
                    )}
                </div>
                {
                    !hasChatbotFeature && (
                        <div>
                            Want to enable custom conversational screening chatbot?
                            {
                                checkCompanyCanBeUpgraded(company)
                                    ? <SubscriptionsLink label="Upgrade account now" className={classes.talkToUsLink} segmentLocation="chatbot assessment settings" />
                                    : <span role="presentation" className={classes.talkToUsLink} onClick={openZendesk}>Talk to us.</span>
                            }
                        </div>
                    )
                }
            </div>
            <Divider sx={{ borderColor: '#F3F3F3', borderBottomWidth: '2px' }} />
            <div className="u-mrg--bx5 u-mrg--tx4">
                <div className="u-dsp--distribute u-fw--wrap">
                    <div>
                        <Typography variant="h5">Chatbot Widget</Typography>
                    </div>
                    {returnTooltipNoScreening(
                        <Switch
                            disabled={(!widgetAllowed && !widgetEnabled) || !screeningChatbotAllowed}
                            checked={widgetEnabled && screeningChatbotAllowed}
                            onChange={handleChangeWidget}
                            value="reminder"
                        />
                    )}
                </div>
                {
                    !hasWidgetFeatureForCompany && (
                        <div>
                            The Chatbot Widget is not available on the current plan. To enable you will need to
                            {
                                checkCompanyCanBeUpgraded(company)
                                    ? <SubscriptionsLink label="upgrade account" className={classes.talkToUsLink} segmentLocation="chatbot assessment settings" />
                                    : <span role="presentation" className={classes.talkToUsLink} onClick={openZendesk}>talk to us.</span>
                            }
                        </div>
                    )
                }
                {
                    (hasWidgetFeatureForCompany && !isWidgetEnabledForCompany) && (
                        <div>
                            Allow screening widget?
                            <span role="presentation" className={classes.talkToUsLink} onClick={() => { history.push('/chatbot-settings'); }}>This setting should be enabled on the account level first.</span>
                        </div>
                    )
                }
            </div>
            {
                screeningChatbotAllowed
                && (
                    <div>
                        <Typography variant="h5" className=" u-mrg--bx1">Assessment Page Link</Typography>
                        <div className="u-mrg--bx3">Set the page that should contain the widget.</div>
                        {
                            assessmentPageLinks.map(({ id, url, error }, index) => (
                                <div className="u-dsp--f u-ai--start u-wdt--100p  u-pos--relative" key={index}>
                                    <TextInput
                                        value={url}
                                        label="Assessment Page URL"
                                        onChange={handleLink(index)}
                                        isError={Boolean(error)}
                                        sx={{ '& .Mui-disabled': { opacity: '1 !important', '-webkit-text-fill-color': '#292A2D !important' } }}
                                        helperText={error || 'Please paste URL the chatbot should appear on'}
                                    />
                                    <IconButton
                                        type="button"
                                        onClick={removeLink(index, id)}
                                        className={classes.closeIcon}
                                        disabled={!widgetAllowed || loadingRemoveLink}
                                        size="large"
                                    >
                                        {
                                            loadingRemoveLink === id ? (
                                                <CircularProgress size={20} />
                                            ) : <CloseIcon />
                                        }

                                    </IconButton>
                                </div>
                            ))
                        }
                        <Button color="primary" disabled={!widgetAllowed || !widgetEnabled} onClick={addEmptyLink}>
                            <PlusIcon color="#1247F6" className="u-mrg--rx1" /> Add Assessment Page Link
                        </Button>
                    </div>
                )
            }
        </div>
    );
});

export default withStyles(styles)(withRouter(ChatbotPage));
