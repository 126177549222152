import React from 'react';
import { withStyles, withTheme } from '@mui/styles';
import clsx from 'clsx';
import ShieldIcon from 'components/icons/ShieldIcon';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';


const stylesJS = theme => ({
    skillWrapper: {
        padding: '4px 15px 4px 13px',
        borderRadius: 50,
        marginRight: 15,
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: theme.palette.green[50],
        color: theme.palette.green[900],
        '& svg': {
            marginRight: 4,
            minWidth: 24
        }
    }
});


const SkillLabel = ({ classes, theme, skill, className }) => (
    <div className={clsx('u-dsp--centered', classes.skillWrapper, className)}>
        <ShieldIcon size={20} color={theme.palette.green[900]} />
        <TruncateWithTooltip text={skill} width={180} />
    </div>
);

export default withTheme(withStyles(stylesJS)(SkillLabel));
