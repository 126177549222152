import React, { useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Layout from 'components/layout';
import AssessmentList from 'components/assessments_pages/AssessmentList';
import InnerHeader from 'components/layout/InnerHeader';

import CreateNewAssessment from 'components/assessments_pages/CreateNewAssessment';
import * as qs from 'query-string';
import { AssessmentQueryCtx } from 'components/assessments_pages/AssessmentList/queryStore';
import { FilterCtx } from './filter-store';
import MarketplaceSearchField from './MarketplaceSearchField';

import styles from './styles';
import FilterBar from './FilterBar';

const Marketplace = observer(({ classes, company, location }) => {
    const { handleFilter } = useContext(FilterCtx);
    const { setQuery } = useContext(AssessmentQueryCtx);
    const parsed = qs.parse(location.search);

    useEffect(() => {
        if (parsed.assessmentName) {
            setQuery('');
            handleFilter('query', parsed.assessmentName);
        }
    }, []);

    return (
        <Layout>
            <InnerHeader
                header="Assessment Library"
                className={classes.wrapper}
                rightPart={(
                    <div className={classes.boxWrapper}>
                        <MarketplaceSearchField
                            wrapClass={classes.wrapClass}
                            className={classes.searchField}
                            handleFilter={handleFilter}
                        />
                        <CreateNewAssessment
                            buttonLabel="New Assessment"
                        />
                    </div>
                )}
            />
            <Container disableGutters>
                <div className={classes.wrapBox}>
                    <FilterBar />
                    <div className={classes.assessmentListWrap}>
                        <AssessmentList
                            company={company}
                            publicAssessments
                        />
                    </div>
                </div>
            </Container>
        </Layout>
    );
});

export default withRouter(withStyles(styles)(Marketplace));
