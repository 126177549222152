import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CreateSetDialog from '../../../../common/CreateSetDialog';
import Icon from './icon.svg';

const EmptySetsList = ({
    selectedSkill, sets, setSets,
    setSelectedSet, setSelectedIndex
}) => {
    const [openedCreateDialog, setOpenedCreateDialog] = useState(false);

    const handleCreateDialog = () => {
        setOpenedCreateDialog(!openedCreateDialog);
    };

    const onCreate = (skill) => {
        if ((selectedSkill && skill.companySkill && skill.companySkill.id === selectedSkill.id) || !selectedSkill) {
            setSets([skill, ...sets]);
            setSelectedSet(skill);
            setSelectedIndex(0);
        }
    };

    if (selectedSkill === -1) {
        return (
            <div className="u-txt--center u-mrg--tx4">
                <img src={Icon} className="u-mrg--bx2" alt="+" />
                <Typography className="u-mrg--bx1" variant="h4">No sets without skill</Typography>
            </div>
        );
    }

    return (
        <>
            <div className="u-txt--center u-mrg--tx4">
                <img src={Icon} className="u-mrg--bx2" alt="+" />
                <Typography className="u-mrg--bx1" variant="h4">Add a set</Typography>
                <p>You haven’t added any sets to this skill yet.
                    <Button color="primary" onClick={handleCreateDialog}>Add your set now</Button>
                </p>
            </div>
            <CreateSetDialog
                openedDialog={openedCreateDialog}
                handleDialog={handleCreateDialog}
                selectedSkill={selectedSkill}
                onSuccess={onCreate}
            />
        </>
    );
};

export default EmptySetsList;
