import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Modifier from 'draft-js/lib/DraftModifier';
import { useStyles } from './styles';

let timerId = null;

export const customInlineStyleFn = (style) => {
    const styles = {};
    if (style.startsWith('color-rgb(')) {
        styles.color = style.substring('color-'.length);
    } else if (style.startsWith('bgcolor-rgb(')) {
        styles.backgroundColor = style.substring('bgcolor-'.length);
    } else if (style.startsWith('fontfamily-')) {
        styles.fontFamily = style.substring('fontfamily-'.length);
    } else if (style.startsWith('fontsize-')) {
        styles.fontSize = `${style.substring('fontsize-'.length)}px`;
    } else if (style === 'SUBSCRIPT') {
        styles.verticalAlign = 'sub';
        styles.fontSize = 'smaller';
    } else if (style === 'SUPERSCRIPT') {
        styles.verticalAlign = 'super';
        styles.fontSize = 'smaller';
    }

    return { style: styles };
};

const customStateToHTML = contentState => stateToHTML(contentState, {
    blockRenderers: {
        code: (block) => {
            const text = block.getText();
            return `<pre style="background: rgba(0, 0, 0, 0.05); padding: 5px; border-radius: 5px; white-space: pre-wrap; word-wrap: break-word;">${text}</pre>`;
        }
    },
    blockStyleFn: (block) => {
        const alignment = block.getData().get('text-align');
        if (alignment) {
            return {
                style: { textAlign: alignment }
            };
        }
        return {};
    },
    inlineStyleFn: (styles) => {
        const styleArr = styles.toArray();
        const styleObj = {};
        styleArr.forEach((style) => {
            const customStyle = customInlineStyleFn(style);
            if (customStyle) {
                Object.assign(styleObj, customStyle.style);
            }
        });
        if (Object.keys(styleObj).length > 0) {
            return { style: styleObj };
        }
        return null;
    },
    entityStyleFn: (entity) => {
        const entityType = entity.getType().toLowerCase();
        if (entityType === 'link') {
            return {
                element: 'a',
                attributes: {
                    href: entity.getData().url,
                    style: 'color: #3b5998; text-decoration: underline;'
                }
            };
        }
        return null;
    }
});

const HANDLE_STATUS = {
    HANDLED: 'handled',
    NOT_HANDLED: 'not-handled'
};

const ReactDraftWysiwyg = ({ value, onEditorStateChange, delay, html, answerType, disabled, limit, ...props }) => {
    const classes = useStyles({});

    const createInitialState = () => {
        if (value) {
            return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
        }
        if (html) {
            const contentState = ContentState.createFromText(html);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    };

    const [touched, setTouched] = useState(false);
    const [content, setContent] = useState(createInitialState());
    const effectiveLimit = limit ? limit + 1 : undefined; // add 1 for getting the error msg from backend

    useEffect(() => {
        if (onEditorStateChange) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                const richTextEditorValue = JSON.stringify(convertToRaw(content.getCurrentContent()));
                const currentPlainText = content.getCurrentContent().getPlainText();
                const editorHtml = customStateToHTML(content.getCurrentContent());
                onEditorStateChange(richTextEditorValue, currentPlainText, editorHtml, touched);
            }, delay);
        }
    }, [content, touched]);

    useEffect(() => {
        setContent(createInitialState());
    }, [answerType]);

    const handleChange = (editorState) => {
        setTouched(true);
        setContent(editorState);
    };

    const handleBeforeInput = (chars, editorState) => {
        if (!effectiveLimit) return HANDLE_STATUS.NOT_HANDLED;
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;

        // Prevent input if limit is exceeded
        if (currentContentLength >= effectiveLimit) {
            return HANDLE_STATUS.HANDLED;
        }
        return HANDLE_STATUS.NOT_HANDLED;
    };

    const handlePastedText = (pastedText, htmlText, editorState) => {
        if (!effectiveLimit) return HANDLE_STATUS.NOT_HANDLED;
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;

        if (currentContentLength + pastedText.length > effectiveLimit) {
            const allowedText = pastedText.slice(0, effectiveLimit - currentContentLength);
            const newContent = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                allowedText
            );
            const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
            setContent(newEditorState);
            return HANDLE_STATUS.HANDLED;
        }
        return HANDLE_STATUS.NOT_HANDLED;
    };


    const toolbarSettings = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove'],
        textAlign: {
            options: ['left', 'center', 'right', 'justify']
        },
        fontFamily: {
            options: ['Open Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            default: 'Open Sans'
        }
    };

    return (
        <Editor
            toolbar={toolbarSettings}
            editorState={content}
            wrapperClassName={classes.wrapper}
            editorClassName={classes.editor}
            onEditorStateChange={handleChange}
            readOnly={disabled}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            {...props}
        />
    );
};

export default ReactDraftWysiwyg;
