import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import GeneralEvents from 'events/GeneralEvents';

const styles = {
    previewButton: {
        padding: '8px 24px',
        lineHeight: '21px',
        whiteSpace: 'nowrap'
    },
    previewLink: {
        padding: '8px 0 9px',
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        minWidth: 38,
        borderRight: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '& img': {
            width: 20,
            height: 20
        },
        '&:disabled img': {
            opacity: 0.4
        }
    }
};


const PreviewButton = observer(({ slug, classes, disabled, audition }) => {
    const [openPreviewScript, setOpenPreviewScript] = useState(false);
    const { uuid, name, active, generatedViaAi } = audition;


    const handlePreviewScript = () => {
        setOpenPreviewScript(!openPreviewScript);
        GeneralEvents.TT_PREVIEWED({
            ttId: uuid,
            ttName: name,
            publicLibrary: false,
            activeAssessment: active
        });
    };

    return (
        <>
            <Button
                variant="outlined"
                className={classes.previewButton}
                disabled={disabled}
                onClick={handlePreviewScript}
            >
                Preview{generatedViaAi && " and Edit"}
            </Button>
            <PreviewScript
                {...{
                    onClose: handlePreviewScript,
                    interviewSlug: slug,
                    open: openPreviewScript,
                    uuid,
                    generatedViaAi
                }}
            />
        </>
    );
});

export default withRouter(withStyles(styles)(PreviewButton));
