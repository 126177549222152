import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import RemainingTimeLabel from 'components/job_or_assessment_settings/RemainingTimeLabel';

import AddToSavedButton from 'pages/assessment/components/AddToSavedButton';
import { createCompanyQuestion, createCompanyQuestionFromSuggested } from 'requests/QuestionsRequests';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';
import styles from './styles';

const QuestionHeader = ({
    index, classes, question,
    interview, isLibrary, isClosedAssessment
}) => {
    const { company } = useContext(appCtx);
    const hasQuestionBanks = checkHasCompanyFeature(company, 'QUESTION_BANKS');

    const { questionSkill, uuid, questionGroup, id, screeningQuestion } = question;
    const { timerEnabled, countValidScreeningQuestions = 0 } = interview;

    const skill = questionSkill || questionGroup?.title;
    const disabled = !skill || !hasQuestionBanks;

    const request = () => {
        if (isLibrary) return createCompanyQuestionFromSuggested(uuid, questionSkill);
        if (isClosedAssessment) return createCompanyQuestion(id);
    };

    return (
        <div className={classes.headerWrapper}>
            <div className={classes.numberQuestion}>
                {screeningQuestion ? 'Screening Question' : 'Question'} {index + 1 - (screeningQuestion ? 0 : countValidScreeningQuestions)}
                <RemainingTimeLabel
                    question={question}
                    auditionTimerEnabled={timerEnabled}
                />
                {
                    question.required && (
                        <span className={classes.requiredLabel}>
                            Required
                        </span>
                    )
                }
            </div>
            {
                (isLibrary || isClosedAssessment) && (
                    <AddToSavedButton
                        onSave={request}
                        question={question}
                        activeQuestion={index}
                        disabled={disabled}
                        missFeature={!hasQuestionBanks}
                    />
                )
            }
        </div>
    );
};

export default withStyles(styles)(withRouter(QuestionHeader));
