import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import TooltipBlack from 'libraries/Tooltip';
import { IconButton } from '@mui/material';
import EditIcon from 'img/edit.svg';
import RemoveGreyIcon from 'img/trash.svg';
import AddGreyIcon from 'img/add_grey.svg';
import SaveIcon from 'components/icons/SaveIcon';
import EditSetDialog from '../../EditSetDialog';
import DeleteSetDialog from '../../DeleteSetDialog';

const SetActions = observer(({
    set, onEdit, classes, onDelete, showOptions, handleOpenCreateDrawer, handleOpenAddQuestionDrawer, disabled
}) => {
    const [openedEditDialog, setOpenedEditDialog] = useState(false);
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

    const handleEditDialog = () => {
        setOpenedEditDialog(!openedEditDialog);
    };

    const handleDeleteDialog = () => {
        setOpenedDeleteDialog(!openedDeleteDialog);
    };


    if (disabled) {
        return (
            <>
                <div>
                    <IconButton className={classes.icon} size="large" disabled>
                        <img src={EditIcon} alt="edit" />
                    </IconButton>
                    <IconButton className={classes.icon} size="large" disabled>
                        <img src={AddGreyIcon} alt="edit" />
                    </IconButton>
                    <IconButton className={classes.icon} size="large" disabled>
                        <SaveIcon color="#a9aaae" />
                    </IconButton>
                    <IconButton className={classes.icon} size="large" disabled>
                        <img src={RemoveGreyIcon} alt="edit" />
                    </IconButton>
                </div>
            </>
        );
    }
    const { showEdit, showDelete, showCreateQuestion, showAddQuestion } = showOptions;

    return (
        <>
            <div>
                {
                    showEdit && (
                        <>
                            <TooltipBlack label="Edit Set">
                                <IconButton onClick={handleEditDialog} className={classes.icon} size="large">
                                    <img src={EditIcon} alt="edit" />
                                </IconButton>
                            </TooltipBlack>
                            <EditSetDialog
                                set={set}
                                handleDialog={handleEditDialog}
                                openedDialog={openedEditDialog}
                                onSuccess={onEdit}
                            />
                        </>
                    )
                }
                {
                    showCreateQuestion && (
                        <>
                            <TooltipBlack label="Create Question">
                                <IconButton onClick={handleOpenCreateDrawer} className={classes.icon} size="large">
                                    <img src={AddGreyIcon} alt="edit" />
                                </IconButton>
                            </TooltipBlack>
                        </>
                    )
                }
                {
                    showAddQuestion && (
                        <>
                            <TooltipBlack label="Add Question">
                                <IconButton onClick={handleOpenAddQuestionDrawer} className={classes.icon} size="large">
                                    <SaveIcon color="#a9aaae" />
                                </IconButton>
                            </TooltipBlack>
                        </>
                    )
                }
                {
                    showDelete && (
                        <>
                            <TooltipBlack label="Delete Set">
                                <IconButton onClick={handleDeleteDialog} className={classes.icon} size="large">
                                    <img src={RemoveGreyIcon} alt="edit" />
                                </IconButton>
                            </TooltipBlack>
                            <DeleteSetDialog
                                openedDialog={openedDeleteDialog}
                                handleDialog={handleDeleteDialog}
                                selectedSetData={set}
                                onSuccess={onDelete}
                            />
                        </>
                    )
                }
            </div>
        </>
    );
});

export default SetActions;
