import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import Tooltip from 'libraries/Tooltip';
import TextInput from 'libraries/TextInput';
import { moveAuditionToFolder, moveAuditionToDefaultFolder, createFolder } from 'requests/FoldersRequests';
import SkillsOrTagsMenuItem from 'components/assessments_pages/asessment_settings_components/SkillsOrTagsMenuItem';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

import { appCtx } from '../../appStore';
import { foldersCtx } from '../../assessments_pages/AssessmentFolders/store';

import FolderAddedIcon from './folderAddedIcon';
import FolderEmptyIcon from './folderEmptyIcon';

import styles from './styles';
import { AssessmentQueryCtx } from '../../assessments_pages/AssessmentList/queryStore';


const FoldersMenu = observer(({
    classes, auditionFolder,
    slug, removeAssessment,
    uuid: auditionUuid, name: auditionName,
    setAssessmentProperty
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingAddToFolder, setLoadingAddToFolder] = useState(false);
    const [loadingName, setLoadingName] = useState(null);
    const [newFolderName, setNewFolderName] = useState('');

    const { flashMessage } = useContext(appCtx);
    const { folders, getFolders, activeFolderId } = useContext(foldersCtx);
    const { query: folderQuery } = useContext(AssessmentQueryCtx);

    const open = Boolean(anchorEl);
    const popoverId = open ? 'popover' : undefined;

    const openPopover = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
        setNewFolderName('');
        setAnchorEl(null);
    };

    const isActiveFolder = (id) => {
        if (!auditionFolder && !id) return true;
        if (!auditionFolder && id) return false;
        return auditionFolder.id === id;
    };

    const moveAssessmentToFolder = (folderId, folderName) => {
        let moveToAll = false;
        if (auditionFolder && auditionFolder.id) {
            moveToAll = folderId === auditionFolder.id;
        }
        const request = moveToAll ? moveAuditionToDefaultFolder : moveAuditionToFolder;
        setLoadingName(folderName);
        request(slug, folderId)
            .then(({ success }) => {
                if (success) {
                    flashMessage(moveToAll
                        ? `Assessment removed from ${folderName} folder`
                        : `Assessment moved to ${folderName} folder`, 'done');
                    if (moveToAll) {
                        MiscellaneousEvents.ASSESSMENT_REMOVED_FROM_FOLDER({
                            ttId: auditionUuid,
                            ttName: auditionName,
                            removedFromFolderName: folderName
                        });
                    } else {
                        MiscellaneousEvents.ASSESSMENT_MOVED_TO_FOLDER({
                            ttId: auditionUuid,
                            ttName: auditionName,
                            movedToFolderName: folderName
                        });
                    }
                    getFolders();
                    if (activeFolderId && !folderQuery.length) {
                        removeAssessment();
                    } else {
                        const addToFolderObj = folders.find(folder => folder.id === folderId);
                        setAssessmentProperty(slug, 'auditionFolder', moveToAll ? null : addToFolderObj);
                    }
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoadingName(null);
                handlePopoverClose();
            });
    };

    const onChange = (e) => {
        setNewFolderName(e.target.value);
    };

    const addNewFolder = () => {
        setLoadingAddToFolder(true);
        createFolder(newFolderName)
            .then(({ success, data }) => {
                if (success && data) {
                    folders.push({ auditionsCount: 0, ...data });
                    MiscellaneousEvents.FOLDER_CREATED({ folderName: newFolderName });
                    moveAssessmentToFolder(data.id, data.name);
                }
                setNewFolderName('');
            }).catch((err) => {
                if (err.response && err.response.data) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setLoadingAddToFolder(false);
            });
    };

    const hasFolder = auditionFolder && folders.some(({ id }) => id === auditionFolder.id);

    return <>
        <Tooltip
            label={(hasFolder && auditionFolder) ? auditionFolder.name : 'All Assessments'}
            tooltipClass={classes.tooltip}
        >
            <IconButton className={classes.organiseIcon} onClick={openPopover} size="large">
                {hasFolder
                    ? <FolderAddedIcon color="#1247F6" />
                    : <FolderEmptyIcon color="#A9AAAE" />
                }
            </IconButton>
        </Tooltip>
        <Popover
            id={popoverId}
            open={open}
            className={classes.popoverRoot}
            classes={{ paper: classes.popoverPaper }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            anchorEl={anchorEl}
            onClick={e => e.stopPropagation()}
            onClose={handlePopoverClose}
        >
            <div className={classes.popoverHeader}>
                <Typography variant="h6">Organise Assessments</Typography>
            </div>
            <div className={classes.popoverContent}>
                {
                    loadingAddToFolder && (
                        <div className={classes.loaderWrapper}>
                            <CircularProgress size={35} color="primary" />
                        </div>
                    )
                }
                <List className={classes.list}>
                    { folders.map(({ id, name }) => {
                        if (!id) return null;
                        return (
                            <SkillsOrTagsMenuItem
                                folder
                                key={id}
                                group={{ title: name }}
                                isActive={isActiveFolder(id)}
                                loadingName={loadingName}
                                onClick={() => moveAssessmentToFolder(id, name)}
                            />
                        );
                    })}
                </List>
            </div>
            <div className={classes.addSection}>
                <TextInput
                    variant="outlined"
                    placeholder="Create new folder"
                    className={classes.skillInput}
                    onChange={onChange}
                    value={newFolderName}
                    maxLength={150}
                />
                <Button
                    disabled={!newFolderName.length}
                    className="u-txt--bold"
                    onClick={addNewFolder}
                    color="primary"
                >
                    Add
                </Button>
            </div>
            <div className={classes.footer}>
                Assessments can be in a single folder only.
            </div>
        </Popover>
    </>;
});

export default withStyles(styles)(FoldersMenu);
