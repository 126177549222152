import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import RequiredQuestionLabel from 'components/job_or_assessment_settings/Chip';

import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import Tooltip from 'libraries/Tooltip';
import ItemStatus from 'components/assessments_pages/ItemStatus';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import { checkPluralNew } from 'helper/commonFunctions';
import exclamationMarkIcon from 'img/exclamationMark.svg';
import ItemSynchronizeStatus from 'components/assessments_pages/ItemSynchronizeStatus';
import QuestionCategoryIcon from 'img/questionCategory.svg';

import QuestionTimerLabel from './QuestionTimerLabel';
import GradingLabel from './GradingLabel';
import DuplicateStatus from './DuplicateStatus';
import ItemAttachmentsWrapper from './ItemAttachmentsWrapper';
import QuestionActions from './QuestionActions';

import QuestionSkillLabel from './QuestionSkillLabel';

const styles = theme => ({
    svgIconStyle: {
        maxWidth: 20,
        width: 20,
        height: 20,
        marginBottom: 0
    },
    wrapper: {
        position: 'relative',
        minHeight: 35
    },
    tooltipClass: {
        padding: '5px 15px 7px'
    },
    iconWrapper: {
        marginRight: 10,
        backgroundColor: theme.palette.green[600],
        borderRadius: 4,
        lineHeight: '5px',
        padding: '6px 9px'
    },
    iconWrapperInvalid: {
        backgroundColor: theme.palette.yellow[900]
    },
    value: {
        color: theme.palette.primary.main
    },
    warningIcon: {
        width: 16,
        marginRight: 10
    },
    categoryIcon: {
        width: 16,
        marginRight: 5
    },
    categoryLabel: {
        marginRight: 10,
        color: theme.palette.blue[800],
        fontWeight: 700,
        marginTop: -2,
        fontSize: 12
    },
    headerStatusRow: {
        gap: '10px 0',
        paddingRight: 120
    }
});

const QuestionItemHeader = ({
    classes, loadQuestions, question, hideMenu,
    selectedSkill, setSelectedQuestionId, showMenu,
    setAddToSetQuestionUuid, setRemoveFromSetQuestionUuid,
    assessmentPage, hideStatus, ...other
}) => {
    const {
        timerEnabled, timeLimit, status,
        answerType, companySkills, setsUsing,
        assessmentsUsing, valid, synchronized,
        countDuplicates, category
    } = question;

    const returnTypeImg = () => getQuestionTypeInfo(answerType, renderIconAndLabel);

    const renderIconAndLabel = (Icon, label, labelShort = label) => (
        <Tooltip
            label={labelShort}
            tooltipClass={classes.tooltipClass}
        >
            {
                Icon && (
                    <span className={clsx(classes.iconWrapper, !valid && classes.iconWrapperInvalid)}>
                        <Icon color="#fff" className={classes.svgIconStyle} />
                    </span>
                )
            }
        </Tooltip>
    );

    const hasSelectedSkill = selectedSkill && companySkills.some(({ id: skillId }) => skillId === selectedSkill.id);

    return (
        <div className={classes.wrapper}>
            <QuestionActions
                question={question}
                hideMenu={hideMenu}
                showMenu={showMenu}
                loadQuestions={loadQuestions}
                assessmentPage={assessmentPage}
                setAddToSetQuestionUuid={setAddToSetQuestionUuid}
                setSelectedQuestionId={setSelectedQuestionId}
                setRemoveFromSetQuestionUuid={setRemoveFromSetQuestionUuid}
                {...other}
            />
            <div className={clsx(classes.headerStatusRow, 'u-dsp--f u-ai--center u-mrg--bx2 u-fw--wrap')}>
                {returnTypeImg()}
                {
                    !valid && (
                        <Tooltip label="Invalid question">
                            <img
                                src={exclamationMarkIcon}
                                className={classes.warningIcon}
                                alt="!"
                            />
                        </Tooltip>
                    )
                }
                {
                    hasSelectedSkill && (
                        <QuestionSkillLabel skill={selectedSkill.title} />
                    )
                }
                {category && (
                    <span className={classes.categoryLabel}>
                        <img
                            src={QuestionCategoryIcon}
                            className={classes.categoryIcon}
                            alt="!"
                        />
                        { category }
                    </span>
                )}
                { timerEnabled && <QuestionTimerLabel timeLimit={timeLimit} /> }
                { question.required && <RequiredQuestionLabel uppercase /> }
                <GradingLabel question={question} />
            </div>
            {
                !hideStatus && (
                    <div className="u-dsp--f u-ai--center u-mrg--bx1">
                        { countDuplicates > 0 && <DuplicateStatus questionId={question.id} /> }
                        <ItemStatus status={status} />
                        {
                            status !== 'draft' && (
                                <ItemSynchronizeStatus className="u-mrg--lx1" synchronized={synchronized} />
                            )
                        }
                        {
                            Boolean(setsUsing.length) && (
                                <ItemAttachmentsWrapper>
                                    <Tooltip label={setsUsing.map(({ title }) => title).join(', ')}>
                                        <span className="u-txt--nowrap">
                                            In {setsUsing.length} {checkPluralNew(setsUsing.length, 'set')}
                                        </span>
                                    </Tooltip>
                                </ItemAttachmentsWrapper>
                            )
                        }
                        {
                            Boolean(assessmentsUsing.length) && (
                                <ItemAttachmentsWrapper>
                                    <Tooltip label={assessmentsUsing.map(({ name }) => name).join(', ')}>
                                        <span className="u-txt--nowrap">
                                                In {assessmentsUsing.length} {checkPluralNew(assessmentsUsing.length, 'assessment')}
                                        </span>
                                    </Tooltip>
                                </ItemAttachmentsWrapper>
                            )
                        }
                        {
                            (Boolean(hasSelectedSkill && companySkills.length - 1)
                            || Boolean(!hasSelectedSkill && companySkills.length)) && (
                                <ItemAttachmentsWrapper className="u-dsp--f u-fw--wrap">
                                    <span>{hasSelectedSkill ? 'Also in' : 'In'} {checkPluralNew(companySkills.length - 1, 'skill')}&nbsp;</span>
                                    {companySkills
                                        .filter(({ id: skillId }) => (hasSelectedSkill ? selectedSkill.id !== skillId : true))
                                        .map(({ title }, index) => (
                                            <>{Boolean(index) && <>,&nbsp;</>}
                                                <TruncateWithTooltip className={classes.value} text={title} width={130} />
                                            </>
                                        ))
                                    }
                                </ItemAttachmentsWrapper>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default withStyles(styles)(QuestionItemHeader);
