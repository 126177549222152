import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';

import styles from './styles';

const TagsList = ({ classes, activeFilter, handleRemove }) => {
    if (!activeFilter) return null;

    return (
        <div>
            <div className={classes.tag}>
                {activeFilter}
                <IconButton
                    className={classes.closeButton}
                    onClick={() => handleRemove('')}
                    size="large"
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>
        </div>
    );
};

export default withStyles(styles)(TagsList);
