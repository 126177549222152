import React from 'react';
import { withTheme } from '@mui/styles';

const CompareIcon = ({ color = '#7F8084' }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.66669" y="2.5" width="6.25" height="6.25" rx="1" stroke={color} strokeWidth="1.2" />
        <rect x="1.66669" y="12.0835" width="6.25" height="6.25" rx="1" stroke={color} strokeWidth="1.2" />
        <rect x="11.25" y="12.0835" width="6.25" height="6.25" rx="1" stroke={color} strokeWidth="1.2" />
        <path d="M17.5 5.41667H11.6667" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5834 2.5V8.33333" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default withTheme(CompareIcon);
