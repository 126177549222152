export default theme => ({
    codeWrapper: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '4px',
        padding: '15px 20px',
        marginTop: '20px',
        fontFamily: 'monospace',
        backgroundColor: theme.palette.grey[200]
    },
    span: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
});
