import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import Dialog from 'libraries/Dialog';
import GeneralEvents from 'events/GeneralEvents';
import { hireCandidates } from 'requests/CandidatesRequests';
import HiringFlowDialog from '../HiringFlowDialog';

import HireIcon from './icon.svg';
import styles from './styles';

const HireCandidateDialog = observer(({
    classes, open, onClose, columnUserId,
    flashMessage, callback, dataForEvent, slug, assessment, candidate, isIntegration
}) => {
    const [loading, setLoading] = useState(false);
    const [hireFlowDialogOpened, setHireFlowDialogOpened] = useState(false);
    const [hiredCandidate, setHiredCandidate] = useState(candidate ? [candidate] : null);

    const handleNextStepsDialogOpened = () => {
        setHireFlowDialogOpened(false);
    };

    const handleHire = () => {
        setLoading(true);
        hireCandidates(slug, [columnUserId])
            .then(({ data, success }) => {
                if (data && success) {
                    flashMessage('Nice work! Candidate hired', 'done');
                    if (!isIntegration) setHireFlowDialogOpened(true);
                    if (callback) callback();
                    dataForEvent.candidateHired = true;
                    GeneralEvents.CANDIDATE_HIRED(dataForEvent);
                }
            }).catch(() => {
                flashMessage('Something went wrong', 'error');
            }).finally(() => {
                setLoading(false);
                onClose();
            });
    };

    const updateUserFields = (updatedFields) => {
        setHiredCandidate(hiredCandidate.map(el => ({ ...el, ...updatedFields })));
    };

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={onClose}
                handleClose={onClose}
                contentClassName={classes.contentClassName}
                actionComponent={(
                    <>
                        <Button
                            className={classes.secondaryButton}
                            onClick={onClose}
                        >
                            Back
                        </Button>
                        <Button
                            className={classes.mainButton}
                            color="primary"
                            variant="contained"
                            onClick={handleHire}
                            disabled={loading}
                        >
                            Yes
                        </Button>
                    </>
                )}
            >
                <img src={HireIcon} alt="!" className="u-mrg--bx2" />
                <div className={classes.contentTitle}>
                    Mark candidate as hired?
                </div>
                <div className={classes.contentText}>
                    Marking candidates as hired will allow you to find and filter them in future from all candidates.
                </div>
            </Dialog>
            {hiredCandidate && (
                <HiringFlowDialog
                    callback={callback}
                    open={hireFlowDialogOpened}
                    onClose={handleNextStepsDialogOpened}
                    candidates={hiredCandidate}
                    assessment={assessment}
                    updateUserFields={updateUserFields}
                />
            )}
        </>
    );
});

export default withStyles(styles)(withRouter(HireCandidateDialog));
