import clsx from 'clsx';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { returnScoreLabel, isCalculating } from 'helper/assessmentFunctions';
import CalculatingLabel from 'components/scores/CalculatingLabel';

import TopPerformerLabel from '../../TopPerformerLabel';
import ScoreContainer from '../../ScoreContainer';
import GradedByList from '../../GradedByList';

import Achivements from './Achivements';
import styles from './styles';


const CardScoreBlock = ({ candidate, propPublic, gradeableCard, classes, isEmployerSortEnabled }) => {
    const {
        rank, rankTotal, score, topPerformer,
        gradedBy, candidateId, scoreExists, achievements, archived
    } = candidate;

    if (archived) return null;

    let overallScore = null;

    if (score) {
        const { score: newOverallScore } = score;
        overallScore = newOverallScore;
    }

    const renderedScoreLabel = returnScoreLabel(score, candidate, isEmployerSortEnabled);

    const renderScore = () => {
        if (!gradeableCard && isCalculating(candidate, (isEmployerSortEnabled && scoreExists) || !isEmployerSortEnabled)) {
            return (
                <>
                    <div className={classes.label}>
                        {renderedScoreLabel}
                    </div>
                    <CalculatingLabel
                        className="u-mrg--bx1 u-txt--12 u-txt--semibold"
                        fontSize={12}
                    />
                </>
            );
        }
        if (overallScore !== null) {
            return (
                <>
                    <div className={classes.label}>
                        {renderedScoreLabel}
                    </div>
                    <div className="u-dsp--f u-ai--center u-mrg--bx2">
                        <div className={classes.scoreLabel}>
                            {overallScore}%&nbsp;
                        </div>
                        <GradedByList
                            gradedBy={gradedBy}
                            candidateId={candidateId}
                        />
                    </div>
                </>
            );
        }
        return (
            <>
                <div className={clsx(classes.label, 'u-mrg--bx0')}>Score</div>
                <div className={classes.rankLabel}>-</div>
            </>
        );
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
                <div className={classes.container}>
                    <Typography variant="h6" className="u-mrg--bx1">Performance</Typography>
                    {renderScore()}
                    {
                        !gradeableCard && (
                            <>
                                <div className={classes.label}>Overall Rank</div>
                                <div className={classes.rankLabel}>
                                    {rank || '-'} / {rankTotal || '-'}
                                    {topPerformer && <TopPerformerLabel />}
                                </div>
                            </>
                        )
                    }
                </div>
                <div className={classes.scoreWrapper}>
                    <ScoreContainer candidate={candidate} propPublic={propPublic} />
                </div>
            </div>
            <Achivements achievements={achievements} />
        </div>
    );
};

export default withRouter(withStyles(styles)(CardScoreBlock));
