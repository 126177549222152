import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import InnerContainer from 'components/layout/InnerHeader';
import { appCtx } from 'components/appStore';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import { ASSESSMENT_BRANDING, PERMISSIONS } from 'helper/constants';

const SettingsHeader = observer(() => {
    const { loggedUser, isLoadingUser, company, isLoadingCompany } = useContext(appCtx);

    const { roleType } = loggedUser;
    const tabList = [
        { label: 'My Account', url: '/my-account' },
        { label: 'Notifications', url: '/notifications-settings' }
    ];

    const hasPermissionsFeature = checkHasCompanyFeature(company, PERMISSIONS);
    const hasCompanyBranding = checkHasCompanyFeature(company, ASSESSMENT_BRANDING);

    const adminPermissions = !((roleType === 'team' && hasPermissionsFeature) || roleType === 'integration');

    if (adminPermissions && !isLoadingUser && !isLoadingCompany) {
        const { freeTrialExpired, isSubscriptionPaused } = company;

        tabList.splice(1, 0, {
            label: 'Company Account', url: '/company-account'
        });

        if (hasCompanyBranding) {
            tabList.splice(2, 0, {
                label: 'Branding Details', url: '/branding-details'
            });
        }

        if (!freeTrialExpired && !isSubscriptionPaused) {
            tabList.splice(3, 0, {
                label: 'Team', url: '/team-settings'
            });
        }
        tabList.push({ label: 'Current Plan and Billing Details', url: '/billing' });
        tabList.push({ label: 'Plan Types', url: '/subscriptions' });

        if (!freeTrialExpired && !isSubscriptionPaused) {
            tabList.push({ label: 'Integrations', url: '/integrations-management' });
        }

        // tabList.push({ label: 'Chatbot Settings', url: '/chatbot-settings' });
    }

    return (
        <InnerContainer
            header="Account Details"
            tabList={tabList}
        />
    );
});

export default withRouter(SettingsHeader);
