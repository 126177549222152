import React, { Fragment, useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

// material components
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { CODE_TEST_AUTOGRADED } from 'helper/constants';
import { appCtx } from 'components/appStore';

// local files and components
import { observer } from 'mobx-react-lite';
import InfoError from 'img/candidateCard/info.svg';
import { clone } from 'helper/commonFunctions';
import Zendesk from 'helper/zendeskFunctions';
import {
    addCodeChallengeQuestion,
    deleteCodeChallengeQuestion, editCodeChallengeQuestion,
    getCodeChallengeQuestions, getTestCases
} from 'requests/QuestionsRequests';
import { createTestCase, deleteCase, editCase } from 'requests/ScriptRequests';
import SwitchAutoGrade from '../SwitchAutoGrade';
import ListLanguages from './components/ListLanguages';
import CodeEditorPreview from './components/CodeEditorPreview';
import CodeEditorDialog from './components/CodeEditorDialog';
import styles from './styles';

let timerId = null;

const CodeEditorPerViewAnswer = observer(({
    classes, question, onChangeType, audition, isCMS,
    questionsNumber, fetchQuestion, setSaving
}) => {
    const [testCases, setTestCases] = useState([]);
    const [codeChallengeQuestions, setCodeChallengeQuestions] = useState([]);
    const [activeLanguages, setActiveLanguages] = useState([]);
    const [loadingCases, setLoadingCases] = useState(false);
    const [openedCodeEditor, setOpenedCodeEditor] = useState(false);
    const [loading, setLoading] = useState(false);

    const autoCases = testCases.filter(({ type }) => (type === 'autograded'));

    const { id, answerType } = question;
    const autoGrade = answerType === CODE_TEST_AUTOGRADED;

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setLoadingCases(true);
        fetchTestCases();
        fetchCodeChallengeQuestions();
    }, [id]);

    const fetchCodeChallengeQuestions = () => {
        setLoading(true);
        getCodeChallengeQuestions(id, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setLoading(false);
                    setCodeChallengeQuestions(data);
                    setActiveLanguages(data.map(({ language }) => language));
                }
            });
    };

    const clearTestCases = () => {
        const newCases = testCases.slice();
        newCases.map((elem) => {
            delete elem.completed;
            delete elem.pass;
            delete elem.stderr;
            delete elem.stdout;
            return null;
        });
        setTestCases(newCases);
    };


    const openCodeEditor = (e) => {
        e.stopPropagation();
        Zendesk.hide();
        setOpenedCodeEditor(true);
    };

    const handleCodeEditor = () => {
        setOpenedCodeEditor(!openedCodeEditor);
    };

    const fetchTestCases = () => {
        getTestCases(id, isCMS)
            .then(({ data }) => {
                const cases = filterCases(data);
                setLoadingCases(false);
                setTestCases(cases);
            })
            .catch((error) => {
                setLoadingCases(false);
                console.log(error);
            });
    };

    const filterCases = (data) => {
        const { autograded, example } = data.reduce((cases, item) => {
            cases[item.type].push(item);
            return cases;
        }, { autograded: [], example: [] });

        return [...example, ...autograded];
    };

    const deleteLanguage = (lang, i) => {
        const codeChallengeQuestionIndex = codeChallengeQuestions.findIndex(({ language }) => language === lang);
        if (codeChallengeQuestionIndex !== -1) {
            const codeChallengeQuestion = codeChallengeQuestions[codeChallengeQuestionIndex];
            const newActiveLanguages = activeLanguages.slice();
            newActiveLanguages.splice(i, 1);
            setActiveLanguages(newActiveLanguages);
            setSaving(true);
            deleteCodeChallengeQuestion(codeChallengeQuestion.id, isCMS)
                .then(() => {
                    setCodeChallengeQuestions((prevCodeChallengeQuestions) => {
                        const newCodeChallengeQuestions = clone(prevCodeChallengeQuestions);
                        newCodeChallengeQuestions.splice(codeChallengeQuestionIndex, 1);
                        return newCodeChallengeQuestions;
                    });
                    fetchQuestion();
                    setSaving(false);
                })
                .catch(() => {
                    setSaving(false);
                });
        }
    };

    const deleteTestCase = ({ id: testCaseId, name }, questionId) => {
        deleteCase(testCaseId, isCMS)
            .then(() => {
                flashMessage(`${name} has been deleted`, 'done');
                fetchTestCases(questionId);
                fetchQuestion();
            });
    };

    const addLanguage = (language) => {
        const newActiveLanguages = activeLanguages.slice();
        newActiveLanguages.push(language);
        setActiveLanguages(newActiveLanguages);
        setSaving(true);
        addCodeChallengeQuestion(id, { language }, isCMS)
            .then(({ success, data }) => {
                if (success && data) {
                    setCodeChallengeQuestions(prevCodeChallengeQuestions => ([...prevCodeChallengeQuestions, data]));
                    fetchQuestion();
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const toggleLanguage = (language) => {
        const index = activeLanguages.indexOf(language);
        if (index !== -1) {
            deleteLanguage(language, index);
        } else {
            addLanguage(language);
        }
    };

    const editTestCase = (caseId, caseData) => {
        caseData.score = (caseData.type === 'autograded' && caseData.score) ? +caseData.score : null;
        const sentData = { ...caseData };
        editCase(caseId, sentData, isCMS)
            .then(({ success, data }) => {
                if (success) {
                    flashMessage(`${data.name} has been edited`);
                    fetchTestCases(data.questionId);
                    fetchQuestion();
                }
            });
    };

    const onChangeCodeEditorQuestion = (questionId, data) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            editCodeChallengeQuestion(questionId, data, isCMS);
        }, 300);
    };

    const setTestData = (caseData) => {
        createTestCase(question.id, caseData, isCMS)
            .then(({ success, data: { questionId, name } }) => {
                if (success) {
                    flashMessage(`${name} has been added`);
                    fetchTestCases(questionId);
                    fetchQuestion();
                }
            });
    };

    const dataForEvents = {
        ttUUID: audition ? audition.uuid : null,
        ttName: audition ? audition.name : null,
        questionNumber: question.sort
    };

    if (loading) {
        return <CircularProgress size={23} />;
    }

    return (
        <div>
            <SwitchAutoGrade {...{ question, onChangeType }} />
            <ListLanguages {...{ toggleLanguage, activeLanguages }} />
            {
                activeLanguages.length > 0 && (
                    <Fragment>
                        <Typography variant="h6">Edit your code below</Typography>
                        <p>Candidates will see the code you enter below and will be able to edit, add & run this code.</p>
                        <CodeEditorPreview
                            question={question}
                            testCases={testCases}
                            activeLanguages={activeLanguages}
                            loadingCases={loadingCases}
                            openCodeEditor={openCodeEditor}
                            codeChallengeQuestions={codeChallengeQuestions}
                        />
                        {
                            !loadingCases && autoGrade && autoCases.length < 1 && (
                                <div className={classes.error}>
                                    <img className="u-mrg--rx1" src={InfoError} alt="" />
                                    This question won't be visible to candidates until you've added test cases or turn autograding off.
                                </div>
                            )
                        }
                    </Fragment>
                )
            }
            {
                openedCodeEditor && codeChallengeQuestions.length && (
                    <CodeEditorDialog
                        {...{
                            isCMS,
                            setTestCases,
                            onClose: handleCodeEditor,
                            questionsNumber,
                            question,
                            flashMessage,
                            deleteTestCase,
                            editTestCase,
                            onChangeCodeEditorQuestion,
                            setTestData,
                            clearTestCases,
                            cases: testCases,
                            codeChallengeQuestions,
                            languages: activeLanguages,
                            setCodeChallengeQuestions,
                            dataForEvents
                        }}
                    />
                )
            }
        </div>
    );
});

export default withStyles(styles)(CodeEditorPerViewAnswer);
