import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';

import { checkHasCompanyFeature } from 'helper/commonFunctions';
import {
    CODE_CHALLENGE, TEXT, AUDIO,
    VIDEO, ANY_FILE, NO_ANSWER,
    CODE_TEST, CODE_TEST_AUTOGRADED,
    SINGLE_CHOICE_AUTO_GRADED,
    MULTIPLE_CHOICE_AUTOGRADED,
    MOBILE_ONLY_QUESTIONS
} from 'helper/constants';

import SelectOutlined from 'libraries/Select';
import simulationIcon from 'components/icons/AnswerTypesIcons/cs_simulation';

import { getQuestionTypeInfo } from 'helper/questionSettingsFunctions';
import NoMobileIcon from 'img/icons/noMobile.svg';
import { appCtx } from '../../../../../appStore';

const styles = theme => ({
    text: {
        color: theme.palette.grey[600]
    }
});

const helperText = {
    [TEXT]: 'Your candidate will be able to enter a text answer.',
    [ANY_FILE]: 'Your candidate will see an option to upload any type of file.',
    [NO_ANSWER]: 'Candidates will not be able to input an answer for this question.',
    [VIDEO]: 'Your candidate will see an option to record or upload a video.',
    [AUDIO]: 'Your candidate will see an option to record audio.'
};


const iconStyles = {
    width: '24px',
    height: '24px',
    marginRight: '15px'
};


const content = (Icon, text) => (
    <div className="u-dsp--f u-ai--center">
        { Icon && <Icon style={iconStyles} /> }
        <span>{text}</span>
    </div>
);

const AnswerTypesDropDown = observer(({ value, onChange, questionTypes, classes }) => {
    const { company } = useContext(appCtx);
    const handleMenuItem = (e) => {
        let newValue = +e.target.value;
        if (checkHasCompanyFeature(company, CODE_CHALLENGE) && newValue === CODE_TEST) newValue = CODE_TEST_AUTOGRADED;
        onChange(newValue);
    };
    const autoGrade = value === CODE_TEST_AUTOGRADED;
    const autoGradeChoice = value === MULTIPLE_CHOICE_AUTOGRADED;

    const menuOptions = (
        questionTypes
            .map((item) => {
                const { type: ITEM } = item;
                if (ITEM.types) {
                    const currentSimulationIndex = ITEM.types.indexOf(value);
                    return {
                        ...item,
                        type: ITEM.types[currentSimulationIndex === -1 ? 0 : currentSimulationIndex],
                        label: content(simulationIcon, ITEM.label)
                    };
                }
                return ({ ...item, label: getQuestionTypeInfo(ITEM, content) });
            })
            .filter(({ type: ITEM }) => !((ITEM === CODE_TEST && autoGrade)
                || (ITEM === CODE_TEST_AUTOGRADED && !autoGrade)
                || (!checkHasCompanyFeature(company, CODE_CHALLENGE) && ITEM === CODE_TEST_AUTOGRADED)))
            .filter(({ type: ITEM }) => !((ITEM === MULTIPLE_CHOICE_AUTOGRADED && !autoGradeChoice)
                || (+ITEM === SINGLE_CHOICE_AUTO_GRADED && autoGradeChoice)))
            .map(({ type, disabled, label }) => ({
                value: type,
                disabled,
                label
            }))
    );

    const menuOptionsWithLabels = menuOptions.map(option => (MOBILE_ONLY_QUESTIONS.includes(option.value) ? (
        { ...option,
            label: <div className="u-dsp--f">
                {option.label}
                <img className="u-mrg--lx2" src={NoMobileIcon} alt="!" />
            </div>
        }
    ) : option));

    const renderSelectedValue = () => {
        const optionLabel = menuOptions.find(option => option.value === value)?.label;
        if (MOBILE_ONLY_QUESTIONS.includes(value)) {
            return (
                <div className="u-dsp--f u-jc--sb">
                    <span>{optionLabel}</span>
                    <div className="u-mrg--rx4">
                        <img className="u-mrg--rx2" src={NoMobileIcon} alt="!" />
                        <span className={classes.text}>Mobile Incompatible</span>
                    </div>
                </div>
            );
        }
        return <span>{optionLabel}</span>;
    };

    return (
        <SelectOutlined
            className="u-mrg--bx3"
            variant="outlined"
            value={value}
            onChange={handleMenuItem}
            label="Question type"
            options={menuOptionsWithLabels}
            helperText={helperText[value]}
            renderValue={renderSelectedValue}
        />
    );
});

export default withStyles(styles)(AnswerTypesDropDown);
