import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BookmarkEmptyIcon from 'components/icons/BookmarkEmptyIcon';

import CloseIcon from 'img/close.svg';

import { observer } from 'mobx-react-lite';
import QuestionListWrapper from '../QuestionsWrapper';

import styles from './styles';

const AddQuestionDrawer = observer(({
    classes, toggleDrawer, open, selectedSet, selectedSkill, onClose
}) => {
    useEffect(() => {
        if (!open) {
            if (onClose) onClose();
        }
    }, [open]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                style: { position: 'absolute' }
            }}
            variant="persistent"
        >
            <div className={classes.headerWrapper}>
                <IconButton className={classes.closeIcon} onClick={toggleDrawer} size="large">
                    <img src={CloseIcon} alt="close" />
                </IconButton>
                <Typography variant="h4">
                    Add question
                </Typography>
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.tab}>
                    <BookmarkEmptyIcon />
                    <Typography variant="h6">
                            Saved Questions
                    </Typography>
                </div>
                <QuestionListWrapper
                    selectedSet={selectedSet}
                    selectedSkill={selectedSkill}
                    wrapperClass={classes.questionsWrapper}
                    hideDuplicate
                    hideEdit
                    hideStatus
                />
            </div>
        </Drawer>
    );
});

export default withStyles(styles)(AddQuestionDrawer);
