import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Layout from 'components/layout';
import { Typography, Switch } from '@mui/material';
import PageWrapper from 'components/layout/PageWrapper';
import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import Loader from 'components/loaders/Loader';
import SettingsEvents from 'events/SettingsEvents';
import Zendesk from 'helper/zendeskFunctions';
import Tooltip from 'libraries/Tooltip';

import SettingsHeader from '../settings_header';
import { setCompanyChatbot, getCompanyChatbot } from '../../../requests/SettingsRequests';


import styles from './styles';


const ChatbotSettings = ({ company, classes }) => {
    const [enabled, setEnabled] = useState(false);
    const [widgetUUID, setWidgetUUID] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const hasWidgetFeatureForCompany = company?.companySettings?.chatbotWidget;

    useEffect(() => {
        if (hasWidgetFeatureForCompany) {
            loadChatbotSettings();
        }
    }, []);

    const loadChatbotSettings = async () => {
        try {
            setLoading(true);
            const { data: { enabled: enabledNew, uuid } } = await getCompanyChatbot();
            setEnabled(enabledNew);
            setWidgetUUID(uuid);
            setLoading(false);
        } catch (e) {
            setEnabled(false);
            setLoading(false);
        }
    };

    const onChange = () => {
        const oldValue = enabled;
        const newValue = !oldValue;
        setEnabled(newValue);
        setCompanyChatbot({ enabled: newValue })
            .then(() => {
                SettingsEvents.COMPANY_CHATBOT_ENABLED({ enabled: newValue });
            })
            .catch(() => {
                setEnabled(oldValue);
            });
    };

    const openZendesk = () => {
        Zendesk.open(true);
    };

    const returnTooltipNoScreening = children => (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        maxWidth: '230px',
                        textAlign: 'center'
                    }
                }
            }}
            label={!hasWidgetFeatureForCompany ? <>The Chatbot Widget is not available on the current plan. To enable you will need to <span role="presentation" className={classes.span} onClick={openZendesk}>talk to sales</span>.</> : ''}
        >
            <span>{children}</span>
        </Tooltip>
    );

    if (!company.companySettings) return null;

    return (
        <Layout footer={null}>
            <SettingsHeader />
            <PageWrapper
                headerLabel="Chatbot Settings"
                className="u-z--1 u-pos--relative"
            >
                <ContentWrapper>

                    <Loader
                        variant="indeterminate"
                        show={isLoading}
                        thickness={3}
                        style={{ backdropFilter: 'blur(2px)' }}
                    />
                    <div className="u-dsp--distribute">
                        <div>
                            <Typography variant="h5" sx={{ mb: '7px' }}>Chatbot Widget</Typography>
                            Embed our customizable screening widget on your website, allowing candidates to begin assessments and be directed to relevant roles effortlessly.
                        </div>
                        {
                            returnTooltipNoScreening(
                                <Switch
                                    onChange={onChange}
                                    checked={enabled}
                                    disabled={!hasWidgetFeatureForCompany}
                                />
                            )
                        }
                    </div>
                    {
                        enabled && (
                            <div>
                                <Typography variant="h5" sx={{ mb: '7px', mt: '30px' }}>How to Embed the Chatbot Widget:</Typography>
                                <div>
                                1. Copy the Code: Copy the provided JavaScript code below. <br />
                                2. Paste the Code: Go to the relevant page of your website or career page where you want the widget to appear. <br />
                                3. Add the Code: Paste the code within the HTML section of the page. Make sure it's placed where you want the widget to display. <br />
                                4. Save and Publish: After adding the code, save your changes and publish the page to make the widget live.
                                </div>
                                <div className={classes.codeWrapper}>
                                    {`<script src="${process.env.REACT_APP_SCREENWIDGET_LINK || 'REACT_APP_SCREENWIDGET_LINK'}"></script>`}<br />
                                    {'<script>'}<br />
                                    {`     window.initializeWidget('${widgetUUID}', { right: '20px', bottom: '20px' });`}<br />
                                    {'</script>'}
                                </div>
                            </div>
                        )
                    }
                </ContentWrapper>
            </PageWrapper>
        </Layout>
    );
};

export default withStyles(styles)(ChatbotSettings);
