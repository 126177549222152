export default () => ({
    wrapper: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        marginTop: 40,
        fontSize: 32,
        fontWeight: 700,
        color: '#292A2D'
    },
    subTitle: {
        marginTop: 10,
        fontSize: 16,
        fontWeight: 600,
        color: '#6B6B70'
    },
    button: {
        color: '#1247F6!important',
        marginTop: `-3px!important`,
        fontWeight: 700
    }
});
