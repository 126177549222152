import React, { useContext } from 'react';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { AI_AUDITION_GENERATION, REQUEST_CUSTOM_ASSESSMENT } from 'helper/constants';
import { appCtx } from '../../appStore';
import CreateNewAssessmentDropdown from './CreateNewAssessmentDropdown';
import CreateNewAssessmentButton from './CreateNewAssessmentButton';
import DisabledButton from './components/DisabledButton';
import styles from './styles';

const CreateNewAssessment = observer((props) => {
    const { company, sentTTRequestCount } = useContext(appCtx);
    const { companySettings } = company;
    if (!companySettings) return null;

    const { activeAuditionsPerCompany: limitCount, activeAuditionsPerCompanyUsed } = companySettings;
    const { classes, className, buttonLabel } = props;
    const { button, buttonDisabled, link } = classes;

    const companyCanCreateAssessment = checkHasCompanyFeature(company, 'CREATE_ASSESSMENT');
    const canCreateCustomRequests = checkHasCompanyFeature(company, REQUEST_CUSTOM_ASSESSMENT);
    const companyHasAiGeneration = checkHasCompanyFeature(company, AI_AUDITION_GENERATION);

    const fullLimit = limitCount !== -1 && limitCount <= activeAuditionsPerCompanyUsed;
    const disabled = !companyCanCreateAssessment || fullLimit;

    if (!companyCanCreateAssessment && !companyHasAiGeneration) {
        return (
            <DisabledButton
                buttonLabel={buttonLabel}
                className={clsx(button, className, buttonDisabled)}
                linkClassName={link}
            />
        );
    }
    const allRequestIsUsed = sentTTRequestCount?.count !== -1 && sentTTRequestCount?.count === 0;

    if (canCreateCustomRequests || companyHasAiGeneration) {
        return (
            <CreateNewAssessmentDropdown {...props} fullLimit={fullLimit} allRequestsIsUsed={allRequestIsUsed} />
        );
    }

    return (
        <CreateNewAssessmentButton {...props} disabled={disabled} />
    );
});

export default withStyles(styles)(CreateNewAssessment);
