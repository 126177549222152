import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { loadCompany, loadLoggedUser } from 'requests/APIrequests';
import { getCustomRequestsCount } from 'requests/CustomAssessmentRequests';
import AuthEvents from '../events/AuthEvents';

const BUILDER_TYPE = {
    DEFAULT: 'default',
    CUSTOM_REQUEST: 'custom',
    AI: 'ai'
};

class AppStore {
    @observable snackbarOpen = false;

    @observable snackbarDuration = 5000;

    @observable snackbarMessage = '';

    @observable snackbarAction = '';

    @observable loggedUser = {};

    @observable company = {};

    @observable idibuJobBoards = {};

    @observable opportunitySlug = '';

    @observable isLoadingCompany = true;

    @observable isLoadingUser = true;

    @observable ttBuilderOpened = false;

    @observable ttRequestOpened = false;

    @observable ttAiGeneratorOpened = false;

    @observable ttBuilderSettings = {};

    @observable sentTTRequestCount = null;

    snackTimerId = null;

    @action loadCompany = () => {
        this.isLoadingCompany = true;
        loadCompany()
            .then(({ success, data }) => {
                if (success) {
                    this.company = data;
                    this.loadRequestsCount();
                }
                this.isLoadingCompany = false;
            });
    };

    @action setCompany = (value) => {
        this.company = value;
    };

    @action loadUser = () => {
        this.isLoadingUser = true;
        loadLoggedUser()
            .then((data) => {
                this.loggedUser = data;
                this.isLoadingUser = false;
                if (data.uuid && data.userSegmentTraits) {
                    AuthEvents.IDENTIFY(data.uuid, data.userSegmentTraits);
                }
            });
    };

    @action closeFlashMessage = () => {
        this.snackbarMessage = '';
        this.snackbarAction = '';
        this.snackbarOpen = false;
    };

    @action flashMessage = (message, actionType = '', duration = 5000) => {
        clearTimeout(this.snackTimerId);
        this.closeFlashMessage();

        this.snackbarMessage = message;
        this.snackbarAction = actionType;
        this.snackbarOpen = true;
        this.snackbarDuration = duration;

        this.snackTimerId = setTimeout(this.closeFlashMessage, duration);
    };

    @action updateCompany = (company) => {
        this.company = company;
    };

    @action updateLoggedUser = (user) => {
        this.loggedUser = user;
    };

    @action updateIdibuAvailableJobBoards = (data) => {
        this.idibuJobBoards = data;
    };

    @action openTTBuilderDialog = (ttBuilderSettings = {}) => {
        this.openTTDialog(BUILDER_TYPE.DEFAULT, ttBuilderSettings);
    };

    @action openTTRequestDialog = () => {
        this.openTTDialog(BUILDER_TYPE.CUSTOM_REQUEST);
    }

    @action openTTAiGeneratorDialog = (ttBuilderSettings = {}) => {
        this.openTTDialog(BUILDER_TYPE.AI, ttBuilderSettings);
    }

    @action openTTDialog = (type, settings = {}) => {
        this.ttAiGeneratorOpened = type === BUILDER_TYPE.AI;
        this.ttBuilderOpened = type === BUILDER_TYPE.DEFAULT;
        this.ttRequestOpened = type === BUILDER_TYPE.CUSTOM_REQUEST;
        this.ttBuilderSettings = settings;
    };

    @action closeTTBuilderDialog = () => {
        this.ttBuilderOpened = false;
        this.ttBuilderSettings = {};
    };

    @action closeTTRequestDialog = () => {
        this.ttRequestOpened = false;
    }

    @action closeTTAiGeneratorDialog = () => {
        this.ttAiGeneratorOpened = false;
    }

    @action decreaseSmsLeftCount = () => {
        if (this.company.countSmsLeft === 0) return;

        this.company.countSmsLeft = this.company.countSmsLeft - 1;
    };

    @action setCountSmsUsed = (newCount) => {
        this.company.countSmsUsed = newCount;
    };

    @action setCountSmsLeft = (newCount) => {
        this.company.countSmsLeft = newCount;
    };

    @action setTeamMembersUsed = (newCount) => {
        this.company.companySettings.teamMembersUsed = newCount;
    }

    @action updateCompanyProperty = (property, value) => {
        this.company[property] = value;
    }

    @action loadRequestsCount = () => {
        if (this.company && this.company.freeTrialExpired) return;

        getCustomRequestsCount().then(({ success, data }) => {
            if (success) this.sentTTRequestCount = data;
        });
    };

    @computed get activeAuditionsSlotsIsAvailable() {
        const { companySettings } = this.company;
        const { activeAuditionsPerCompany, activeAuditionsPerCompanyUsed } = companySettings;
        return (activeAuditionsPerCompany === -1) || (activeAuditionsPerCompany > activeAuditionsPerCompanyUsed);
    }
}

export const appStore = new AppStore();
export const appCtx = createContext(appStore);
