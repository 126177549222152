import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import SetItemHeader from '../../SetItemHeader';

const styles = theme => ({
    wrapper: {
        padding: 24,
        borderRadius: 8,
        marginBottom: 32,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    wrapperFocused: {
        backgroundColor: theme.palette.grey[100]
    },
    wrapperSelected: {
        border: `2px solid ${theme.palette.primary.main}`
    },
    description: {
        fontSize: 16,
        color: '#000',
        fontWeight: 600,
        lineHeight: '29px'
    }
});

const SetItem = observer(({
    classes, onFocus, set, setFocusedSet, focusedSet, ...other
}) => {
    const focus = () => {
        setFocusedSet(set);
        if (onFocus) onFocus(set);
    };

    const isFocused = focusedSet?.uuid === set?.uuid;

    return (
        <div
            onClick={focus}
            role="presentation"
            className={clsx(
                classes.wrapper,
                isFocused && classes.wrapperFocused
            )}
        >
            {
                set && (
                    <>
                        <SetItemHeader
                            set={set}
                            showEdit={isFocused}
                            showDelete={isFocused}
                            showSkillRow
                            {...other}
                        />
                        <div className={classes.description}>
                            {set.description}
                        </div>
                    </>
                )
            }
        </div>
    );
});

export default withStyles(styles)(SetItem);
