import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import CompanyLogo from 'components/logos/company_logo';
import { assessmentEditCtx } from 'pages/assessment/edit_assessment/edit_questions/store';
import RetinaImage from 'react-retina-image';
import EditBlueIcon from 'img/edit_blue.svg';
import { Button } from '@mui/material';
import CloseIcon from './closeIcon';
import { convertMinutes } from '../../../common/commonFunc';

import styles from './styles';

const PreviewHeader = ({
    handleClosePreview, classes, history, showActionTooltips,
    interview, company, brand, loading, currentQuestionIndex, headerActions
}) => {
    const { setActiveQuestionIndex } = useContext(assessmentEditCtx);

    const logo = brand?.logoUrl[0]
        ? (
            <RetinaImage
                alt="logo"
                src={brand?.logoUrl}
                className={classes.logo}
            />
        ) : (
            <CompanyLogo
                sizeHeight={40}
                company={company}
                className={classes.companyLogo}
            />
        );

    const renderedButton = (
        <Tooltip
            label={showActionTooltips && 'Exit candidate preview'}
            placement="left"
        >
            <IconButton onClick={handleClosePreview} size="large">
                <CloseIcon className={classes.closeIcon} />
            </IconButton>
        </Tooltip>
    );

    if (!interview) {
        return (
            <div className={classes.headerPanel}>
                <div
                    className={classes.logoWrapper}
                >
                    {!loading && logo}
                </div>
                <div>
                    { renderedButton }
                </div>
            </div>
        );
    }
    const { timerCount, timerEnabled, slug, generatedViaAi } = interview;
    const timeLabel = timerEnabled ? `${convertMinutes(timerCount)} remaining` : '';

    const goToInvite = () => {
        history.push(`/script/invite/${slug}/invitation`);
        handleClosePreview();
    };

    const goToEdit = () => {
        history.push(`/script/edit/${slug}`, { editMode: true });
        setActiveQuestionIndex(currentQuestionIndex);
        handleClosePreview();
    };

    return (
        <div className={classes.headerPanel}>
            <div
                className={classes.logoWrapper}
            >
                {!loading && logo}
                <span className={classes.timeLimit}>
                    {timeLabel}
                </span>
            </div>
            <div className="u-dsp--f u-ai--center">
                {headerActions}
                {generatedViaAi && (
                    <>
                        <Button className={classes.buttonEdit} onClick={goToEdit}>
                            <img src={EditBlueIcon} alt="" />
                            <span>Edit</span>
                        </Button>
                        <Button variant="contained" color="primary" className={classes.buttonReview} onClick={goToInvite}>
                            Review and Invite
                        </Button>
                    </>
                )}
                { renderedButton }
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(PreviewHeader));
