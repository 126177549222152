import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import RejectDialog from 'components/dialogs/RejectDialog';
import UnrejectDialog from 'components/dialogs/UnrejectDialog';
import ShortListDialog from 'components/dialogs/ShortListDialog';
import { SCORE_TYPE_AI } from 'helper/constants';
import { shortlistCandidate } from 'requests/CandidatesRequests';
import { appCtx } from 'components/appStore';
import { checkHasUserPermission } from 'helper/commonFunctions';
import AssessmentEvents from 'events/AssessmentEvents';
import HireCandidateDialog from 'components/dialogs/HireCandidateDialog';
import RemoveFromHiredDialog from 'components/dialogs/RemoveFromHiredDialog';
import ArchiveCandidateDialog from 'components/dialogs/ArchiveCandidateDialog';
import HireBanner from './HireBanner';

import useStyles from './styles';

const RejectShortlistActions = observer(({
    user, setUser, getUserInfo, updateUserFields,
    showHireBanner, setShowHireBanner, audition, opportunitySlug,
    increaseShortlistCandidatesCount, propPublic, context,
    onHireChanges, loadFullStats, isIntegration, roleType
}) => {
    const classes = useStyles();
    const [shortlistedState, setShortlistedState] = useState(false);
    const [archivedState, setArchivedState] = useState(false);
    const [rejectedState, setRejectedState] = useState(false);
    const [rejectDialogOpened, setRejectDialogOpened] = useState(false);
    const [unrejectDialogOpened, setUnrejectDialogOpened] = useState(false);
    const [shortlistDialogOpened, setShortlistDialogOpen] = useState(false);
    const [hireDialogOpened, setHireDialogOpened] = useState(false);
    const [removeHireDialogOpened, setRemoveHireDialogOpened] = useState(false);
    const [archiveDialogOpened, setArchiveDialogOpened] = useState(false);

    const { flashMessage, company, loggedUser } = useContext(appCtx);

    const {
        shortlisted, rejected, score, scoreType,
        candidateUuid, userAuditionId, rank, shortlistedAt,
        shortlistedBy, columnUserId, candidateStatus,
        completed, canBeUnRejected, archived
    } = user;

    const { permissions, uuid: ttId, name: ttName, slug, sendRejectEmail, active, removed } = audition;

    const reject = checkHasUserPermission(company, permissions, 'reject');
    const edit = checkHasUserPermission(company, permissions, 'edit');
    const hire = checkHasUserPermission(company, permissions, 'hire');

    const hired = candidateStatus === 'Hired';

    useEffect(() => {
        setRejectedState(rejected);
    }, [rejected]);

    useEffect(() => {
        setShortlistedState(shortlisted);
    }, [shortlisted]);

    useEffect(() => {
        setArchivedState(archived);
    }, [archived]);

    const dataForEvents = {
        candidateId: candidateUuid,
        context,
        ttId,
        ttName,
        score: score ? score.score : undefined,
        scoreIsAI: score ? score.type === SCORE_TYPE_AI : undefined,
        scoreType,
        rank,
        candidateHired: hired
    };

    const handleRejectDialog = () => setRejectDialogOpened(!rejectDialogOpened);
    const handleUnrejectDialog = () => setUnrejectDialogOpened(!unrejectDialogOpened);
    const handleShortListDialog = () => setShortlistDialogOpen(!shortlistDialogOpened);
    const handleHireDialog = () => setHireDialogOpened(!hireDialogOpened);
    const handleRemoveHireDialog = () => setRemoveHireDialogOpened(!removeHireDialogOpened);
    const handleArchiveDialog = () => setArchiveDialogOpened(!archiveDialogOpened);

    const isIntegrationUser = roleType === 'integration';

    const onShortListedClick = async (e) => {
        e.stopPropagation();
        const newValue = !shortlistedState;

        if (!newValue) {
            handleShortListDialog();
        } else {
            try {
                const { data: shortlistedCandidate } = await shortlistCandidate(userAuditionId, newValue);
                const { shortlistedBy: candidateShortlistedBy, shortlistedAt: candidateShortlistedAt } = shortlistedCandidate;
                dataForEvents.shortlistedBy = candidateShortlistedBy && candidateShortlistedBy.id;
                dataForEvents.shortlistedDate = candidateShortlistedAt;
                AssessmentEvents.CANDIDATE_SHORTLISTED(dataForEvents);
                setShortlistedState(newValue);
                if (increaseShortlistCandidatesCount) increaseShortlistCandidatesCount(true);
                setUser({ ...user, shortlistedBy: candidateShortlistedBy, shortlistedAt: candidateShortlistedAt });
                flashMessage('Candidate Shortlisted', 'done');
            } catch (err) {
                if (err.response && err.response.data && err.response.data.errors) {
                    flashMessage(err.response.data.errors, 'error');
                }
            }
        }
    };

    const removeFromShortlist = async () => {
        handleShortListDialog();
        setShortlistedState(false);
        await shortlistCandidate(userAuditionId, false);
        increaseShortlistCandidatesCount(false);
        flashMessage('Candidate removed from shortlist', 'done');
        dataForEvents.shortlistedBy = shortlistedBy && shortlistedBy.id;
        dataForEvents.shortlistedDate = shortlistedAt;
        dataForEvents.shortlistedRemovedBy = loggedUser.id;
        dataForEvents.shortlistedRemovedDate = new Date();
        AssessmentEvents.CANDIDATE_SHORTLISTED_REMOVED(dataForEvents);
    };

    const onReject = () => {
        setRejectedState(!rejectedState);
        getUserInfo();
    };

    const handleShowBanner = () => {
        setShowHireBanner(!showHireBanner);
    };

    const onHireCallback = () => {
        getUserInfo();
        handleShowBanner();
        if (onHireChanges) onHireChanges();
    };

    const onArchiveCallback = () => {
        setShortlistedState(false);
        getUserInfo();
        if (loadFullStats) loadFullStats();
    };

    const onRemoveHireCallback = () => {
        getUserInfo();
        if (onHireChanges) onHireChanges();
    };

    return (
        <>
            <HireBanner
                user={user}
                audition={audition}
                showBanner={showHireBanner && !isIntegration}
                handleShow={handleShowBanner}
                updateUserFields={updateUserFields}
            />
            <div className={classes.wrapper}>
                {
                    !isIntegration && (
                        <>
                            <Button
                                onClick={onShortListedClick}
                                className={clsx(classes.button, classes.shortlistButton, shortlistedState && classes.buttonActive)}
                                disabled={rejectedState || !completed || propPublic || !active || archived}
                            >
                                <span>{ !shortlistedState ? <span>Shortlist</span> : <span>Shortlisted</span>}</span>
                            </Button>
                            <Button
                                className={clsx(classes.button, classes.archiveButton, archived && classes.buttonActive)}
                                disabled={!edit || !completed || removed || propPublic}
                                onClick={handleArchiveDialog}
                            >
                                <span>{ archivedState ? <span>Archived</span> : <span>Archive</span>}</span>
                            </Button>
                        </>
                    )
                }
                {
                    !isIntegrationUser && (
                        <>
                            <Button
                                className={clsx(classes.button, classes.hiredButton, hired && classes.buttonActive)}
                                disabled={rejected || !edit || !completed || propPublic || !hire || (!active && !hired) || (archived && !hired)}
                                onClick={hired ? handleRemoveHireDialog : handleHireDialog}
                            >
                                <span>{ hired ? <span>Hired</span> : <span>Hire</span>}</span>
                            </Button>
                            <Button
                                onClick={rejectedState ? handleUnrejectDialog : handleRejectDialog}
                                className={clsx(classes.button, classes.rejectButton, rejectedState && classes.buttonActive)}
                                disabled={hired || (rejected && !canBeUnRejected) || !reject || propPublic || !active || archived}
                            >
                                <span>{ rejectedState ? <span>Rejected</span> : <span>Reject</span> }</span>
                            </Button>
                        </>
                    )
                }
                <RejectDialog
                    user={user}
                    notifyRejectEmail={sendRejectEmail}
                    dataForEvent={dataForEvents}
                    slug={opportunitySlug}
                    assessmentSlug={slug}
                    open={rejectDialogOpened}
                    callback={onReject}
                    handleClose={handleRejectDialog}
                />
                <UnrejectDialog
                    callback={onReject}
                    user={user}
                    dataForEvent={dataForEvents}
                    open={unrejectDialogOpened}
                    flashMessage={flashMessage}
                    handleClose={handleUnrejectDialog}
                />
                <ShortListDialog
                    open={shortlistDialogOpened}
                    handleDialog={handleShortListDialog}
                    removeFromShortlist={removeFromShortlist}
                />
                <HireCandidateDialog
                    open={hireDialogOpened}
                    onClose={handleHireDialog}
                    flashMessage={flashMessage}
                    slug={slug}
                    columnUserId={columnUserId}
                    callback={onHireCallback}
                    dataForEvent={dataForEvents}
                    isIntegration={isIntegration}
                />
                <RemoveFromHiredDialog
                    open={removeHireDialogOpened}
                    onClose={() => setRemoveHireDialogOpened(false)}
                    candidate={{ ...user, assessmentTitle: ttName, assessmentUuid: ttId }}
                    callback={onRemoveHireCallback}
                />
                <ArchiveCandidateDialog
                    open={archiveDialogOpened}
                    onClose={handleArchiveDialog}
                    callback={onArchiveCallback}
                    isArchive={!archivedState}
                    userAuditionIds={[userAuditionId]}
                    audition={audition}
                    dataForEvents={dataForEvents}
                />
            </div>
        </>
    );
});

export default RejectShortlistActions;
