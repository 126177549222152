import React, { useEffect, useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { checkPluralNew } from 'helper/commonFunctions';

import NotFoundContent from 'components/assessments_pages/NotFoundContent';
import Pagination from 'components/board/board_pagination';
import SearchFilterWrapper from './SearchFilterWrapper';
import SetsList from '../../../common/SetsList';
import TagsList from './TagsList';
import { setLibraryCtx } from '../../store';

const styles = {
    wrapper: {
        padding: '0 30px'
    },
    countLabel: {
        fontSize: 12,
        marginBottom: 21,
        marginTop: 20,
        height: 17,
        marginRight: 10
    }
};
const PAGE_STEP = 12;
const SetWrapper = observer(({
    classes, openDrawer
}) => {
    const {
        setsData, loadSets, setPage, setQuery, setFilterStatus, query, filterStatus, filterState,
        selectedSkill, loadingSets, selectedSetData, setSelectedSet, page, setFilterState
    } = useContext(setLibraryCtx);

    useEffect(() => {
        setPage(1);
        loadSets();
    }, [query, filterStatus, selectedSkill, filterState]);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setQuery(value);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        loadSets();
    };

    return (
        <div className={classes.wrapper}>
            <SearchFilterWrapper
                filterStatus={filterStatus}
                filterState={filterState}
                openDrawer={openDrawer}
                totalCount={setsData.total}
                handleChangeSearch={handleChangeSearch}
                handleChangeFilterStatus={setFilterStatus}
                handleChangeFilterState={setFilterState}
                searchValue={query}
            />
            <div className="u-dsp--f u-ai--center">
                <div className={classes.countLabel}>
                    {
                        !loadingSets && (
                            <>
                                <b>{setsData.total}</b> {checkPluralNew(setsData.total, 'Set')}
                            </>
                        )
                    }
                </div>
                <TagsList activeFilter={filterStatus} handleRemove={setFilterStatus} />
                <TagsList activeFilter={filterState} handleRemove={setFilterState} />
            </div>
            {
                !loadingSets && query && (!setsData.items || !setsData.items.length) && (
                    <NotFoundContent searchValue={query} />
                )
            }
            <SetsList
                sets={setsData?.items}
                loading={loadingSets}
                selectedSet={selectedSetData}
                setSelectedSet={set => setSelectedSet(set?.uuid)}
                onEdit={loadSets}
                onDelete={loadSets}
                onPublish={loadSets}
            />
            {
                setsData.items && setsData.items.length > 0 && (
                    <>
                        <Pagination
                            currentPage={page}
                            totalPages={Math.ceil(setsData?.total / PAGE_STEP)}
                            handleChangePage={handleChangePage}
                        />
                    </>
                )
            }
        </div>
    );
});

export default withStyles(styles)(SetWrapper);
