import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import { createScript, getRolesWithSkills } from 'requests/ScriptRequests';
import TTBuilderEvents from 'events/TTBuilderEvents';
import Dialog from 'libraries/Dialog';
import { appCtx } from 'components/appStore';

import FooterCustomTTBuilder from './components/FooterCustomTTBuilder';

import stylesJs from './styles';
import CustomFlow from './components/CustomFlow';

const TTBuilderDialog = ({ open, flashMessage, history, classes }) => {
    const [loading, setLoading] = useState(false);
    const [writingSkills, setWritingSkills] = useState([]);
    const [manualAddedSkills, setManualAddedSkills] = useState([]);

    const [customTitle, setCustomTitle] = useState('');
    const [eventFired, setEventFired] = useState(false);

    const { company, updateCompany, closeTTBuilderDialog } = useContext(appCtx);

    useEffect(() => {
        if (open && !eventFired) {
            TTBuilderEvents.INPUT_SCREEN_VIEW();
            setEventFired(true);
        }

        if (!open) {
            setDefaultValues();
            return;
        }
    }, [open]);

    const setDefaultValues = () => {
        setLoading(false);
        setCustomTitle('');
        setWritingSkills([]);
        setManualAddedSkills([]);
    };

    const submitDescription = async () => {
        const skills = [...writingSkills, ...manualAddedSkills];
        const roleName = customTitle;

        TTBuilderEvents.DESCRIPTION_SAVED({
            skills,
            numberSkills: skills.length,
            title: roleName
        });

        setLoading(true);

        localStorage.setItem('assessmentName', roleName);
        localStorage.setItem('assessmentSkills', JSON.stringify(skills));

        try {
            const { success, data } = await createScript({ name: customTitle, skills, roleName, categoryName: 'other' });
            closeTTBuilderDialog();
            if (success && data) {
                if (data.active) {
                    const { companySettings } = company;
                    const { activeAuditionsPerCompanyUsed } = companySettings;
                    updateCompany({
                        ...company,
                        companySettings: {
                            ...companySettings,
                            activeAuditionsPerCompanyUsed: activeAuditionsPerCompanyUsed + 1
                        }
                    });
                }
                history.push(`/script/edit/${data.slug}`);
            }
        } catch (e) {
            let errorLabel = '';
            if (e.response.data && e.response.data.errors) {
                Object.keys(e.response.data.errors).forEach((key) => {
                    const error = e.response.data.errors[key][0];
                    errorLabel += error && error.message ? error.message : '';
                });
            }

            TTBuilderEvents.DESCRIPTION_SAVED_FAILED({
                skills,
                numberSkills: skills.length,
                title: customTitle
            });
            flashMessage(errorLabel || 'Something went wrong', '', 10000);
            setLoading(false);
        }
    };

    const handleClose = () => {
        closeTTBuilderDialog();
    };

    const addedSkills = [...writingSkills, ...manualAddedSkills];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent="Create new assessment"
            actionComponent={
                <FooterCustomTTBuilder
                    loading={loading}
                    customTitle={customTitle}
                    addedSkills={addedSkills}
                    submitDescription={submitDescription}
                />
            }
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            <div className={classes.content}>
                <CustomFlow
                    customTitle={customTitle}
                    setCustomTitle={setCustomTitle}
                    writingSkills={writingSkills}
                    setWritingSkills={setWritingSkills}
                    manualAddedSkills={manualAddedSkills}
                    setManualAddedSkills={setManualAddedSkills}
                    addedSkills={addedSkills}
                />
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTBuilderDialog));
