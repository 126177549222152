import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';

import { observer } from 'mobx-react-lite';
import SearchField from 'components/inputs/SearchField';
import TableFilter from './TableFilter';

const styles = {
    searchField: {
        maxWidth: '100%',
        width: 'calc(100% - 105px)',
        borderRadius: 4,
        height: 40,
        margin: 0
    }
};

const STATUS_FILTER_OPTIONS = [
    { value: 'published', label: 'Published' },
    { value: 'unpublished', label: 'Unpublished' },
    { value: 'draft', label: 'Draft' }
];

const STATE_FILTER_OPTIONS = [
    { value: 'synchronized', label: 'Synchronized' },
    { value: 'unsynchronized', label: 'Unsynchronized' }
];

const SearchFilterWrapper = observer(({
    classes, filterStatus, handleChangeFilterStatus, handleChangeSearch, searchValue, handleChangeFilterState, filterState
}) => {
    const statusOptions = useMemo(() => STATUS_FILTER_OPTIONS.map(el => ({ ...el, checked: el.value === filterStatus })), [filterStatus]);
    const stateOptions = useMemo(() => STATE_FILTER_OPTIONS.map(el => ({ ...el, checked: el.value === filterState })), [filterState]);

    return (
        <div className="u-dsp--distribute">
            <SearchField
                placeholder="Search Set"
                className={classes.searchField}
                value={searchValue}
                onChange={handleChangeSearch}
            />
            <TableFilter
                filter={filterStatus}
                filterState={filterState}
                stateOptions={stateOptions}
                statusOptions={statusOptions}
                handleChangeFilterStatus={handleChangeFilterStatus}
                handleChangeFilterState={handleChangeFilterState}
            />
        </div>
    );
});

export default withStyles(styles)(SearchFilterWrapper);
