import { del, get, patch, post, put } from '../helper/API';


export function createScript(form) {
    return post('employer/auditions', form);
}

export function deleteInterviewScript(slug) {
    return del(`employer/auditions/${slug}/delete`);
}

export function loadAssessmentQuestions(scriptSlug, isScreening) {
    return get(`employer/auditions/${scriptSlug}/${isScreening ? 'screening-' : ''}questions`);
}

export function loadAssessmentGroups(scriptSlug) {
    return get(`employer/auditions/${scriptSlug}/question-groups`);
}

export function saveAssessmentGroups(scriptSlug, data) {
    return put(`employer/auditions/${scriptSlug}/question-groups`, data);
}

export function loadAssessmentSettings(scriptSlug) {
    return get(`employer/auditions/${scriptSlug}/settings`);
}

export function saveAssessmentSettings(scriptSlug, form) {
    return patch(`employer/auditions/${scriptSlug}/settings`, form);
}


export function addQuestion(scriptSlug, data, isScreening) {
    return post(`employer/auditions/${scriptSlug}/${isScreening ? 'screening-' : ''}questions`, data);
}

export function deleteQuestion(questionId, isScreening) {
    return del(`employer/${isScreening ? 'screening-' : ''}questions/${questionId}`);
}

export function loadPreviewInterview(interviewSlug) {
    return get(`employer/auditions/${interviewSlug}`);
}

export function loadPublicPreviewInterview(uuid) {
    return get(`employer/public-auditions/${uuid}`);
}

export function loadCompletedQuestions(interviewSlug) {
    return get(`employer/auditions/${interviewSlug}/completed-questions`);
}

export function loadCompletedScreeningQuestions(interviewSlug) {
    return get(`employer/auditions/${interviewSlug}/completed-screening-questions`);
}

export function loadPublicCompletedQuestions(uuid) {
    return get(`employer/public-auditions/${uuid}/preview`);
}

export function uploadAuditionFile(questionId, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/file`, data);
}

export function deleteAuditionFile(questionId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/delete-file`);
}

export function deleteZiggeoFile(questionId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/delete-ziggeo`);
}

export function createGoogleDriveDocument(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/google/create`, data);
}

export function uploadGoogleDriveDocument(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/google/upload`, data);
}

export function uploadMicrosoftExcel(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/microsoft-excel/upload`, data);
}

export function duplicateInterview(slug) {
    return post(`employer/auditions/${slug}/copy-private`);
}

export function createTestCase(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/test-cases`, data);
}

export function editCase(caseId, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/test-cases/${caseId}`, data);
}

export function deleteCase(caseId, isCMS) {
    return del(`employer/${isCMS ? 'company-' : ''}questions/test-cases/${caseId}`);
}

export function runTestCases(id, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${id}/run-code`, data);
}

export function generateCodeForCodeChallenge(questionId, data, isCMS) {
    return post(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/generate-code-snippet`, data);
}

export function getCodeGenerationSnippetDraft(questionId, isCMS) {
    return get(`employer/${isCMS ? 'company-' : ''}questions/${questionId}/code-snippet-draft`);
}

export function loadPublicInterviews(
    query,
    page,
    config = {},
    filterData = {}
) {
    return get('employer/publiclibrary/auditions-new', {
        query,
        page,
        ...filterData
    }, config);
}

export function loadOnePublicInterview(uuid) {
    return get(`employer/public-auditions/${uuid}`);
}

export function loadPublicLibrarySearchResults(query) {
    return get('employer/publiclibrary/pre-emptive-search', { query });
}

export function loadPrivateInterviews(query, page, filter, maxPerPage, extra, folderId, config = {}) {
    return get('employer/privatelibrary/auditions', {
        query,
        page,
        filter,
        maxPerPage,
        extra,
        folderId
    }, config);
}

export function loadAssessmentFilters() {
    return get('employer/privatelibrary/auditions/filters');
}

export function getAutoRejectSettings(slug) {
    return get(`employer/auditions/${slug}/auto-reject`);
}

export function setAutoRejectSettings(slug, data) {
    return post(`employer/auditions/${slug}/auto-reject`, data);
}

export function getAuditionsForProgress(slug) {
    return get(`employer/auditions/${slug}/auto-progress/list`);
}

export function getAutoProgressSettings(slug) {
    return get(`employer/auditions/${slug}/link`);
}

export function setAutoProgressSettings(slug, linkSlug, data) {
    return post(`employer/auditions/${slug}/auto-progress/link/${linkSlug}`, data);
}

export function deleteAutoProgress(slug) {
    return del(`employer/auditions/${slug}/auto-progress/unlink`);
}

export function loadAudition(slug) {
    return get(`employer/auditions/${slug}`);
}

export function getPopularAssessments() {
    return get('employer/popular/auditions');
}

export function getNotInvitedTeamMembersByAssessment(slug) {
    return get(`employer/opportunities/${slug}/not-invited`);
}

export function changeTeamMemberRoleByAssessment(slug, uuid, type) {
    return patch(`employer/opportunities/${slug}/employers/${uuid}/permissions/change-role`, { type });
}

export function removeTeamMemberRoleFromAssessment(slug, uuid) {
    return del(`employer/opportunities/${slug}/employers/${uuid}/permissions`);
}

export function getAssessmentCategories() {
    return get('employer/auditions/categories');
}

export function getPopularAssessmentCategories() {
    return get('employer/auditions/categories/popular');
}

export function addAssessmentFromPublicLibrary(uuid, activate) {
    return post(`employer/public-auditions/${uuid}/copy`, { activate });
}

export function bulkUploadAssessmentsJson(data) {
    return post('employer/auditions/bulk', data);
}

export function getFilterList() {
    return get('employer/publiclibrary/public-audition-filter');
}

export function getAuditionCreateCategories() {
    return get('employer/auditions/categories-for-create');
}

export function getRolesWithSkills(category) {
    return get('employer/auditions/roles', { category });
}

export function createAssessmentRequest(body) {
    return post('employer/assessments/requests', body);
}

export function deleteScreeningFile(questionId) {
    return del(`employer/screening-questions/${questionId}/file`);
}

export function deleteScreeningZiggeoFile(questionId) {
    return del(`employer/screening-questions/${questionId}/ziggeo`);
}

export function uploadScreeningAuditionFile(questionId, data) {
    return post(`employer/screening-questions/${questionId}/file`, data);
}

export function saveScreeningZiggeo(questionId, data) {
    return patch(`employer/screening-questions/${questionId}/ziggeo`, data);
}

export function generateAssessment(body) {
    return post('employer/auditions/generate', body);
}

export function generateAssessmentSkills(uuid) {
    return post(`employer/auditions/generate/${uuid}/skills`);
}

export function generateAssessmentSkillSet(uuid) {
    return post(`employer/auditions/generate/${uuid}/skill-set`);
}

export function compoundGeneratedQuestionTypes(uuid) {
    return post(`employer/auditions/generate/${uuid}/questions`);
}

export function getGeneratedCompletedQuestions(uuid) {
    return get(`employer/auditions/generate/${uuid}/completed-questions`);
}

export function uploadGenerationFile(body) {
    return post('employer/auditions/generate/file', body);
}

export function reUploadGenerationFile(body, uuid) {
    return post(`employer/auditions/generate/${uuid}/file`, body);
}

export function saveGeneratedAssessment(uuid) {
    return post(`employer/auditions/generate/${uuid}/save`);
}

export function getGeneratedCount() {
    return get('employer/auditions/generate/count');
}
