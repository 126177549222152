import { clone } from 'helper/commonFunctions';
import { tracking } from 'helper/eventSegment';

export default {
    START_SIGNUP: () => {
        tracking(
            'OB-signup-started',
            {
                page_path: window.location.hostname + window.location.pathname
            }
        );
    },
    FAILED_SUBMIT_SIGNUP: (validation, ) => {
        tracking(
            'OB-signup-failed',
            {
                validation
            }
        );
    },
    SUBMIT_SIGNUP: (data) => {
        const cloneData = clone(data);
        cloneData.password = undefined;
        tracking(
            'OB-signup-submitted',
            {
                cloneData
            }
        );
    },
    // onboarding
    FILL_COMPANY_DETAILS_STARTED: () => {
        tracking('OB-company-details-started');
    },
    FILL_COMPANY_DETAILS_SAVED: (eventObj) => {
        tracking('OB-company-details-saved', eventObj);
    },
    COLLEAGUE_INVITED_SUCCESS: (eventObj) => {
        tracking('OB-colleague-invited', eventObj);
    },
    CREATE_SA_CLICKED: () => {
        tracking('OB-createSA-clicked');
    },
    INVITE_CANDIDATES_CLICKED: () => {
        tracking('OB-invite-candidates-clicked');
    },
    VERIFY_STARTED: () => {
        tracking('OB-verify-started');
    },
    VERIFY_SUBMITTED: () => {
        tracking('OB-verify-submitted');
    },
    ACCOUNT_CODE_RESENT: () => {
        tracking('OB-account-code-resent');
    },

    LIBRARY_LOADED: () => {
        tracking('OB-library-loaded');
    },
    LIBRARY_TT_SELECTED: (eventObj) => {
        tracking('OB-library-tt-selected', eventObj);
    },
    TT_STARTED: (eventObj) => {
        tracking('OB-tt-started', eventObj);
    },
    SELECT_LOADED: (eventObj) => {
        tracking('OB-select-loaded', eventObj);
    },
    CARD_LOADED: (eventObj) => {
        tracking('OB-card-loaded', eventObj);
    },
    GET_STARTED_CLICKED: (eventObj) => {
        tracking('OB-get-started-clicked', eventObj);
    },
    GENERATE_LOADED: () => {
        tracking('OB-generate-loaded');
    }
};
