import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import styles from '../../styles';

const Sidebar = ({
    classes, firstOpen, canGenerate
}) => (
    <div className={clsx(classes.sidebar, 'u-dsp--f u-jc--sb u-fdir--column')}>
        <div className="title">
            {firstOpen && (
                <div><b>Welcome to Vervoe</b> <span className={classes.emodji} role="img" aria-label="">🎉</span></div>
            )}
            <div className="description">
                Our <span>assessment library has evolved</span> — instead of static tests, we now <span>dynamically build assessments</span> based on the skills
                needed for your role. Simply <span>add a few bullet points</span> or <span>upload the full job description</span>, and our AI will pull
                the right skills and turn them into a <span>powerful, tailored assessment</span>—ready to help you find the best candidate.
            </div>
            {
                !canGenerate && canGenerate !== null && (
                    <div className="generateWarning">
                        You’ve reached the end of the limit of attempts on the Free Trial.
                        <div>
                            <ButtonBase
                                color="primary"
                                variant="text"
                                onClick={() => window.open('/subscriptions', '_blank')}
                            >
                            Upgrade now to unlock more
                            </ButtonBase>
                        </div>
                    </div>
                )
            }
        </div>
        <div className="steps">
            <div><b>How it works</b></div>
            <div><b>Step 1:</b> Upload job description</div>
            <div><b>Step 2:</b> <a href="https://help.vervoe.com/hc/en-us/articles/33459197657108-AI-Assessment-Builder" rel="noopener noreferrer" target="_blank">Skills Taxonomy</a> </div>
            <div><b>Step 3:</b> Review assessment</div>
            <div><b>Step 4:</b> Invite Candidates</div>
        </div>
    </div>
);

export default withStyles(styles)(Sidebar);
