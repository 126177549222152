import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import Dialog from 'libraries/Dialog';
import AddIcon from 'img/add_grey.svg';
import AlertIcon from 'img/alert.svg';

import { addBatchOfTags } from 'requests/SettingsRequests';
import Zendesk from 'helper/zendeskFunctions';
import { assessmentSettingsCtx } from 'pages/assessment/store';
import { appCtx } from 'components/appStore';
import styles from './styles';

const AddNewTagsDialog = ({
    classes, open, onClose, newTags, totalTags, onAddTags
}) => {
    const { getTags } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);
    const { companySettings } = company;

    const overLimitCount = Math.max(totalTags - companySettings.tags, 0);

    const addTags = () => {
        addBatchOfTags({ titles: newTags }).then(({ success }) => {
            if (success) {
                getTags();
                onAddTags();
                onClose(false);
            }
        });
    };

    if (!newTags.length) return null;

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={onClose}
                handleClose={onClose}
                titleComponent={overLimitCount ? '' : 'Add tags'}
                actionComponent={overLimitCount ? null : (
                    <>
                        <Button
                            color="primary"
                            className="u-txt--bold"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                            variant="contained"
                            onClick={addTags}
                        >
                            Add
                        </Button>
                    </>
                )}
            >
                {
                    overLimitCount ? (
                        <div className={classes.tagsLimitContainer}>
                            <img src={AlertIcon} alt="" />
                            <div className={classes.limitTitle}>Maximum tag limit reached.</div>
                            <div className={classes.limitMessage}>You've exceeded the limit by <b>{ overLimitCount } tag{overLimitCount > 1 && 's'}</b>.</div>
                            <Button
                                fullWidth
                                color="primary"
                                className="u-txt--bold u-mrg--tx2"
                                onClick={() => Zendesk.open(true)}
                            >
                                Talk To Sales
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className={classes.subTitle}>Add the following tags to your account?</div>
                            {newTags.map((tag, i) => (
                                <div key={i} className={classes.tag}>
                                    <img src={AddIcon} alt="" />
                                    {tag}
                                </div>
                            ))}
                        </>
                    )
                }
            </Dialog>
        </>
    );
};

export default withStyles(styles)(withRouter(AddNewTagsDialog));
