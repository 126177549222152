import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import EditIcon from 'img/edit.svg';
import DeleteIcon from 'img/trash.svg';
import TooltipBlack from 'libraries/Tooltip';

import SkillsInput from './SkillsInput';
import CreateSkill from './CreateSkill';
import EditSkillDialog from './EditSkillDialog';
import RemoveSkillDialog from './RemoveSkillDialog';

const styles = theme => ({
    skillInput: {
        maxWidth: 250
    },
    skillsWrapper: {
        padding: '0 30px 20px',
        marginBottom: 20,
        borderBottom: `2px solid ${theme.palette.grey[300]}`
    },
    selectedSkillWrapper: {
        padding: '0 30px'
    },
    icon: {
        marginTop: -5,
        '& img': {
            width: 20
        },
        '&:first-of-type': {
            marginLeft: 7
        }
    }
});

const SkillsSelection = observer(({ classes, selectedSkill, setSelectedSkill }) => {
    const [openedDialog, setOpenedDialog] = useState(false);
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

    const handleDialog = () => {
        setOpenedDialog(!openedDialog);
    };

    const handleDeleteDialog = () => {
        setOpenedDeleteDialog(!openedDeleteDialog);
    };

    const onRemove = () => {
        setSelectedSkill(null);
    };

    const onEdit = (skill) => {
        setSelectedSkill(skill);
    };

    return <>
        <div className={clsx('u-dsp--distribute', classes.skillsWrapper)}>
            <SkillsInput
                className={classes.skillInput}
                selectedSkill={selectedSkill}
                setSelectedSkill={setSelectedSkill}
            />
            <CreateSkill />
        </div>
        { selectedSkill && selectedSkill !== -1 && (
            <div className={classes.selectedSkillWrapper}>
                <Typography className="u-mrg--bx2" variant="h6">
                    {selectedSkill.title}
                    <TooltipBlack label="Edit Skill">
                        <IconButton className={classes.icon} onClick={handleDialog} size="large">
                            <img src={EditIcon} alt="edit" />
                        </IconButton>
                    </TooltipBlack>
                    <TooltipBlack label="Remove Skill">
                        <IconButton className={classes.icon} onClick={handleDeleteDialog} size="large">
                            <img src={DeleteIcon} alt="delete" />
                        </IconButton>
                    </TooltipBlack>
                </Typography>
                <EditSkillDialog
                    skill={selectedSkill}
                    handleDialog={handleDialog}
                    openedDialog={openedDialog}
                    onSuccess={onEdit}
                />
                <RemoveSkillDialog
                    onClose={handleDeleteDialog}
                    onRemove={onRemove}
                    open={openedDeleteDialog}
                    skill={selectedSkill}
                />
            </div>
        )}
    </>;
});

export default withStyles(styles)(SkillsSelection);
