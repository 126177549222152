import React, { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import ProtectedWithSpecificValue from 'components/routes/protected_with_specific_value';
import ContentAssessment from 'pages/content_library/content_assessment';
import ContentLibrary from './assessments_library';
import { assessmentListCtx } from './assessments_library/content_assessments/AssessmentList/store';

const ContentLibraryWrapper = observer(({ ...other }) => {
    const { company } = other;
    const companyHasCMS = checkHasCompanyFeature(company, 'CMS');
    const { cleanState } = useContext(assessmentListCtx);

    useEffect(() => () => {
        cleanState();
    }, []);

    return (
        <Switch>
            <ProtectedWithSpecificValue value={companyHasCMS} path="/content-library/:auditionUuid" render={props => (<ContentAssessment {...props} {...other} />)} />
            <ProtectedWithSpecificValue value={companyHasCMS} path="/content-library" render={props => (<ContentLibrary {...props} {...other} />)} />
        </Switch>
    );
});

export default ContentLibraryWrapper;
