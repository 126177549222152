import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CardMenuItem from 'components/assessments_pages/AssessmentFolders/CardMenuItem';
import RemoveGreyIcon from 'img/remove_grey.svg';
import CreateSet from 'img/add_to_assessments.svg';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import SynchroniseButton from '../../../../../common/SynchroniseButton';
import CreateSetDialog from '../../../../../common/CreateSetDialog';
import DeleteSetDialog from '../../../../../common/DeleteSetDialog';


const styles = () => ({
    menuIcon: {
        transform: 'rotate(90deg)',
        marginLeft: 10
    }
});

const SetMenu = observer(({
    classes, sets, selectedSkill, selectedSetData,
    setSets, setSelectedSet, setSelectedIndex,
    selectedIndex, setSelectedSetData
}) => {
    const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);
    const [openedCreateDialog, setOpenedCreateDialog] = useState(false);

    const handleDeleteDialog = () => {
        setOpenedDeleteDialog(!openedDeleteDialog);
    };

    const handleCreateDialog = () => {
        setOpenedCreateDialog(!openedCreateDialog);
    };

    const onCreate = (skill) => {
        if ((selectedSkill && skill.companySkill && skill.companySkill.id === selectedSkill.id) || !selectedSkill) {
            setSets([skill, ...sets]);
            setSelectedSet(skill);
            setSelectedIndex(0);
        }
    };

    const onDelete = () => {
        const newSelectedIndex = selectedIndex > 0 ? selectedIndex - 1 : 0;
        const newSet = [...sets];
        newSet.splice(selectedIndex, 1);
        setSets(newSet);
        setSelectedSet(newSet[newSelectedIndex]);
        setSelectedIndex(newSelectedIndex);
    };

    const menuItems = [{
        label: <CardMenuItem label="Create new set" icon={CreateSet} />,
        onClick: handleCreateDialog
    }];

    if (selectedSetData) {
        menuItems.push({
            label: <CardMenuItem label="Delete this set" icon={RemoveGreyIcon} />,
            onClick: handleDeleteDialog
        });
    }

    return (
        <>
            <div className="u-txt--nowrap">
                {
                    selectedSetData && (
                        <SynchroniseButton
                            uuid={selectedSetData.uuid}
                            disabled={selectedSetData.synchronized || selectedSetData.status === 'draft'}
                            setSelectedSetData={setSelectedSetData}
                        />
                    )
                }
                <BoardItemMenu
                    iconMenuClassName={classes.menuIcon}
                    options={menuItems}
                />
            </div>
            <CreateSetDialog
                openedDialog={openedCreateDialog}
                handleDialog={handleCreateDialog}
                selectedSkill={selectedSkill}
                onSuccess={onCreate}
            />
            <DeleteSetDialog
                openedDialog={openedDeleteDialog}
                handleDialog={handleDeleteDialog}
                selectedSetData={selectedSetData}
                onSuccess={onDelete}
            />
        </>
    );
});

export default withStyles(styles)(SetMenu);
