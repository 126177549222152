import { del, get, patch, post } from '../helper/API';

export function changeChatbotSettings(scriptSlug, screening) {
    return patch(`employer/auditions/${scriptSlug}/screening-chatbot`, { screening });
}


export function loadChatbotSettings(scriptSlug) {
    return get(`employer/auditions/${scriptSlug}/chatbot-widget`);
}

export function changeChatbotWidgetSettings(scriptSlug, enabled) {
    return patch(`employer/auditions/${scriptSlug}/chatbot-widget`, { enabled });
}

export function addChatbotWidgetLink(scriptSlug, url) {
    return post(`employer/auditions/${scriptSlug}/chatbot-widget/links`, { url });
}

export function changeChatbotWidgetLink(linkId, url) {
    return patch(`employer/auditions/chatbot-widget/links/${linkId}`, { url });
}

export function removeChatbotWidgetLink(linkId) {
    return del(`employer/auditions/chatbot-widget/links/${linkId}`);
}
