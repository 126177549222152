import axios from 'axios';
import { removeTokensFromLocalStorage } from 'helper/commonFunctions';
import { appStore } from 'components/appStore';

const apiURL = `${process.env.REACT_APP_API_URL}/api/`;
const company = (process.env.REACT_APP_BASE_URL !== window.location.hostname) ? window.location.hostname.split('.')[0] : false;
// const company = process.env.REACT_APP_COMPANY_NAME;

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Company-Slug': company
};

function addAuthorizationTokenToHeaders() {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
}

function getNewToken() {
    const instanceAdditional = axios.create({ apiURL });
    addAuthorizationTokenToHeaders();
    const config = {
        method: 'post',
        url: 'public/refresh/token',
        baseURL: apiURL,
        headers,
        data: {
            refreshToken: localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken')
        }
    };
    return instanceAdditional(config)
        .then((response) => {
            const { data } = response;
            if (data.success) {
                return data;
            }
            return null;
        })
        .catch(() => {
            removeTokensFromLocalStorage();
            window.location = '/login';
        });
}

function makeRequest(method, url, parameters, customConfig = {}) {
    let data = {};
    let params = {};
    if (method === 'get') {
        params = parameters;
    } else {
        data = parameters;
    }

    const instance = axios.create({ apiURL });
    addAuthorizationTokenToHeaders();
    const config = {
        method,
        baseURL: apiURL,
        url,
        headers,
        data,
        params,
        ...customConfig
    };

    instance.isCancel = axios.isCancel;
    instance.interceptors.response.use(response => response.data || response, (err) => {
        const error = err.response;
        if (error && error.status === 401 && !config.__isRetryRequest) {
            return getNewToken()
                .then((data2) => {
                    config.__isRetryRequest = true;
                    if (data2) {
                        headers.Authorization = `Bearer ${data2.accessToken}`;
                        localStorage.setItem('refreshToken', data2.refreshToken);
                        localStorage.setItem('token', data2.accessToken);
                        return instance.request(config);
                    }
                    return data2;
                });
        }
        if (error && error.status === 503 && !config.__isRetryRequest) {
            config.__isRetryRequest = true;
            return instance.request(config);
        }
        if (error && error.status === 401 && config.__isRetryRequest) {
            removeTokensFromLocalStorage();
            window.location = '/login';
            return;
        }
        if (!instance.isCancel(err)) {
            console.log(err.message || 'Something went wrong.' || error.config);
        }
        if (error && (error.status === 500) && !config.__isRetryRequest) {
            const { flashMessage } = appStore;
            flashMessage('Something went wrong', 'error');
        }
        if (error && (error.status === 404) && !config.__isRetryRequest) {
            console.log('Something went wrong: 404');
        }
        return Promise.reject(err);
    });
    addAuthorizationTokenToHeaders();
    return instance.request(config);
}

export function post(url, params = {}, customConfig = {}) {
    return makeRequest('post', url, params, customConfig);
}

export function get(url, params = {}, customConfig = {}) {
    return makeRequest('get', url, params, customConfig);
}

export function patch(url, params = {}, customConfig = {}) {
    return makeRequest('patch', url, params, customConfig);
}

export function del(url, params = {}, customConfig = {}) {
    return makeRequest('delete', url, params, customConfig);
}

export function put(url, params = {}, customConfig = {}) {
    return makeRequest('put', url, params, customConfig);
}
