import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';
import { withStyles, withTheme } from '@mui/styles';

import QuestionWarning from 'img/question-warning.svg';
import CheckInCircle from 'img/checkInCircle.svg';
import ChevronRight from 'img/chevronRight.svg';
import Optimizing from 'img/shieldLightning.svg';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import ALLinearProgress from 'components/grade_components/LinearProgress';
import ALProgressBar from 'components/grade_components/ALProgressBar';

import {
    AI_HEALTH_STATUS_LOW,
    AI_HEALTH_STATUS_MEDIUM, AI_HEALTH_STATUS_HIGH, AI_LIGHT_OPTIMIZED,
    AI_HEALTH_STATUS_OPTIMIZED, MANUAL_GRADING_REQUIRED, AI_LIGHT_INITIAL, AI_LIGHT_GRADING_REQUIRED
} from 'helper/constants';
import GradingResultsDialog from '../grading_results';

import styles from './styles';


const ActiveLearningStatus = withTheme(withStyles(styles)(({
    match, status, classes, theme
}) => {
    const [dialogOpen, setDialogOpen] = useState(null);
    const [openedDrawerSection, setOpenedDrawerSection] = useState(null);
    const {
        buttonBase, auxiliaryText, statusLabel,
        statusText, questionIcon, insightsButton
    } = classes;

    const { scriptSlug } = match.params;


    const returnALStatusObj = () => {
        if (!(status > 0)) return null;

        switch (status) {
            case AI_HEALTH_STATUS_LOW: return {
                backgroundColor: '#FF113D',
                label: 'Low',
                text: <>More candidate answers required<br />to predict accurate scores</>,
                tooltipText: 'Add correct answer samples to increase the accuracy of your score!',
                activeLink: true
            };
            case AI_HEALTH_STATUS_MEDIUM: return {
                backgroundColor: '#009879',
                color: '#ffffff',
                label: 'Medium',
                text: 'Candidate answers require grading',
                progressBar: true,
                tooltipText: 'Grade candidate responses and take it to the next level!',
                activeLink: true
            };
            case AI_HEALTH_STATUS_HIGH: return {
                backgroundColor: '#009879',
                color: '#ffffff',
                label: 'High',
                text: 'Candidate answers require grading',
                progressBar: true,
                tooltipText: 'Grade responses and train Vervoe to be more like a member of your team!',
                activeLink: true
            };
            case MANUAL_GRADING_REQUIRED: return {
                backgroundColor: theme.palette.yellow[50],
                color: theme.palette.yellow[900],
                label: 'Manual Grades Required',
                text: '',
                progressBar: false,
                tooltipText: 'Grade responses and train Vervoe to be more like a member of your team!',
                activeLink: true
            };
            case AI_HEALTH_STATUS_OPTIMIZED: return {
                backgroundColor: '#009879',
                color: '#ffffff',
                label: 'Optimized',
                text: 'You\'ve unlocked a high level of accuracy!',
                progressBar: true,
                hasInsights: true,
                activeLink: true
            };
            case AI_LIGHT_INITIAL: return {
                backgroundColor: theme.palette.blue[700],
                color: '#ffffff',
                label: 'Optimized',
                progressBar: false,
                hasInsights: false,
                activeLink: false
            };
            case AI_LIGHT_GRADING_REQUIRED: return {
                backgroundColor: theme.palette.yellow[900],
                color: '#ffffff',
                label: 'Grading Required',
                text: 'Review and score ',
                progressBar: false,
                tooltipText: 'Grade candidate responses and take it to the next level!',
                activeLink: true
            };
            case AI_LIGHT_OPTIMIZED: return {
                backgroundColor: theme.palette.blue[700],
                color: '#ffffff',
                label: 'Optimized',
                progressBar: false,
                hasInsights: false,
                activeLink: false
            };
            case 'optimizing': return {
                backgroundColor: '#0032DD',
                color: '#ffffff',
                activeLink: false,
                text: <>
                    <div style={{ fontWeight: 700, marginBottom: 5 }}>
                        <img className="u-mrg--r/2" src={Optimizing} alt="!" />
                        <span style={{ color: '#0032DD' }}>
                            Optimizing.&nbsp;
                        </span>
                        Recalculating based on grading
                    </div>
                    <ALLinearProgress />
                    <span style={{ color: '#A9AAAE', fontSize: 10 }}>
                        Optimizing may take up to 3 hours.
                    </span>
                </>
            };
            case 'optimized': return {
                backgroundColor: '#0032DD',
                color: '#ffffff',
                label: 'Optimized',
                text: 'Assessment grading complete',
                hasInsights: true,
                activeLink: false
            };
            default: return null;
        }
    };

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleClickInsights = (e) => {
        e.stopPropagation();
        setDialogOpen(true);
        setOpenedDrawerSection('question-insights');
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setOpenedDrawerSection(null);
    };

    if (!returnALStatusObj()) return null;

    const { color, backgroundColor, label, text, progressBar, tooltipText, hasInsights, activeLink } = returnALStatusObj();


    return <>
        <ButtonBase
            className={clsx(buttonBase)}
            disabled={!activeLink}
            onClick={handleClick}
            disableRipple={hasInsights}
        >
            <div className={auxiliaryText}>
                Assessment Score Accuracy
                {tooltipText && <QuestionTooltipBlack label={tooltipText} iconClassName={questionIcon} />}
            </div>
            <div className="u-dsp--f u-ai--center">
                {label && (
                    <div className={statusLabel} style={{ backgroundColor, color }}>
                        {MANUAL_GRADING_REQUIRED !== status && (
                            <img
                                src={(['optimized', AI_LIGHT_OPTIMIZED, AI_LIGHT_INITIAL].includes(status)) ? CheckInCircle : QuestionWarning}
                                alt="!"
                            />)}
                        {label}
                    </div>
                )}
                <div className={statusText}>
                    <span>{text}</span>
                    {hasInsights && (
                        <ButtonBase
                            className={insightsButton}
                            onClick={handleClickInsights}
                        >
                            Review scoring insights
                        </ButtonBase>
                    )}
                </div>
                {activeLink && <img className="u-mrg--lx2" src={ChevronRight} alt=">" />}
            </div>
            {progressBar && <ALProgressBar color={backgroundColor} status={status} />}
        </ButtonBase>
        <GradingResultsDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            interviewSlug={scriptSlug}
            status={status}
            openedDrawerSection={openedDrawerSection}
            setOpenedDrawerSection={setOpenedDrawerSection}
        />
    </>;
}));

export default withRouter(ActiveLearningStatus);
