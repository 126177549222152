import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'libraries/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from 'components/icons/DoneIcon';
import EditIcon from 'img/edit.svg';
import DuplicateIcon from 'img/duplicate.svg';
import AddGrey from 'img/add_grey.svg';
import CloseIcon from 'img/close.svg';

import ReorderIcon from 'img/reorder.svg';

const styles = theme => ({
    wrapper: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    icon: {
        padding: 0,
        backgroundColor: '#fff',
        width: 32,
        height: 32,
        marginLeft: 8,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:disabled': {
            opacity: 0.6
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    iconDone: {
        borderColor: theme.palette.green[600],
        backgroundColor: theme.palette.green[600],
        '&:hover': {
            backgroundColor: `${theme.palette.green[900]} !important`,
            borderColor: theme.palette.green[900]
        }
    }
});

const ItemActions = ({
    classes, onEdit, showDone,
    onDuplicate, onAdd, isInSelectedSet,
    onDelete, hideDuplicate, hideAdd, hideEdit,
    hideReorder, disabledAddToSet, loadingAdd
}) => (
    <div className={classes.wrapper}>
        {
            !hideDuplicate && (
                <Tooltip label="Duplicate">
                    <IconButton className={classes.icon} onClick={onDuplicate} size="large">
                        <img src={DuplicateIcon} alt="duplicate" />
                    </IconButton>
                </Tooltip>
            )
        }
        {
            !hideEdit && (
                <Tooltip label="Edit">
                    <IconButton className={classes.icon} onClick={onEdit} size="large">
                        <img src={EditIcon} alt="edit" />
                    </IconButton>
                </Tooltip>
            )
        }
        {
            !hideAdd && (
                <>
                    {
                        isInSelectedSet ? (
                            <Tooltip label="Remove from Set">
                                {
                                    !showDone ? (
                                        <IconButton
                                            disabled={disabledAddToSet}
                                            className={classes.icon}
                                            onClick={onDelete}
                                            size="large"
                                        >
                                            <img src={CloseIcon} alt="delete" />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            disabled={disabledAddToSet}
                                            className={clsx(classes.icon, classes.iconDone)}
                                            onClick={onDelete}
                                            size="large"
                                        >
                                            <DoneIcon color="#fff" />
                                        </IconButton>
                                    )
                                }
                            </Tooltip>
                        ) : (
                            <Tooltip label="Add to Set">
                                <IconButton
                                    disabled={disabledAddToSet}
                                    className={classes.icon}
                                    onClick={onAdd}
                                    size="large"
                                >
                                    { loadingAdd ? (
                                        <CircularProgress
                                            size={18}
                                        />
                                    ) : (
                                        <img src={AddGrey} alt="add" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </>
            )
        }
        {
            !hideReorder && (
                <Tooltip label="Reorder">
                    <IconButton
                        data-drag="drop"
                        className={clsx(classes.icon, 'u-cursor--g')}
                        size="large"
                    >
                        <img src={ReorderIcon} alt="reorder" />
                    </IconButton>
                </Tooltip>
            )
        }
    </div>
);

export default withStyles(styles)(ItemActions);
