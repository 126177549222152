import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import AssessmentSettingsEvents from 'events/AssessmentSettingsEvents';
import { makeStyles } from '@mui/styles';
import EditPanelSection from '../EditPanelSection';

const useStyles = makeStyles(theme => ({
    wrapper: {
        borderBottom: 'none !important',
        paddingBottom: 10,
        paddingTop: 0
    }
}));

const TimeExtensionRequest = ({
    settings: { requestMoreTimeEnabled }, changeAssessmentSettings, audition: { name, uuid }
}) => {
    const [timeExtensionRequestValue, setTimeExtensionRequestValue] = useState(false);
    const { wrapper } = useStyles();

    useEffect(() => {
        setTimeExtensionRequestValue(requestMoreTimeEnabled);
    }, [requestMoreTimeEnabled]);

    const handleChangeAutoExtendLimit = (e) => {
        const { checked } = e.target;
        changeAssessmentSettings({ requestMoreTimeEnabled: checked });
        setTimeExtensionRequestValue(checked);
        AssessmentSettingsEvents.EXTENSION_REQUEST_ENABLED({ enabled: checked, ttId: uuid, ttName: name });
    };

    return (
        <EditPanelSection
            className={wrapper}
            header={(
                <>
                    Enable time extension request
                    <Switch
                        checked={timeExtensionRequestValue}
                        onChange={handleChangeAutoExtendLimit}
                    />
                </>
            )}
        >
            <p style={{ marginBottom: 0, marginTop: -20 }}>Candidates will be able to request additional time.</p>
        </EditPanelSection>
    );
};

export default TimeExtensionRequest;
