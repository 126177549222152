import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles, withTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import InnerTabs from 'components/layout/InnerTabs';
import UsersIcon from 'components/icons/UsersIcon';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import HiddenEye from 'components/icons/HiddenEye';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CloseIcon from 'img/close.svg';

import styles from '../../styles';

const SelectTabs = observer(({
    classes, history, theme, match, activeAssessment,
    candidateTabs, removeCandidateTab, setCandidateTabs,
    shortlistCandidatesCount, loadAssessmentStats,
    completedNotArchivedCount, archivedCount
}) => {
    const { scriptSlug } = match.params;

    useEffect(() => {
        setCandidateTabs(scriptSlug);
    }, [scriptSlug]);

    const onRemove = (e, id) => {
        e.stopPropagation();
        removeCandidateTab(id, scriptSlug);
        history.push(`/script/select/${scriptSlug}`);
    };

    const candidateTabsElements = candidateTabs.map(({ id, name }) => ({
        label: (<>
            <div>
                <TruncateWithTooltip text={name} width={250} />
            </div>
            <IconButton className={classes.closeIcon} onClick={e => onRemove(e, id)} size="large">
                <img src={CloseIcon} alt="x" />
            </IconButton>
        </>),
        url: `/script/select/${scriptSlug}/candidate/${id}`
    }));

    return (
        <InnerTabs
            loadAssessmentStats={loadAssessmentStats}
            className={classes.innerTabs}
            tabList={[{
                label: (
                    <span className="u-dsp--f u-ai--center">
                        <UsersIcon color={theme.palette.primary.main} className="u-mrg--rx1" />
                        <span>Candidates</span>&nbsp;
                        <span>
                            {completedNotArchivedCount ? ` (${completedNotArchivedCount})` : ''}
                        </span>
                    </span>
                ),
                className: classes.allCandidatesTab,
                url: `/script/select/${scriptSlug}`
            }, {
                label: (
                    <span className="u-dsp--f u-ai--center">
                        <BookmarkIcon color={theme.palette.green[600]} className="u-mrg--rx1" />
                        <span>Shortlist</span>&nbsp;
                        <span>
                            {shortlistCandidatesCount ? ` (${shortlistCandidatesCount})` : ''}
                        </span>
                    </span>
                ),
                className: classes.shortlistTab,
                disabled: !activeAssessment,
                url: `/script/select/${scriptSlug}/shortlist`
            }, {
                label: (
                    <span className="u-dsp--f u-ai--center">
                        <HiddenEye color={theme.palette.primary.main} className="u-mrg--rx1" />
                        <span>Archived</span>&nbsp;
                        <span>
                            {archivedCount ? ` (${archivedCount})` : ''}
                        </span>
                    </span>
                ),
                className: classes.archivedTab,
                url: `/script/select/${scriptSlug}/archived`
            }].concat(candidateTabsElements)}
        />
    );
});

export default withTheme(withStyles(styles)(withRouter(SelectTabs)));
