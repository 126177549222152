import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExtensionButtons from 'components/dialogs/ExtensionsRequestsDialog/ExtentionButtons';

const styles = theme => ({
    blockWrapper: {
        padding: '20px 33px',
        backgroundColor: theme.palette.yellow[150]
    }
});

const ExtendRequestedBlock = ({ candidate, classes, audition, getUserInfo, disabled: disabledProp }) => {
    const [disabled, setDisabled] = useState(disabledProp);

    const {
        candidateUuid, resetReason, resetReasonType,
        userAuditionId, resetRequested
    } = candidate;

    if (!resetRequested) return null;

    return (
        <div className={classes.blockWrapper}>
            <div className="u-dsp--distribute">
                <Typography variant="h5">More Time Requested</Typography>
                <ExtensionButtons
                    disabled={disabled}
                    audition={audition}
                    setDisabled={setDisabled}
                    userAuditionId={userAuditionId}
                    candidateUuid={candidateUuid}
                    onExtendRequestSuccess={getUserInfo}
                />
            </div>
            {Boolean(resetReasonType) && (
                <div className="u-mrg--tx1">
                    <p className="u-mrg--bx0">{resetReasonType}</p>
                    {nl2br(resetReason || '')}
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(withRouter(ExtendRequestedBlock));
