import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { appCtx } from 'components/appStore';
import { observer } from 'mobx-react-lite';
import { checkCompanyCanBeUpgraded, checkHasCompanyFeature } from 'helper/commonFunctions';
import SubscriptionsLink from 'components/subscription/SubscriptionsLink';
import Zendesk from 'helper/zendeskFunctions';
import { REQUEST_CUSTOM_ASSESSMENT } from 'helper/constants';
import { AssessmentTagsListCtx } from '../TabsSearchHeader/store';

const styles = theme => ({
    jobCount: {
        fontWeight: 'normal',
        marginBottom: 0,
        minHeight: 20
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 600,
        '&:hover, &:active, &:focus': {
            color: theme.palette.primary.main
        }
    }
});

const LimitBanner = observer(({ classes, requestCounts }) => {
    const { company } = useContext(appCtx);
    const { openFilterCount } = useContext(AssessmentTagsListCtx);
    const { companySettings, plan } = company;
    if (!companySettings || !plan) return null;
    const { activeAuditionsPerCompany: limitCount, requestCustomAssessmentLimit } = companySettings;
    const { name } = plan;

    const canCompanyUpgrade = checkCompanyCanBeUpgraded(company);
    const canCompanyCreateCustomRequests = checkHasCompanyFeature(company, REQUEST_CUSTOM_ASSESSMENT);
    const isUnlimitedRequests = requestCustomAssessmentLimit === -1;

    if (limitCount === openFilterCount) {
        return (
            <p className={classes.jobCount}>
                You are using <b>{openFilterCount}</b> out of <b>{limitCount}</b> Open Assessment{Boolean(limitCount !== 1) && 's'} on the {name} Plan.&nbsp;
                {canCompanyUpgrade ? (
                    <SubscriptionsLink
                        label="Upgrade Plan"
                        className={classes.link}
                        segmentLocation="active assessments limit warning"
                    />
                ) : (
                    <span
                        role="presentation"
                        onClick={() => window.open('/subscriptions', '_blank')}
                        className={classes.link}
                    >
                        Upgrade
                    </span>)
                }
            </p>
        );
    }

    if (canCompanyCreateCustomRequests && requestCounts != null && !isUnlimitedRequests) {
        return (
            <p className={classes.jobCount}>
                You have <b>{requestCounts.count} Custom</b> Assessment credit{Boolean(requestCounts.count !== 1) && 's'}.&nbsp;
                <span
                    role="presentation"
                    onClick={() => Zendesk.open(true)}
                    className={classes.link}
                >
                    Need more? Talk to Sales
                </span>
            </p>
        );
    }

    return null;
});

export default withStyles(styles)(LimitBanner);
