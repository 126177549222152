import React, { useState } from 'react';
import clsx from 'clsx';
import StepperItem from './StepperItem';
import useStyles from './styles';

const InterviewStepper = ({
    questions, setSelectedQuestionIndex, selectedQuestionIndex,
    color, isAbsolute, assessmentTimed, countScreeningQuestions = 0
}) => {
    const [opened, setOpened] = useState(false);

    const { wrapper, stepperLabel, overflowWrapper, wrapperAbsolute } = useStyles({ hasScreening: Boolean(countScreeningQuestions) });

    if (!questions) return null;

    return (
        <div
            className={clsx(wrapper, isAbsolute && wrapperAbsolute)}
            onMouseEnter={() => { setOpened(true); }}
            onMouseLeave={() => { setOpened(false); }}
        >
            <div className={stepperLabel}>
                <div>0 of {questions.length} answered</div>
            </div>
            <div className={overflowWrapper}>
                {
                    questions.map((item, index) => (
                        <StepperItem
                            index={index + 1}
                            key={item.uuid}
                            opened={opened}
                            item={item}
                            countScreeningQuestions={countScreeningQuestions}
                            setSelectedQuestionIndex={setSelectedQuestionIndex}
                            selectedQuestionIndex={selectedQuestionIndex}
                            color={color}
                            assessmentTimed={assessmentTimed}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default InterviewStepper;
