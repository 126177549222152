import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { checkHasUserPermission } from 'helper/commonFunctions';
import ExclamationIcon from 'components/icons/ExclamationIcon';
import DoneOutlinedIcon from 'components/icons/DoneOutlinedIcon';
import SettingsIcon from 'components/icons/SettingsIcon';
import { appCtx } from 'components/appStore';
import AssessmentEvents from 'events/AssessmentEvents';
import { assessmentSettingsCtx } from 'pages/assessment/store';

import useStyles from './styles';


const StepItem = ({
    active, onClick, hideIcon,
    done, isGreen, label, last,
    className, index
}) => {
    const classes = useStyles();

    return (
        <div
            onClick={onClick}
            className={
                clsx(classes.step,
                    {
                        [classes.stepFirst]: !index,
                        [classes.stepLast]: last,
                        [classes.stepActive]: active,
                        [classes.stepGreenActive]: active && isGreen,
                        [classes.stepGreen]: isGreen
                    }, className)
            }
            role="presentation"
        >
            {
                !hideIcon && (
                    <span>
                        {
                            done ? (
                                <DoneOutlinedIcon className={classes.stepIconDone} />
                            ) : <ExclamationIcon size={15} className={classes.stepIcon} />
                        }
                    </span>
                )
            }
            {label}
        </div>
    );
};

const HeaderStepper = ({ history, location, match, className = '' }) => {
    const classes = useStyles();
    const { company } = useContext(appCtx);
    const { audition, loadEditScript } = useContext(assessmentSettingsCtx);

    const {
        name, uuid, permissions, countCompleteQuestions,
        countRealCandidates, countCompletedNotRejectedCandidates, generatedViaAi
    } = audition;
    const edit = checkHasUserPermission(company, permissions, 'edit');

    const handleStep = (url, callback) => {
        if (callback) callback();
        loadEditScript(history);
        history.push(url);
    };

    const goToSettings = () => {
        history.push({
            pathname: `/script/settings/${audition.slug}/details`,
            state: { from: location.pathname }
        });
    };

    const returnOuterTabs = () => {
        const resultTabs = [
            {
                label: 'Invite',
                value: `/script/invite/${audition.slug}`,
                url: `/script/invite/${audition.slug}/${(countRealCandidates || !edit) ? 'invite-candidates' : 'invitation'}`,
                onClick: () => AssessmentEvents.INVITE_CANDIDATE_VIEWED({ ttId: uuid, ttName: name }),
                isDone: Boolean(countRealCandidates)
            },
            {
                label: 'Select',
                value: `/script/select/${audition.slug}`,
                url: `/script/select/${audition.slug}`,
                isDone: Boolean(countCompletedNotRejectedCandidates)
            },
            {
                label: 'Insights',
                value: `/script/insights/${audition.slug}`,
                url: `/script/insights/${audition.slug}`,
                onClick: () => AssessmentEvents.INSIGHTS_VIEWED({ ttId: uuid, ttName: name }),
                isDone: countCompletedNotRejectedCandidates >= 5,
                isGreen: true
            }
        ]       

        if(!generatedViaAi) {
            resultTabs.unshift({
                label: 'Create',
                value: `/script/edit/${audition.slug}`,
                url: `/script/edit/${audition.slug}`,
                isDone: Boolean(countCompleteQuestions)
            });
        }
        return resultTabs;
    };

    const stepsList = returnOuterTabs();

    if (!stepsList.length) return null;

    const activeStep = stepsList.findIndex(({ url, value = url }) => match.url.includes(value));

    return (
        <Container className={clsx('u-wdt--100p', classes.wrapper, className)}>
            <div className="u-dsp--distribute u-fw--wrap">
                <div className={classes.stepperRoot}>
                    {stepsList.map(({ label, isDone, isGreen, hideIcon, url, onClick, labelClassName = '' }, index) => (
                        <StepItem
                            key={label}
                            label={label}
                            index={index}
                            done={isDone}
                            hideIcon={hideIcon}
                            isGreen={isGreen}
                            active={activeStep === index}
                            last={stepsList.length - 1 === index}
                            className={labelClassName}
                            onClick={() => handleStep(url, onClick)}
                        />
                    ))}
                </div>
                <div className="u-dsp--f u-ai--center">
                    <Button
                        variant="outlined"
                        onClick={goToSettings}
                        disabled={!edit || audition.archived}
                        className={classes.settingsButton}
                    >
                        <SettingsIcon color="#292A2D" className="u-mrg--rx1" />
                        Settings
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default withRouter(HeaderStepper);
