export default theme => ({
    headerWrapper: {
        padding: '25px 50px',
        borderBottom: `2px solid ${theme.palette.grey[200]}`
    },
    closeIcon: {
        position: 'absolute',
        left: 8,
        top: 17
    },
    drawerPaper: {
        width: 660,
        zIndex: 100,
        maxWidth: '100%'
    },
    contentWrapper: {
        position: 'relative',
        padding: '0 40px',
        '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: 0,
            height: 55,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            width: 'calc(100% - 210px)'
        }
    },
    tab: {
        display: 'flex',
        alignItems: 'center',
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        width: 170,
        gap: 10,
        height: 55,
        fontSize: 14,
        fontWeight: 700
    },
    empty: {
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    questionsWrapper: {
        padding: '30px 0'
    }
});
