import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { publishQuestion, publishSet } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';

import stylesJS from './styles';

const ItemSynchronizeStatus = ({
    classes, uuid, className = '',
    isInteractive, synchronized = true,
    flashMessage, setQuestion, isSet, onSynchronized
}) => {
    const [hovered, setHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setHovered(false);
    }, [synchronized]);

    const onHover = () => {
        if (!isInteractive) return;
        setHovered(true);
    };

    const onClick = () => {
        if (!isInteractive) return;
        doRequest(isSet ? publishSet : publishQuestion, isSet ? 'Set' : 'Question');
    };

    const doRequest = (request, key) => {
        setIsLoading(true);
        request(uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    if (setQuestion) setQuestion(data);
                    if (onSynchronized) onSynchronized();
                    flashMessage(`${key} synchronized`, 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onUnHover = () => {
        if (!isInteractive) return;
        setHovered(false);
    };

    return (!synchronized ? (
        <div
            role="presentation"
            onMouseOver={onHover}
            onFocus={onHover}
            onClick={onClick}
            onMouseLeave={onUnHover}
            className={clsx(
                isInteractive && 'u-cursor--p',
                classes.wrapper,
                hovered && classes.hoveredWrapper,
                className
            )}
        >
            {
                isLoading ? (
                    <CircularProgress size={11} className={classes.loader} />
                ) : (
                    <>
                        {hovered ? <span>Synchronize</span> : <span>Unsynchronized</span>}
                    </>
                )
            }
        </div>
    ) : null);
};

export default withStyles(stylesJS)(ItemSynchronizeStatus);
