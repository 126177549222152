import { tracking } from 'helper/eventSegment';

export default {
    GENERATE_CLICKED: () => {
        tracking('AB-generate-clicked');
    },
    GENERATE_TAB_CLICKED: () => {
        tracking('AB-generate-tab-clicked');
    },
    GENERATE_DESCRIPTION_ADDED: (data) => {
        tracking('AB-generate-description-added', data);
    },
    GENERATE_RESTART_CLICKED: () => {
        tracking('AB-generate-restart-clicked');
    },
    GENERATE_NEXT_CLICKED: (data) => {
        tracking('AB-generate-next-clicked', data);
    },
    GENERATE_SAMPLE_CLICKED: () => {
        tracking('AB-generate-sample-clicked');
    },
    GENERATE_PREVIEW_INVITE_CLICKED: (data) => {
        tracking('AB-generate-preview-invite-clicked', data);
    },
    GENERATE_INVITE_CLICKED: (data) => {
        tracking('AB-generate-invite-clicked', data);
    },
    GENERATE_RESET_CLICKED: () => {
        tracking('AB-generate-reset-clicked');
    },
    GENERATE_SAVE_CLICKED: (data) => {
        tracking('AB-generate-save-clicked', data);
    },
    GENERATE_CLOSED: (data) => {
        tracking('AB-generate-closed', data);
    }
};
