import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import QuestionTypes from 'components/marketplace/Preview/common/QuestionTypes';
import AnswerTypes from 'components/marketplace/Preview/common/AnswerTypes';
import QuestionInnerHeader from './QuestionItemHeader';

const styles = theme => ({
    wrapper: {
        padding: 24,
        borderRadius: 8,
        marginBottom: 32,
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    wrapperFocused: {
        backgroundColor: theme.palette.grey[100]
    },
    wrapperSelected: {
        border: `2px solid ${theme.palette.primary.main}`
    }
});

const QuestionItem = observer(({
    classes, index, showOnFocus, onFocus, showOnHover,
    hideDuplicate, hideAdd, hideEdit, hideReorder, audition,
    question, setFocusedIndex, focusedIndex, selectedDuplicatedQuestionId, ...other
}) => {
    const [questionState, setQuestionState] = useState(null);

    useEffect(() => {
        setQuestionState(question);
    }, [question]);

    const focus = () => {
        if (hideDuplicate && hideAdd && hideEdit && hideReorder) return;
        setFocusedIndex(index);
        if (onFocus) onFocus(index);
    };

    const hideMenu = focusedIndex !== index && showOnFocus;

    return (
        <div
            onClick={focus}
            onMouseEnter={showOnHover ? focus : null}
            role="presentation"
            className={clsx(
                classes.wrapper,
                showOnFocus && 'u-cursor--p',
                focusedIndex === index && showOnFocus && classes.wrapperFocused,
                (selectedDuplicatedQuestionId === question.id) && classes.wrapperSelected,
            )}
        >
            {
                questionState && (
                    <>
                        <QuestionInnerHeader
                            question={questionState}
                            hideMenu={hideMenu}
                            {...{
                                hideDuplicate,
                                hideAdd,
                                hideEdit,
                                hideReorder,
                                audition,
                                questionIndex: index
                            }}
                            setQuestionState={setQuestionState}
                            {...other}
                        />
                        <QuestionTypes data={questionState} className="u-mrg--tx2" />
                        <AnswerTypes
                            interview={audition}
                            data={questionState}
                        />
                    </>
                )
            }
        </div>
    );
});

export default withStyles(styles)(QuestionItem);
