import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import TableFilter from 'components/table_components/table_filter';
import { AssessmentTagsListCtx } from 'components/assessments_pages/AssessmentList/PrivateAssessmentsView/TabsSearchHeader/store';

import { foldersCtx } from '../../AssessmentFolders/store';


const MyAssessmentsTableFilter = observer(() => {
    const {
        assessmentsFilter, setAssessmentsFilter, openFilterCount,
        closedFilterCount, allFilterCount
    } = useContext(AssessmentTagsListCtx);
    const { activeFolderId } = useContext(foldersCtx);

    const options = [{
        value: '',
        label: 'Show all',
        count: allFilterCount
    }, {
        value: 'open',
        label: 'Open',
        count: openFilterCount
    }, {
        value: 'closed',
        label: 'Closed',
        count: closedFilterCount
    }];

    options.forEach((item) => { item.checked = assessmentsFilter === item.value; });

    const handleChange = (value) => {
        setAssessmentsFilter(value, activeFolderId);
    };

    return (
        <TableFilter
            isFilterActive={assessmentsFilter !== ''}
            innerHeader="Assessments"
            options={options}
            showCountNumber
            isCheckbox={false}
            handleChange={handleChange}
        />
    );
});

export default MyAssessmentsTableFilter;
