import React, { useState, useContext, useEffect } from 'react';
import * as qs from 'query-string';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { FilterCtx } from 'pages/expert_assessments/filter-store';
import PublicAssessmentsView from './PublicAssessmentsView';
import PrivateAssessmentsView from './PrivateAssessmentsView';
import { AssessmentTagsListCtx } from './PrivateAssessmentsView/TabsSearchHeader/store';

import styles from './styles';
import { AssessmentListStore } from './store';
import { AssessmentQueryCtx } from './queryStore';


const AssessmentList = observer(({
    location, history, activeFolderId, publicAssessments, classes
}) => {
    const [listStore] = useState(() => new AssessmentListStore());
    const { query, setQuery } = useContext(AssessmentQueryCtx);
    const { getAssessmentFilterNumbers, setAssessmentsFilter, assessmentsFilter } = useContext(AssessmentTagsListCtx);
    const filterStore = useContext(FilterCtx);
    const [filterData, setFilterData] = useState(filterStore.filterData);

    const {
        assessments, totalCount,
        getPrivateAssessments,
        getPublicAssessments, setTotalCount,
        setAssessments, setLoadingAssessments,
        setLoadingSearchAssessments, setCurrentPage,
        loadingAssessments, loadingSearchAssessments,
        cleanState, refineByTitle, mode,
        setMode, setRefineByTitle,
        currentPage
    } = listStore;

    const parsed = qs.parse(location.search);
    const { assessmentName } = parsed;

    useEffect(() => {
        if (location.state?.needRefetch) {
            setCurrentPage(0);
            getAssessments();
            history.replace(location.pathname, {});
        }
    }, [location.state]);

    useEffect(() => {
        setAssessmentsFilter(localStorage.getItem('assessmentsFilter') || '');
        if (assessmentName) {
            history.push(location.pathname);
            setQuery(assessmentName);
        }
        getAssessments();
        if (!publicAssessments) {
            getAssessmentFilterNumbers();
        }
        return () => {
            cleanState();
        };
    }, []);

    useEffect(() => {
        if (!publicAssessments) {
            setCurrentPage(0);
            getAssessments();
        }
    }, [activeFolderId]);

    useEffect(() => {
        setCurrentPage(0);
        getAssessments();
    }, [assessmentsFilter, refineByTitle, mode]);

    useEffect(() => {
        if (filterData !== filterStore.filterData) {
            setFilterData(filterStore.filterData);
        }
    }, [filterStore.filterData]);

    useEffect(() => {
        setLoadingAssessments(true);
        setLoadingSearchAssessments(true);
        setTotalCount(0);
        setCurrentPage(0);
        setAssessments([]);
        getAssessments();
    }, [query, filterData]);

    const getAssessments = () => {
        if (publicAssessments) {
            getPublicAssessments(filterData);
        } else {
            setAssessments([]);
            getPrivateAssessments(activeFolderId);
        }
    };

    const handleDuplicate = () => {
        setCurrentPage(currentPage - 1);
        getAssessments();
        window.scrollTo(0, 0);
    };

    const changePage = (page) => {
        setCurrentPage(page - 1);
        setLoadingSearchAssessments(true);
        getAssessments();
    };

    const removeAssessments = (opportunityIndex) => {
        const newAssessments = JSON.parse(JSON.stringify(assessments));
        newAssessments.splice(opportunityIndex, 1);
        setAssessments(newAssessments);
        setTotalCount(totalCount - 1);
        if (!publicAssessments) getAssessmentFilterNumbers();
    };

    const otherProps = {
        ...listStore,
        // if not to specify it explicitly - doesn't work
        setMode,
        setRefineByTitle,
        setAssessments
    };

    return (
        <div className={clsx(classes.boardWrapper, publicAssessments ? 'assessment-library' : '')}>
            { publicAssessments ? (
                <PublicAssessmentsView
                    removeAssessments={removeAssessments}
                    changePage={changePage}

                    {...otherProps}
                />
            ) : (
                <PrivateAssessmentsView
                    removeAssessments={removeAssessments}
                    changePage={changePage}
                    handleDuplicate={handleDuplicate}

                    {...otherProps}
                />
            )}
            {
                (loadingSearchAssessments || loadingAssessments) && (
                    <div className={classes.searchLoaderWrapper}>
                        <CircularProgress size={25} className={classes.loader} />
                    </div>
                )
            }
        </div>
    );
});

export default withRouter(withStyles(styles)(AssessmentList));
