import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import EmptyIcon from 'img/emptyContentWithPlus.svg';
import { reorderCompanyQuestion } from 'requests/CMSRequests';
import { appCtx } from 'components/appStore';
import SetItemHeader from '../../../common/SetItemHeader';
import QuestionsList from '../../../common/QuestionsList';
import EmptyQuestionsList from '../../../common/EmptyQuestionsList';
import { setLibraryCtx } from '../../store';

const styles = theme => ({
    wrapper: {
        padding: '26px 30px',
        marginBottom: 20,
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        minHeight: 99
    },
    titleLabel: {
        color: theme.palette.grey[900],
        backgroundColor: 'transparent',
        fontSize: 16,
        textTransform: 'uppercase',
        '& span': {
            marginLeft: 7
        }
    },
    emptySet: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: 18,
        fontWeight: 700,
        '& img': {
            width: 40,
            marginBottom: 10
        }
    }
});

const SelectedSet = observer(({ classes, handleOpenCreateDrawer, handleOpenAddQuestionDrawer, ...other }) => {
    const { selectedSetData, loadingSetInfo, loadSets, selectedSkill, setQuestions, loadSetInfo,
        selectedSetUuid, updateQuestions } = useContext(setLibraryCtx);
    const { flashMessage } = useContext(appCtx);

    if (loadingSetInfo) {
        return (
            <div className="u-txt--center u-mrg--tx2">
                <CircularProgress size={22} />
            </div>
        );
    }

    const loadData = () => {
        loadSets();
        loadSetInfo(selectedSetUuid);
    };

    if (!selectedSetData) {
        return (
            <>
                <div className={classes.wrapper}>
                    <SetItemHeader
                        classTitleRow={classes.titleLabel}
                        disabled
                    />
                </div>
                <div className={classes.emptySet}>
                    <img src={EmptyIcon} alt="empty" />
                    Select a set
                </div>
            </>
        );
    }

    const saveQuestionsSort = (question, order) => {
        reorderCompanyQuestion(selectedSetUuid, question.uuid, { order })
            .then(({ data, success }) => {
                if (success && data) {
                    updateQuestions(data.companyQuestions);
                    flashMessage('Question reordered', 'done');
                }
            });
    };


    return (
        <>
            <div className={classes.wrapper}>
                <SetItemHeader
                    classTitleRow={classes.titleLabel}
                    set={selectedSetData}
                    handleOpenCreateDrawer={handleOpenCreateDrawer}
                    handleOpenAddQuestionDrawer={handleOpenAddQuestionDrawer}
                    isFocused
                    showEdit
                    showDelete
                    showCreateQuestion
                    showAddQuestion
                    onEdit={loadData}
                    onDelete={loadSets}
                    onPublish={loadData}
                />
            </div>
            <div>
                {
                    setQuestions.length ? (
                        <QuestionsList
                            saveQuestionsSort={saveQuestionsSort}
                            hideDuplicate
                            selectedSkill={selectedSkill}
                            questions={setQuestions}
                            selectedSet={selectedSetData}
                            showOnHover
                            deleteCallback={() => loadSetInfo(selectedSetUuid)}
                            {...other}
                        />
                    ) : (
                        <EmptyQuestionsList hasSet onClick={handleOpenAddQuestionDrawer} />
                    )
                }
            </div>
        </>
    );
});

export default withStyles(styles)(SelectedSet);
