import { makeStyles } from '@mui/styles';

const StepGreenActive = theme => ({
    color: theme.palette.green[50],
    backgroundColor: theme.palette.green[600],
    '& $stepIcon': {
        '& ellipse': {
            fill: theme.palette.green[50]
        },
        '& path': {
            stroke: theme.palette.green[50]
        }
    },
    '& $stepIconDone': {
        '& path:first-child': {
            fill: theme.palette.green[50]
        },
        '& path:nth-child(2)': {
            fill: theme.palette.green[600]
        }
    }
});

const StepActive = theme => ({
    color: theme.palette.blue[50],
    backgroundColor: theme.palette.blue[500],
    '&:after': {
        borderLeftColor: theme.palette.blue[500],
        boxShadow: `-5px 0px 0px -1px ${theme.palette.blue[500]}`
    },
    '& $stepIcon': {
        '& ellipse': {
            fill: theme.palette.blue[50]
        },
        '& path': {
            stroke: theme.palette.blue[50]
        }
    },
    '& $stepIconDone': {
        '& path:first-child': {
            fill: theme.palette.blue[50]
        },
        '& path:nth-child(2)': {
            fill: theme.palette.blue[500]
        }
    }
});

export default makeStyles(theme => ({
    wrapper: {
        margin: '7px auto 0',
        padding: '25px 0 22px',
        borderTop: `2px solid ${theme.palette.grey[300]}`
    },
    step: {
        textDecoration: 'underline',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        textUnderlineOffset: 3,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 12,
        marginRight: 12,
        padding: 15,
        paddingLeft: 25,
        transition: 'all 300ms',
        color: theme.palette.blue[500],
        backgroundColor: theme.palette.blue[50],
        whiteSpace: 'nowrap',
        '& > span': {
            display: 'flex'
        },
        '& $stepIcon': {
            '& ellipse': {
                fill: theme.palette.blue[500]
            },
            '& path': {
                stroke: theme.palette.blue[500]
            }
        },
        '& $stepIconDone': {
            '& path:first-child': {
                fill: theme.palette.blue[500]
            }
        },
        '&::after': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            transition: 'all 300ms',
            width: 0,
            height: 0,
            right: -15,
            zIndex: 11,
            borderTop: '23px solid transparent',
            borderLeft: `15px solid ${theme.palette.blue[50]}`,
            borderBottom: '23px solid transparent',
            boxShadow: `-5px 0px 0px -1px ${theme.palette.blue[50]}`
        },
        '&::before': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            width: 0,
            height: 0,
            left: 0,
            zIndex: 10,
            borderTop: '23px solid transparent',
            borderLeft: '15px solid #fff',
            borderBottom: '23px solid transparent'
        },
        '&:hover': { ...StepActive(theme) }
    },
    stepActive: { ...StepActive(theme) },
    stepIcon: { marginRight: 12 },
    stepIconDone: { marginRight: 12 },
    stepFirst: {
        paddingLeft: 15,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        '&:before': {
            display: 'none'
        }
    },
    stepGreen: {
        backgroundColor: theme.palette.green[50],
        color: theme.palette.green[600],
        '& $stepIcon': {
            '& ellipse': {
                fill: theme.palette.green[600]
            },
            '& path': {
                stroke: theme.palette.green[600]
            }
        },
        '& $stepIconDone': {
            '& path:first-child': {
                fill: theme.palette.green[600]
            }
        },
        '&:hover': {
            ...StepGreenActive(theme)
        }
    },
    stepLast: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        '&:after': {
            display: 'none'
        }
    },
    stepGreenActive: {
        ...StepGreenActive(theme)
    },
    stepperRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        padding: 0
    },
    active: {
        borderColor: theme.palette.primary.main
    },
    settingsButton: {
        paddingRight: 24,
        paddingLeft: 18,
        '&:disabled svg': {
            opacity: 0.4
        },
        '&:first-child': {
            marginRight: 10
        }
    }
}));
