export default theme => ({
    headerPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 29,
        paddingBottom: 29,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 75,
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        height: '80px',
        zIndex: 8,
        background: 'white',
        '@media only screen and (max-width: 767px)': {
            paddingLeft: 20,
            paddingRight: 20
        }
    },
    closeIcon: {
        width: 30,
        height: 30
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    companyLogo: {
        color: '#3f3f3f',
        fontSize: 25,
        fontWeight: 400,
        fontFamily: 'Proximanova-Extrabold'
    },
    timeLimit: {
        marginTop: 5,
        marginLeft: 20,
        '@media only screen and (max-width: 767px)': {
            fontSize: 11
        }
    },
    editButton: {
        borderRadius: 25,
        color: theme.palette.primary.main,
        padding: '9px 25px',
        textTransform: 'none'
    },
    editButtonDisabled: {
        '&:disabled': {
            color: theme.palette.primary.main
        }
    },
    tooltip: {
        width: 235
    },
    logo: {
        height: 36,
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: {
            height: 21
        }
    },
    buttonReview: {
        minWidth: 224
    },
    buttonEdit: {
        color: theme.palette.blue[500],
        fontWeight: 700,
        fontSize: 14,
        display: 'flex',
        gap: 8,
        marginRight: 30
    }
});
