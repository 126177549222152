export const errorMessage = 'Some error occurs. Try again.';
export const timeToCloseFlashMessageCancel = 2000;

// Name of validation
export const VALIDATE_IS_EMAIL = 'isEmail';
export const VALIDATE_REQUIRED = 'required';
export const VALIDATE_SPACES = 'validateSpaces';

// Company Features
export const AI = 'AI';
export const REPORTING = 'REPORTING';
export const CSV_IMPORT = 'CSV_IMPORT';
export const CSV_EXPORT = 'CSV_EXPORT';
export const AUTO_PROGRESS = 'AUTO_PROGRESS';
export const AUTO_REJECT = 'AUTO_REJECT';
export const BATCH_ACTIONS = 'BATCH_ACTIONS';
export const DIRECT_MESSAGING = 'DIRECT_MESSAGING';
export const CODE_CHALLENGE = 'CODE_CHALLENGE';
export const CANDIDATE_SHARING = 'CANDIDATE_SHARING';
export const GUEST_GRADING = 'GUEST_GRADING';
export const PERMISSIONS = 'PERMISSIONS';
export const SEND_SMS_INVITATION = 'SEND_SMS_INVITATION';
export const APP_SUMO = 'APP_SUMO';
export const PLAGIARISM_DETECTION = 'PLAGIARISM_DETECTION';
export const ACCESS_PREMIUM_CONTENT = 'ACCESS_PREMIUM_CONTENT';
export const ASSESSMENT_BRANDING = 'ASSESSMENT_BRANDING';
export const CSV_EXPORT_CANDIDATE_QUESTIONS_AND_ANSWERS = 'CSV_EXPORT_CANDIDATE_QUESTIONS_AND_ANSWERS';
export const HIDE_CANDIDATE_DETAILS = 'HIDE_CANDIDATE_DETAILS';
export const PUBLIC_LIBRARY = 'PUBLIC_LIBRARY';
export const AI_AUDITION_GENERATION = 'AI_AUDITION_GENERATION';
export const REQUEST_CUSTOM_ASSESSMENT = 'REQUEST_CUSTOM_ASSESSMENT';


export const TEXT = 1;
export const MULTIPLE_CHOICE_AUTOGRADED = 2;
export const AUDIO = 3;
export const VIDEO = 4;
export const ANY_FILE = 5;
export const IMAGE = 6;
export const NO_ANSWER = 7;
export const SINGLE_CHOICE_AUTO_GRADED = 8;
export const GOOGLE_DOC = 9;
export const PRESENTATION = 10;
export const SPREADSHEET = 11;
export const CODE_TEST = 12;
export const CODE_TEST_AUTOGRADED = 13;
export const FEEDBACK = 14;
export const SPREADSHEET_AUTO_GRADED = 16;
export const NUMBER = 17;
export const MULTIPLE_CHOICE_OLD = 18;
export const CS_SIMULATOR = 19;
export const TYPING_TEST = 20;
export const CYBER_SIMULATOR = 21;
export const EXCEL_AUTO_GRADED = 22;
export const SALES_SIMULATOR = 23;
export const QA_SIMULATOR = 25;
export const MARKETING_SIMULATOR = 26;
export const TABLE = 28;

export const SIMULATION_GROUP = {
    label: 'Simulation',
    types: [CS_SIMULATOR, CYBER_SIMULATOR, SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR],
    autogradedTypes: [CS_SIMULATOR, CYBER_SIMULATOR]
};


export const MOBILE_ONLY_QUESTIONS = [
    SPREADSHEET, SPREADSHEET_AUTO_GRADED, PRESENTATION, GOOGLE_DOC,
    TYPING_TEST, CS_SIMULATOR, CYBER_SIMULATOR, SALES_SIMULATOR,
    MARKETING_SIMULATOR, EXCEL_AUTO_GRADED, SIMULATION_GROUP, CODE_TEST_AUTOGRADED];

export const TYPES_DISABLED_FOR_CMS = [SALES_SIMULATOR, MARKETING_SIMULATOR];

export const TYPE_PROSPECT_LIST = 2301;
export const TYPE_PROSPECT_SINGLE_CHOICE = 2302;
export const TYPE_PROSPECT_SINGLE_EMAIL = 2303;
export const TYPE_PROSPECT_SINGLE_EMAIL_CHOICE = 2304;
export const TYPE_PROSPECT_SINGLE_VIDEO = 2305;
export const TYPE_PROSPECT_SINGLE_AUDIO = 2306;
export const TYPE_PROSPECT_SINGLE_NOTE = 2307;
export const TYPE_PROSPECT_SINGLE_EMAIL_SUBJECT = 2308;
export const TYPE_PROSPECT_SINGLE_EMPLOYEE_SUBJECT = 2309;
export const TYPE_MARKET_EMAIL = 2310;
export const TYPE_MARKET_SINGLE_CHOICE = 2311;
export const TYPE_PROSPECT_LIST_TEXT = 2312;
export const TYPE_PROSPECT_LIST_EMAIL = 2313;
export const TYPE_PROSPECT_SINGLE_VOICE_MESSAGE = 2314;

export const SALES_AUTOGRADED_TYPES = [
    TYPE_PROSPECT_LIST, TYPE_PROSPECT_SINGLE_CHOICE,
    TYPE_PROSPECT_SINGLE_EMAIL_CHOICE, TYPE_MARKET_SINGLE_CHOICE
];

export const SALES_SIMULATION_TYPES = [
    ...SALES_AUTOGRADED_TYPES, TYPE_PROSPECT_SINGLE_EMAIL,
    TYPE_PROSPECT_SINGLE_VIDEO, TYPE_PROSPECT_SINGLE_AUDIO,
    TYPE_PROSPECT_SINGLE_NOTE, TYPE_PROSPECT_SINGLE_EMAIL_SUBJECT,
    TYPE_PROSPECT_SINGLE_EMPLOYEE_SUBJECT, TYPE_MARKET_EMAIL,
    TYPE_PROSPECT_LIST_TEXT, TYPE_PROSPECT_LIST_EMAIL,
    TYPE_PROSPECT_SINGLE_VOICE_MESSAGE
];

export const QA_USER_STORY = 'QA_USER_STORY';
export const QA_TEST_CASE = 'QA_TEST_CASE';
export const QA_TEST_RUN = 'QA_TEST_RUN';
export const QA_EDIT_BUG = 'QA_EDIT_BUG';
export const QA_CREATE_BUG = 'QA_CREATE_BUG';

export const MARKETING_SIM_CREATE_ICP = 'MARKETING_SIM_CREATE_ICP';
export const MARKETING_SIM_FLOW_BUILDER = 'MARKETING_SIM_FLOW_BUILDER';
export const MARKETING_SIM_FLOW_CONTENT = 'MARKETING_SIM_FLOW_CONTENT';
export const MARKETING_SIM_FORECASTING_SHEET = 'MARKETING_SIM_FORECASTING_SHEET';
export const MARKETING_SIM_TEXT_WYSIWYG = 'MARKETING_SIM_TEXT_WYSIWYG';
export const MARKETING_SIM_COMPOSE_BUSINESS_PLAN = 'MARKETING_SIM_COMPOSE_BUSINESS_PLAN';
export const MARKETING_SIM_BUSINESS_PLAN_EMAIL = 'MARKETING_SIM_BUSINESS_PLAN_EMAIL';
export const MARKETING_SIM_SEO_TRAFFIC = 'MARKETING_SIM_SEO_TRAFFIC';
export const MARKETING_SIM_SEO_TECHNICAL = 'MARKETING_SIM_SEO_TECHNICAL';
export const MARKETING_SIM_SEO_SITEMAP = 'MARKETING_SIM_SEO_SITEMAP';
export const MARKETING_SIM_SEO_EMAIL_TO_MARKETING = 'MARKETING_SIM_SEO_EMAIL_TO_MARKETING';
export const MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS = 'MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS';
export const MARKETING_SIM_SEO_AUDIO_CALL = 'MARKETING_SIM_SEO_AUDIO_CALL';
export const MARKETING_SIM_SEO_SOLUTION_PAGE = 'MARKETING_SIM_SEO_SOLUTION_PAGE';
export const MARKETING_SIM_SEO_OFF_PAGE = 'MARKETING_SIM_SEO_OFF_PAGE';
export const MARKETING_SIM_SEO_COLD_OUTREACH = 'MARKETING_SIM_SEO_COLD_OUTREACH';
export const MARKETING_SIM_CM_STRATEGY = 'MARKETING_SIM_CM_STRATEGY';
export const MARKETING_SIM_CM_EMAIL = 'MARKETING_SIM_CM_EMAIL';
export const MARKETING_SIM_CM_TABLE = 'MARKETING_SIM_CM_TABLE';
export const MARKETING_SIM_CM_TEXT_WYSIWYG = 'MARKETING_SIM_CM_TEXT_WYSIWYG';
export const MARKETING_SIM_CM_AUDIO_CALL = 'MARKETING_SIM_CM_AUDIO_CALL';
export const MARKETING_SIM_CM_VIDEO_CALL = 'MARKETING_SIM_CM_VIDEO_CALL';
export const MARKETING_SIM_CM_SPREADSHEET = 'MARKETING_SIM_CM_SPREADSHEET';


export const QA_AUTOGRADED_TYPES = [QA_TEST_CASE, QA_TEST_RUN, QA_EDIT_BUG];

export const MARKETING_AUTOGRADED_TYPES = [
    MARKETING_SIM_FORECASTING_SHEET, MARKETING_SIM_FLOW_BUILDER,
    MARKETING_SIM_SEO_TRAFFIC, MARKETING_SIM_SEO_TECHNICAL,
    MARKETING_SIM_SEO_SITEMAP, MARKETING_SIM_CM_SPREADSHEET
];

export const TYPE_QA_TEST_CASE = 2501;
export const TYPE_QA_TEST_RUN = 2502;

export const SCORE_TYPE_MANUAL = 'TYPE_MANUAL';
export const SCORE_TYPE_AI = 'TYPE_AI';
export const SCORE_TYPE_COMBINED = 'TYPE_COMBINE';

// Candidates results page
export const CANDIDATES_PER_RESULTS_PAGE = 24;
export const CANDIDATES_PER_ALL_CANDIDATES_PAGE = 12;
export const POPULAR_SKILLS_PER_ALL_SKILLS_PAGE = 24;
export const ASSESSMENT_TAGS_PER_PAGE = 24;
// Grading statuses

export const STATUS_READY_TO_GRADE = 2;

export const AI_HEALTH_THERE_ARE_NO_ANSWERS = 0;
/* There is at least one real candidate completion */
export const AI_HEALTH_STATUS_LOW = 1;
/* If there were added correct answers */
export const AI_HEALTH_STATUS_MEDIUM = 2;
/* If 555 model is on */
export const AI_HEALTH_STATUS_HIGH = 3;
/* if 555 model + Iterative models are ON */
export const AI_HEALTH_STATUS_OPTIMIZED = 4;

export const MANUAL_GRADING_REQUIRED = 5;
export const MANUAL_GRADED = 6;

export const AI_LIGHT_INITIAL = 7;
export const AI_LIGHT_GRADING_REQUIRED = 8;
export const AI_LIGHT_OPTIMIZED = 9;

export const MAX_CANDIDATES_COMPARE_COUNT = 10;

export const BATCH_STEP = 15;
export const MAX_SKILL_NAME_LENGTH = 255;

export const MAX_PAUSES_COUNT = 2;
export const MAX_BRANDING_SETS = 42;


export const AUTOGRADED_ANSWER_TYPES = [
    SINGLE_CHOICE_AUTO_GRADED, CODE_TEST_AUTOGRADED, NUMBER,
    SPREADSHEET_AUTO_GRADED, MULTIPLE_CHOICE_AUTOGRADED, TYPING_TEST, EXCEL_AUTO_GRADED,
    ...SIMULATION_GROUP.autogradedTypes, ...SALES_AUTOGRADED_TYPES,
    ...QA_AUTOGRADED_TYPES, ...MARKETING_AUTOGRADED_TYPES
];

export const NOT_REQUIRED_ANSWER_TYPES = [QA_USER_STORY];

export const CUSTOM_ASSESSMENT_REQUEST_LIMIT = 24;

export const ALL_MANUAL_GRADED_TYPES = [SPREADSHEET, CODE_TEST, PRESENTATION, ANY_FILE];

export const DATE_RANGES = {
    ALL_TIME: 'All Time',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    LAST_YEAR: 'Last Year'
};
