import { del, get, patch, post } from '../helper/API';

export function activateAssessment(slug) {
    return post(`employer/auditions/${slug}/activate`);
}

export function deactivateAssessment(slug) {
    return post(`employer/auditions/${slug}/inactivate`);
}

export function inviteToAssessment(slug, form, test = false, config) {
    return post(`employer/auditions/${slug}/invite`, { form, test }, config);
}

export function getCandidatesInAssessment(slug, page, query, filter, tags, sort, order, plagiarism = {}, config = {}) {
    return get(`employer/auditions/${slug}/results`, {
        page, query, filter, tags, sort, order, ...plagiarism
    }, config);
}

export function loadAssessmentStats(slug) {
    return get(`employer/auditions/${slug}/statistic`);
}

export function loadInvitationsStats(slug) {
    return get(`employer/auditions/${slug}/filters-for-invite`);
}

export function loadWelcomeScreenData(slug) {
    return get(`employer/auditions/${slug}/welcome-screen`);
}

export function changeWelcomeScreenData(slug, data) {
    return patch(`employer/auditions/${slug}/welcome-screen`, data);
}


export function loadAssessmentLinks(slug) {
    return get(`employer/auditions/${slug}/job-links`);
}

export function changeAssessmentLink(slug, link) {
    return patch(`employer/auditions/${slug}/trackable-links/${link.id}`, {
        name: link.name,
        source: link.source
    });
}

export function addAssessmentLink(slug, data) {
    return post(`employer/auditions/${slug}/trackable-links`, data);
}

export function deleteAssessmentLink(slug, linkId) {
    return del(`employer/auditions/${slug}/trackable-links/${linkId}`);
}

export function requestExportCsvPerAssessment(slug, options, page = 1, offset = 50) {
    const {
        exclude: non_exported,
        candidateLinkType: candidate_link_type,
        exportQuestions: export_question,
        includeRank: rank
    } = options;
    return get(`employer/auditions/${slug}/candidates/export`, {
        non_exported,
        export_question,
        candidate_link_type,
        rank,
        page,
        offset
    });
}

export function extendCandidates(slug, userAuditions) {
    return post(`employer/opportunities/${slug}/reset`, { userAuditions });
}

export function rejectCandidates(slug, users) {
    return post(`employer/opportunities/${slug}/reject`, { users });
}

export function rejectExtensionRequest(userAuditionId) {
    return patch(`employer/user-auditions/${userAuditionId}/reset-request/reject`);
}

export function toggleAssessmentNotifications(slug, enabled) {
    return post(`employer/auditions/${slug}/notifications/switch`, { enabled });
}

export function extendBulkCandidates(slug, userAuditions) {
    return post(`employer/auditions/${slug}/reset`, { userAuditions });
}

export function getCompletionStatistics() {
    return get('employer/auditions/statistic');
}

export function getAFSetupGuide() {
    return get('employer/setup-guide');
}

export function generateAssessmentQuestions(slug) {
    return post(`employer/auditions/${slug}/populate-templates`);
}

export function sendAssessmentToSmartRecruiters(slug) {
    return post(`employer/auditions/${slug}/smartrecruiters/send`);
}

export function tagCandidate(tagId, candidateId) {
    return post(`employer/tags/${tagId}/column-users/${candidateId}`);
}

export function unTagCandidate(tagId, candidateId) {
    return del(`employer/tags/${tagId}/column-users/${candidateId}`);
}

export function getAssessmentSortSettings(slug) {
    return get(`employer/score/audition/${slug}`);
}

export function saveAssessmentSortSettings(slug, data) {
    return post(`employer/score/audition/${slug}`, data);
}

export function removeAssessmentSortSettings(slug) {
    return del(`employer/score/audition/${slug}`);
}

export function calculateAssessmentEmployerSortSettings(slug) {
    return post(`employer/score/audition/${slug}/calculate`, { limit: 30 });
}

export function getCandidateCardInfo(slug, candidateId) {
    return get(`employer/auditions/${slug}/candidates/${candidateId}`);
}

export function getCandidateCompareModeInfo(slug, candidateId) {
    return get(`employer/auditions/${slug}/candidates/${candidateId}/compare-mode`);
}

export function getInvitationsInAssessment(slug, page, query, filter, tags, sort, order, source) {
    return get(`employer/auditions/${slug}/invited-candidates`, {
        page, query, filter, tags, sort, order, source
    });
}

export function getArchivedCandidates(slug, page, query, sort, order) {
    return get(`employer/auditions/${slug}/archived-candidates`, {
        page, query, sort, order
    });
}

export function getNewExtensionsRequests(slug) {
    return get(`employer/auditions/${slug}/user-auditions/requested-more-time`);
}

export function getFiltersCount(slug) {
    return get(`employer/auditions/${slug}/filters-for-select`);
}

export function getQuestionsToCompare(interviewSlug) {
    return get(`employer/auditions/${interviewSlug}/compare-questions`);
}

export function getQuestionsToComparePublic(uuid) {
    return get(`public/auditions/${uuid}/compare-questions`);
}

export function getCandidateAnswerByHashUuid(slug, id, hashUuid, isPublic) {
    return get(`${isPublic ? 'public' : 'employer'}/auditions/${slug}/candidates/${id}/question-results/${hashUuid}`);
}

export function getCandidatesForCompare(slug) {
    return get(`employer/auditions/${slug}/compare-list`);
}

export function addCandidateToCompare(slug, candidateId) {
    return post(`employer/auditions/${slug}/compare-list/candidate/${candidateId}`);
}

export function removeCandidateFromCompare(slug, candidateId) {
    return del(`employer/auditions/${slug}/compare-list/candidate/${candidateId}`);
}

export function getInsightsScoreDistribution(slug) {
    return get(`employer/auditions/${slug}/score-distribution`);
}

export function getInsightsScoreDistributionByGroup(uuid) {
    return get(`employer/question-groups/${uuid}/score-distribution`);
}

export function exportInsightsScoreDistribution(slug) {
    return get(`employer/auditions/${slug}/score-distribution/export`);
}

export function exportInsightsScoreDistributionByGroup(uuid) {
    return get(`employer/question-groups/${uuid}/score-distribution/export`);
}

export function getInsightsQuestionByQuestion(slug) {
    return get(`employer/auditions/${slug}/question-by-question`);
}

export function getInsightsMultipleChoiceResponse(slug) {
    return get(`employer/auditions/${slug}/insight/choices`);
}

export function getInsightsCompletions(slug, dateRange) {
    return get(`employer/auditions/${slug}/candidate-completions`,
        dateRange.length && dateRange[0]
            ? { from: dateRange[0], to: dateRange[1] }
            : {});
}

export function exportInsightsCompletions(slug, dateRange) {
    return get(`employer/auditions/${slug}/candidate-completions/export`,
        dateRange.length && dateRange[0]
            ? { from: dateRange[0], to: dateRange[1] }
            : {});
}

export function exportMultipleChoiceResponceInsights(slug) {
    return get(`employer/auditions/${slug}/insight/choices/export`);
}


export function getCandidateRating(slug) {
    return get(`employer/auditions/${slug}/candidate-feedbacks-rating`);
}

export function getCandidateRatingFilter({ slug }) {
    return get(`employer/auditions/${slug}/candidate-feedbacks/filter`);
}

export function getCandidateRatingList({ slug, page, score }) {
    return get(`employer/auditions/${slug}/candidate-feedbacks`, {
        page, score: score === 'All' ? undefined : score
    });
}

export function getCandidatePlagiarismDetectionData(userAuditionId) {
    return get(`employer/user-auditions/${userAuditionId}/plagiarism`);
}

export function requestExportFeedbackCsv({ slug, page = 1, offset = 100 }) {
    return get(`employer/auditions/${slug}/candidate-feedbacks/export`, {
        page,
        offset
    });
}

export function getAssessmentPreviewLink(slug) {
    return post(`employer/auditions/${slug}/audition-preview-link/create`);
}

export function getAssessmentPreviewData(uuid) {
    return get(`public/audition-preview-links/${uuid}/view`);
}

export function getPublicAssessmentPreviewData(uuid) {
    return get(`public/public-auditions/${uuid}/preview`);
}

export function getSatisfactionFeedback(slug, candidateUuid, isPublic) {
    return get(`${isPublic ? 'public' : 'employer'}/auditions/${slug}/candidates/${candidateUuid}/last-feedback`);
}

export function regenerateAssessment(slug) {
    return post(`employer/auditions/${slug}/regenerate`);
}

export function reorderQuestionGroupBefore(id, questionGroupId) {
    return post(`employer/question-groups/${id}/before/${questionGroupId}/sort`);
}

export function reorderQuestionGroupAfter(id, questionGroupId) {
    return post(`employer/question-groups/${id}/after/${questionGroupId}/sort`);
}

export function removeFraudDetectionFlag(id) {
    return del(`employer/user-auditions/${id}/fraud-detection`);
}

export function removePlagiarismDetectionFlag(id) {
    return del(`employer/question-results/${id}/plagiarism`);
}
