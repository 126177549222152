import React from 'react';
import clsx from 'clsx';

// material components
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { CODE_TEST_AUTOGRADED } from 'helper/constants';

// local files and components
import InfoError from 'img/candidateCard/info.svg';
import { checkPluralNew } from 'helper/commonFunctions';
import CodeEditorQuestion from 'components/marketplace/CodeEditorQuestion';
import OpenIcon from '../../assets/open.svg';

import styles from './styles';

const CodeEditorPreview = ({
    openCodeEditor, classes,
    activeLanguages, question, testCases,
    codeChallengeQuestions, loadingCases
}) => {
    const autoCases = testCases.filter(({ type }) => (type === 'autograded'));
    const exampleCases = testCases.filter(({ type }) => (type === 'example'));
    const { id, answerType } = question;
    const autoGrade = answerType === CODE_TEST_AUTOGRADED;

    return (
        <div
            role="presentation"
            onClick={openCodeEditor}
            className={classes.stylesWrapper}
        >
            <div className={classes.headerStyles}>
                <div className={classes.labelStyles}>
                    { codeChallengeQuestions[0] && codeChallengeQuestions[0].language }
                    { activeLanguages.length > 1 && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                            <path fill="#65cbcb" fillRule="evenodd" d="M7.402 9L6 10.342 12 16l6-5.658L16.598 9 12 13.316z" />
                        </svg>
                    ) }
                </div>
            </div>
            <CodeEditorQuestion
                options={
                    {
                        lineNumbers: true,
                        theme: 'material',
                        keyMap: 'sublime',
                        mode: 'text/javascript',
                        firstLineNumber: 1,
                        tabSize: 4,
                        readOnly: true
                    }
                }
                key={id}
                onChange={() => {}}
                header={codeChallengeQuestions[0] ? codeChallengeQuestions[0].header : ''}
                body={codeChallengeQuestions[0] ? codeChallengeQuestions[0].body : ''}
                footer={codeChallengeQuestions[0] ? codeChallengeQuestions[0].footer : ''}
                className="preView"
                helperText="preView"
                stylesComment={{
                    fontSize: 12,
                    paddingLeft: 18
                }}
            />
            <div className={clsx(classes.footerStyles, classes.footerError)}>
                {
                    !loadingCases ? (
                        <>
                            {
                                ((autoCases.length < 1 || exampleCases.length < 1) && autoGrade) ? (
                                    <p className={classes.stylesWarning}>
                                        <img style={{ marginTop: -3 }} src={InfoError} alt="" />&nbsp;
                                        Add code, example and autograded test cases
                                    </p>
                                ) : (
                                    <div className={classes.stylesCasesLength}>
                                        {autoCases.length} autograded {checkPluralNew(autoCases.length, 'test')} {checkPluralNew(autoCases.length, 'case', 'cases')}
                                    </div>
                                )
                            }
                        </>
                    ) : <div />
                }
                <Button onClick={openCodeEditor} classes={{ root: classes.btnEdit }}>
                    <img src={OpenIcon} className="u-mrg--rx1" alt="open" />
                    Edit Code and Test Cases
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(CodeEditorPreview);
