import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { getListOfQuestions } from 'requests/CMSRequests';
import { checkPluralNew } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import NotFoundContent from 'components/assessments_pages/NotFoundContent';
import clsx from 'clsx';
import QuestionsList from '../QuestionsList';
import SearchFilterWrapper from './SearchFilterWrapper';
import TagsList from './TagsList';

const styles = {
    wrapper: {
        padding: '0 30px'
    },
    questionsCountLabel: {
        fontSize: 12,
        marginBottom: 21,
        marginTop: 20,
        width: 95,
        height: 17,
        marginRight: 10
    }
};

const PAGE_STEP = 12;
let timerId = null;

const QuestionsWrapper = observer(({
    classes, selectedSkill, openDrawer,
    setSelectedQuestionId, selectedSet, selectedQuestionId,
    removeFromQuestionUuid, wrapperClass, ...other
}) => {
    const [questionsSearchValue, setQuestionsSearchValue] = useState('');
    const [filters, setFilters] = useState({});
    const [questionsData, setQuestionsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setLoading(true);
        setQuestionsData({});
        setCurrentPage(1);
        loadQuestions(1);
    }, [questionsSearchValue, selectedSkill, filters]);

    useEffect(() => {
        if (!openDrawer && questionsData.items) {
            const index = questionsData.items.findIndex(({ id }) => id === selectedQuestionId);
            loadPageByIndex(index);
        }
    }, [openDrawer]);


    useEffect(() => {
        if (removeFromQuestionUuid && questionsData.items) {
            const index = questionsData.items.findIndex(({ uuid }) => uuid === removeFromQuestionUuid);
            loadPageByIndex(index);
        }
    }, [removeFromQuestionUuid]);

    useEffect(() => {
        loadQuestions();
    }, [currentPage]);

    const loadPageByIndex = (index) => {
        if (index === -1) {
            setCurrentPage(1);
            loadQuestions(1);
        } else loadQuestions(Math.floor(index / PAGE_STEP) + 1);
    };

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setQuestionsSearchValue(value);
    };

    const handleChangeFilters = (filterCategory) => {
        const newFilter = { ...filters };
        if (newFilter[filterCategory]) {
            delete newFilter[filterCategory];
        } else {
            newFilter[filterCategory] = true;
        }
        setFilters(newFilter);
    };

    const loadQuestions = (
        page = currentPage,
        query = questionsSearchValue,
        skill = (selectedSkill && selectedSkill.id) || selectedSkill || null,
        types = Object.keys(filters).join(',')
    ) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            setLoading(true);
            getListOfQuestions(query, skill, page, types)
                .then(({ success, data }) => {
                    if (success && data) {
                        let newItems = data.items;
                        if (page !== 1) {
                            const slicedQuestions = questionsData.items.slice(0, PAGE_STEP * (page - 1));
                            newItems = [...slicedQuestions, ...data.items];
                        }
                        setQuestionsData({ ...data, items: newItems });
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.msg) {
                        const { data: { msg } } = response;
                        flashMessage(msg, 'error');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 300);
    };

    const handleNextButtonClick = () => {
        if (!questionsData.hasMorePages) return;
        setQuestionsData({ ...questionsData, hasMorePages: false });
        setCurrentPage(currentPage + 1);
    };

    return (
        <div className={clsx(classes.wrapper, wrapperClass)}>
            <SearchFilterWrapper
                filters={filters}
                openDrawer={openDrawer}
                totalCount={questionsData.total}
                handleChangeSearch={handleChangeSearch}
                handleChangeFilters={handleChangeFilters}
                questionsSearchValue={questionsSearchValue}
            />
            <div className="u-dsp--f u-ai--center">
                <div className={classes.questionsCountLabel}>
                    {
                        !loading && (
                            <>
                                <b>{questionsData.total}</b> {checkPluralNew(questionsData.total, 'Question')}
                            </>
                        )
                    }
                </div>
                <TagsList options={filters} handleRemove={handleChangeFilters} />
            </div>
            {
                !loading && questionsSearchValue && (!questionsData.items || !questionsData.items.length) && (
                    <NotFoundContent searchValue={questionsSearchValue} />
                )
            }
            <QuestionsList
                showDone
                hideReorder
                loading={loading}
                selectedSkill={selectedSkill}
                questions={questionsData.items}
                currentPage={currentPage}
                hasMorePages={questionsData.hasMorePages}
                loadMore={handleNextButtonClick}
                loadQuestions={loadQuestions}
                selectedSet={selectedSet}
                setSelectedQuestionId={setSelectedQuestionId}
                setCurrentPage={setCurrentPage}
                {...other}
            />
        </div>
    );
});

export default withStyles(styles)(QuestionsWrapper);
