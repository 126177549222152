import React from 'react';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import SetItem from './SetItem';

const styles = () => ({
    setWrapper: {
        maxHeight: 900,
        padding: '0 0 16px',
        overflow: 'auto'
    }
});

const SetList = ({
    classes, loading, sets, setSelectedSet, selectedSet, ...other
}) => (
        <>
            <div className={classes.setWrapper}>
                {
                    (sets || []).map((item, index) => (
                        <SetItem
                            set={item}
                            setIndex={index}
                            key={item.uuid}
                            setFocusedSet={setSelectedSet}
                            focusedSet={selectedSet}
                            {...other}
                        />
                    ))
                }
            </div>
            <div className="u-txt--center u-mrg--tx2">
                {
                    loading && (
                        <CircularProgress size={22} />
                    )
                }
            </div>
        </>
);

export default withStyles(styles)(SetList);
