import React from 'react';
import { withStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';
import FallbackIcon from 'img/fallback.svg';

import styles from './styles';

const Fallback = ({ classes }) => {
    return (
        <div className={classes.wrapper}>
            <img src={FallbackIcon} />
            <div className={classes.title}>Something went wrong</div>
            <div className={classes.subTitle}>
                Try to 
                <ButtonBase
                    className={classes.button}
                    onClick={() => window.location.reload()}
                >
                        &nbsp;refresh&nbsp;
                </ButtonBase>  
                the page or go to
                <ButtonBase
                    className={classes.button}
                    onClick={() => window.location.assign('/my-assessments')}
                >
                         &nbsp;my assessments
                </ButtonBase>.
            </div>
        </div>
    )
};

export default withStyles(styles)(Fallback);
