import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';

import Button from '@mui/material/Button';
import TextInputOutlined from 'libraries/TextInput';

import TTBuilderEvents from 'events/TTBuilderEvents';

import styles from './styles';

const CustomInput = ({ addSkill, classes, className, isPreview }) => {
    const [value, onChange] = useState('');

    const changeInput = (e) => {
        onChange(e.target.value);
    };

    const submitSkill = () => {
        if (value.trim()) {
            if (!isPreview) {
                TTBuilderEvents.SKILL_ADDED({
                    skill: value,
                    customSkill: true
                });
            }
            onChange('');
            addSkill(value.toLowerCase());
        }
    };

    return (
        <TextInputOutlined
            className={className}
            variant="outlined"
            label="Add a custom skill..."
            placeholder="Type a skill e.g ‘Graphic Design’ or ‘Grit’"
            value={value}
            onChange={changeInput}
            type="text"
            fullWidth
            endAdornment={(
                <InputAdornment position="end">
                    <Button
                        className={classes.btnAssess}
                        onClick={submitSkill}
                    >
                        Add Skill
                    </Button>
                </InputAdornment>
            )}
        />
    );
};

export default withRouter(withStyles(styles)(CustomInput));
