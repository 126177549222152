import React, { useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import Tooltip from 'libraries/Tooltip';
import { checkPluralNew } from 'helper/commonFunctions';
import ItemSynchronizeStatus from 'components/assessments_pages/ItemSynchronizeStatus';
import SetsIcon from 'components/icons/SetsIcon';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import ItemStatus from 'components/assessments_pages/ItemStatus';
import { appCtx } from 'components/appStore';
import useMediaQuery from '@mui/material/useMediaQuery';
import ItemAttachmentsWrapper from './ItemAttachmentsWrapper';
import SetActions from './SetActions';

import SkillLabel from './SkillLabel';


const styles = theme => ({
    wrapper: {
        position: 'relative',
        minHeight: 35,
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        padding: 0,
        backgroundColor: '#fff',
        width: 32,
        height: 32,
        marginLeft: 8,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:disabled': {
            opacity: 0.6
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    titleLabel: {
        marginRight: 13,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[300],
        fontWeight: 700,
        marginTop: -2,
        fontSize: 12,
        padding: '6px 9px',
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    statusLabel: {
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '20px',
        padding: '0 8px',
        borderRadius: 60,
        '&.draft': {
            color: theme.palette.yellow[900],
            backgroundColor: theme.palette.yellow[50]
        },
        '&.published': {
            color: theme.palette.green[600],
            backgroundColor: theme.palette.green[50]
        }
    },
    headerStatusRow: {
        gap: '10px 0',
        paddingRight: 120
    }
});

const SetItemHeader = ({
    classes, onDelete, set, onEdit, showEdit, showDelete, showCreateQuestion, onPublish, disabled,
    showAddQuestion, showSkillRow, classTitleRow, handleOpenCreateDrawer, onCreateQuestion,
    handleOpenAddQuestionDrawer, ...other
}) => {
    const { flashMessage } = useContext(appCtx);
    const isSmaller = useMediaQuery('(max-width: 1400px)');

    const handleEdit = (updatedSet) => {
        onEdit(updatedSet);
    };

    if (disabled) {
        return (
            <div className={classes.wrapper}>
                <div>
                    <div className={clsx(classes.headerStatusRow, 'u-dsp--f u-ai--center u-mrg--bx2 u-fw--wrap')}>
                        <span className={clsx(classes.titleLabel, classTitleRow)}>
                            <SetsIcon color="#C7C8CC" />
                        </span>
                    </div>
                </div>

                <SetActions
                    classes={classes}
                    disabled
                />
            </div>
        );
    }
    const { companySkill, title, status, synchronized, assessmentsUsing } = set;

    return (
        <div className={classes.wrapper}>
            <div>
                <div className="u-dsp--f u-ai--center u-mrg--bx2 u-fw--wrap">
                    <span className={clsx(classes.titleLabel, classTitleRow)}>
                        <SetsIcon color="#292A2D" />
                        <span><TruncateWithTooltip width={isSmaller ? 80 : 150} text={title} /></span>
                    </span>
                    <ItemStatus
                        className="u-mrg--lx2"
                        isInteractive
                        isSet
                        uuid={set.uuid}
                        status={set.status}
                        setQuestion={onPublish}
                        flashMessage={flashMessage}
                    />
                    {
                        status !== 'draft' && (
                            <ItemSynchronizeStatus
                                className="u-mrg--lx1"
                                synchronized={synchronized}
                                isInteractive
                                isSet
                                flashMessage={flashMessage}
                                onSynchronized={onPublish}
                                uuid={set.uuid}
                            />
                        )
                    }
                </div>
                {
                    showSkillRow && (
                        <div className="u-dsp--f u-ai--center u-mrg--bx1">
                            <SkillLabel skill={companySkill.title} />
                            {
                                Boolean(assessmentsUsing.length) && (
                                    <ItemAttachmentsWrapper>
                                        <Tooltip label={assessmentsUsing.map(({ name }) => name).join(', ')}>
                                            <span className="u-txt--nowrap">
                                                In {assessmentsUsing.length} {checkPluralNew(assessmentsUsing.length, 'assessment')}
                                            </span>
                                        </Tooltip>
                                    </ItemAttachmentsWrapper>
                                )
                            }
                        </div>
                    )
                }
            </div>

            <SetActions
                set={set}
                classes={classes}
                onEdit={handleEdit}
                onDelete={onDelete}
                handleOpenCreateDrawer={handleOpenCreateDrawer}
                handleOpenAddQuestionDrawer={handleOpenAddQuestionDrawer}
                showOptions={{ showEdit, showDelete, showCreateQuestion, showAddQuestion }}
                {...other}
            />
        </div>
    );
};

export default withStyles(styles)(SetItemHeader);
